
/* Css For Atrix */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two
5. Main Slider/style-one/style-two
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Testimonial Section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section
39. Google Map


**********************************************/

/** banner-section **/

.slider-one{
  position: relative;
  overflow: hidden;
}

.banner-carousel .slide-item{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner-carousel .content-box{
  position: relative;
  width: 100%;
}

.banner-carousel .slide-item:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: red;
  mix-blend-mode: multiply;
  z-index: 1;
}

.banner-carousel .slide-item:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: #000000;
  opacity: 0.6;
}

.banner-carousel .slide-item .image-layer{
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transform:scale(1);
  -ms-transform:scale(1);
  transform:scale(1);
  -webkit-transition: all 6000ms linear;
  -moz-transition: all 6000ms linear;
  -ms-transition: all 6000ms linear;
  -o-transition: all 6000ms linear;
  transition: all 6000ms linear;
}

.banner-carousel .active .slide-item .image-layer{
  -webkit-transform:scale(1.15);
  -ms-transform:scale(1.15);
  transform:scale(1.15);
}

.banner-carousel .content-box h2{
  color: #fff;
  opacity: 0;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
  transform: scaleX(0);
}

.banner-carousel .active .content-box h2{
  opacity: 1;
  transform: scaleX(1);
}

.banner-carousel .content-box p{
  line-height: 28px;
  color: #fff;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .active .content-box p{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.banner-carousel .content-box .btn-box{
  position: relative;
  opacity: 0;
   -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .active .content-box .btn-box{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1300ms;
  -moz-transition-delay: 1300ms;
  -ms-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav{
  display: none !important;
}

.banner-carousel .owl-nav{
  position: absolute;
  left: 0px;
  top: 40%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 3;
}

.banner-carousel .owl-nav button{
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 66px;
  background-color: rgba(255, 255, 255, 0.20);
  text-align: center;
  border-radius: 50%;
  font-size: 45px;
  font-weight: 400;
  cursor: pointer;
  transition: all 500ms ease;
}

.banner-carousel .owl-nav button.owl-next:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 35px;
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 45px;
  height: 2px;
  -webkit-transition: .5s cubic-bezier(.858, .01, .068, .99);
  -o-transition: .5s cubic-bezier(.858, .01, .068, .99);
  transition: .5s cubic-bezier(.858, .01, .068, .99);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  background: #fff;
}

.banner-carousel .owl-nav button.owl-next:hover:before{
  background: #f4244f;
  right: 23px;
  width: 20px;
}

.banner-carousel .owl-nav button.owl-next:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 35px;
  width: 10px;
  height: 10px;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  -webkit-transition: .5s cubic-bezier(.858, .01, .068, .99);
  -o-transition: .5s cubic-bezier(.858, .01, .068, .99);
  transition: .5s cubic-bezier(.858, .01, .068, .99);
}

.banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #f4244f;
  border-top: 2px solid #f4244f;
  right: 23px;
}

.banner-carousel .owl-nav button.owl-prev:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 35px;
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 45px;
  height: 2px;
  -webkit-transition: .5s cubic-bezier(.858, .01, .068, .99);
  -o-transition: .5s cubic-bezier(.858, .01, .068, .99);
  transition: .5s cubic-bezier(.858, .01, .068, .99);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  background: #fff;
}

.banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #f4244f;
  left: 23px;
  width: 20px;
}

.banner-carousel .owl-nav button.owl-prev:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 35px;
  width: 10px;
  height: 10px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  -webkit-transition: .5s cubic-bezier(.858, .01, .068, .99);
  -o-transition: .5s cubic-bezier(.858, .01, .068, .99);
  transition: .5s cubic-bezier(.858, .01, .068, .99);
}

.banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #f4244f;
  border-bottom: 2px solid #f4244f;
  left: 23px;
}

.banner-carousel .owl-nav button.owl-prev{
  left: 70px;
}

.banner-carousel .owl-nav button.owl-next{
  right: 70px;
}


.banner-carousel .owl-nav button:hover{
  background: #fff;
}

.slider-two .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #41a0ea;
}

.slider-two .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #41a0ea;
  border-top: 2px solid #41a0ea;
}

.slider-two .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #41a0ea;
}

.slider-two .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #41a0ea;
  border-bottom: 2px solid #41a0ea;
}

.slider-three .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #6a36ff;
}

.slider-three .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #6a36ff;
  border-top: 2px solid #6a36ff;
}

.slider-three .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #6a36ff;
}

.slider-three .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #6a36ff;
  border-bottom: 2px solid #6a36ff;
}

.slider-four .banner-carousel .owl-nav button.owl-next:after{
  border-right: 2px solid rgba(0, 0, 0, 0.40);
  border-top: 2px solid rgba(0, 0, 0, 0.40);
}

.slider-four .banner-carousel .owl-nav button.owl-prev:after{
  border-left: 2px solid rgba(0, 0, 0, 0.40);
  border-bottom: 2px solid rgba(0, 0, 0, 0.40);
}

.slider-four .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #f1592a;
  border-top: 2px solid #f1592a;
}

.slider-four .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #f1592a;
  border-bottom: 2px solid #f1592a;
}

.slider-six .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #f38836;
}

.slider-six .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #f38836;
  border-top: 2px solid #f38836;
}

.slider-six .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #f38836;
}

.slider-six .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #f38836;
  border-bottom: 2px solid #f38836;
}

.slider-seven .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #ff7f4c;
}

.slider-seven .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #ff7f4c;
  border-top: 2px solid #ff7f4c;
}

.slider-seven .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #ff7f4c;
}

.slider-seven .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #ff7f4c;
  border-bottom: 2px solid #ff7f4c;
}

.slider-eight .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #ae8c64;
}

.slider-eight .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #ae8c64;
  border-top: 2px solid #ae8c64;
}

.slider-eight .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #ae8c64;
}

.slider-eight .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #ae8c64;
  border-bottom: 2px solid #ae8c64;
}

.slider-nine .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #ff5e14;
}

.slider-nine .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #ff5e14;
  border-top: 2px solid #ff5e14;
}

.slider-nine .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #ff5e14;
}

.slider-nine .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #ff5e14;
  border-bottom: 2px solid #ff5e14;
}

.slider-11 .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #17be74;
}

.slider-11 .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #17be74;
  border-top: 2px solid #17be74;
}

.slider-11 .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #17be74;
}

.slider-11 .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #17be74;
  border-bottom: 2px solid #17be74;
}

.slider-12 .banner-carousel .owl-nav button.owl-next:hover:before{
  background: #e35712;
}

.slider-12 .banner-carousel .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #e35712;
  border-top: 2px solid #e35712;
}

.slider-12 .banner-carousel .owl-nav button.owl-prev:hover:before{
  background: #e35712;
}

.slider-12 .banner-carousel .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #e35712;
  border-bottom: 2px solid #e35712;
}







.parallax-scene {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.slider-one .shape-layer .shape-1{
  width: 420px;
  height: 494px;
  top: 0px;
  background-repeat: no-repeat;
}

.slider-one .shape-layer .shape-2{
  width: 425px;
  height: 576px;
  background-repeat: no-repeat;
}

.slider-one .shape-layer .shape-3{
  width: 442px;
  height: 273px;
  right: 0px;
  background-repeat: no-repeat;
}

.slider-one .shape-layer .shape-4{
  width: 382px;
  height: 291px;
  background-repeat: no-repeat;
}

.feature-block-two .inner-box{
  min-height: 80px;
}


/** feature-one **/

.feature-one{
  background: #f7f7f7;
  overflow: hidden;
}

.feature-block-one .inner-box{
  min-height: 80px;
}

.feature-block-one .inner-box .icon-box{
  background: #fff;
}

.g_color{
  color: transparent;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.g_color_2{
  color: transparent;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.g_color_3{
  color: transparent;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.feature-block-one .inner-box:before{
  position: absolute;
  content: '';
  background: #ededed;
  width: 3px;
  height: 100px;
  top: -9px;
  right: 35px;
  border-radius: 2px;
}

.feature-block:last-child .feature-block-one .inner-box:before{
  display: none;
}


/** about-one **/

.about-one{

}

.image_block_one .image-box{
  padding-right: 240px;
}

.image_block_one .image-box img{
  width: 100%;
  border-radius: 10px;
}

.image_block_one .image-box .video-inner .video-btn a{
  font-size: 30px;
  background: #fff;
}

.image_block_one .image-box .video-inner .video-btn a:before,
.image_block_one .image-box .video-inner .video-btn a:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.image_block_one .image-box .video-inner .video-btn a:after{
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.image_block_one .image-box .video-inner:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.2;
  border-radius: 10px;
}

.image_block_one .image-box .skills-box{
  width: 310px;
  background: #fff;
  box-shadow: 0px 20px 50px 0px #e8e8e8;
}

.image_block_one .image-box .skills-box .piechart span{
  position: absolute;
  display: flex;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 38px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #141417;
  text-align: center;
}

.image_block_one .image-box .skills-box .piechart span:after{
  color: #141417;
  content: "%";
  font-size: 22px;
}

.image_block_one .image-box .image-1{
  overflow: hidden;
}

.image_block_one .image-box .image-1:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_one .image-box:hover .image-1:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.image_block_one .image-box .shape .shape-1{
  top: 87px !important;
  left: -43px !important;
  background-repeat: no-repeat;
}

.image_block_one .image-box .shape .shape-2{
  left: 280px !important;
  top: 240px !important;
}

.list-style-one li{
  font-family: 'Inter', sans-serif;
  color: #141417;
  width: 50%;
}

.list-style-one li:before{
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  left: 0px;
  top: 6px;
  border-radius: 50%;
}

.list-style-one li:after{
  position: absolute;
  left: 2px;
  top: 8px;
  content: '';
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.section-line{
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.section-line .line{
  width: 1px;
  height: 100%;
  background: #ededed;
}

.section-line .line-1{
  left: 100px;
}

.section-line .line-2{
  left: 50%;
}

.section-line .line-3{
  right: 100px;
}

.about-one .pattern-layer .pattern-1{
  left: 100% !important;
  top: 70% !important;
  margin-left: -110px;
  background: #f8f8f8;
  width: 220px;
  height: 220px;
}

.about-one .pattern-layer .pattern-2{
  top: 90% !important;
  left: 90% !important;
}


/** service-one **/

.service-one{
  background: #f7f7f7;
  overflow: hidden;
}

.service-block-one .inner-box{
  background: #fff;
  overflow: hidden;
  z-index: 1;
}

.service-block-one .inner-box .icon-box .icon-shape{
  position: absolute;
  top: -13px;
  left: 0px;
  background: #fff0f2;
  opacity: 1;
  z-index: -1;
}

.service-block-one .inner-box:hover{
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%);
  transform: translateY(-10px);
}

.service-block-one .inner-box h4 a{
  color: #141417;
}

.service-block-one .inner-box p{
  transition: all 500ms ease
}

.service-block-one .inner-box .link a{
  color: #141417;
}

.service-block-one .inner-box .link a:hover{
  letter-spacing: 0.5px;
}

.service-block-one .inner-box .link a i{
  margin-left: 10px;
  position: relative;
  font-size: 14px;
  top: 1px;
}

.service-one .pattern-layer .pattern-1{
  width: 455px;
  height: 496px;
  left: -100px;
  background-repeat: no-repeat;
}

.service-one .pattern-layer .pattern-2{
  width: 635px;
  height: 462px;
  left: -100px;
  background-repeat: no-repeat;
}

.service-one .pattern-layer .pattern-3{
  width: 257px;
  height: 399px;
  right: -100px;
  background-repeat: no-repeat;
}

.service-one .pattern-layer .pattern-4{
  width: 437px;
  height: 633px;
  right: -100px;
  background-repeat: no-repeat;
}


/** video-one **/

.video-one{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.video-one:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: red;
  mix-blend-mode: multiply;
  z-index: 1;
}

.video-one:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: #000000;
  opacity: 0.6;
}

.video-one .content-box{
  max-width: 560px;
}

.video-one .content-box h2{
  color: #fff;
}

.video-one .content-box p{
  color: #fff;
}

.border-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255,255,255,1);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  animation: squares 3.9s linear 0s infinite;
  -webkit-animation: squares 3.9s linear 0s infinite;
  -ms-animation: squares 3.9s linear 0s infinite;
  -o-animation: squares 3.9s linear 0s infinite;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  opacity: 0;
}

.border-animation.border-2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}

.border-animation.border-3 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

.video-one .video-btn{
  top: 35px;
}

.video-one .video-btn a{
  color: #fff;
  border: 3px solid rgba(255, 255, 255, 0.40);
}

.video-one .video-btn a:before,
.video-one .video-btn a:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.video-one .video-btn a:after{
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.video-one .pattern-layer .pattern-1{
  width: 397px;
  height: 273px;
  background-repeat: no-repeat;
}

.video-one .pattern-layer .pattern-2{
  width: 337px;
  height: 291px;
  background-repeat: no-repeat;
}

.video-one .pattern-layer .pattern-3{
  width: 262px;
  height: 222px;
  right: -30px;
  top: -15px;
  background-repeat: no-repeat;
}

.video-one .pattern-layer .pattern-4{
  width: 312px;
  height: 301px;
  background-repeat: no-repeat;
}

.hidden-icon{
  display: none;
}


/** chooseus-one **/

.content_block_two .content-box .inner-box .single-item h4 a{
  color: #141417;
}

.content_block_two .content-box .inner-box .single-item h4 a:hover{

}

.image_block_two .image-box{
  padding-left: 240px;
}

.image_block_two .image-box img{
  width: 100%;
  border-radius: 10px;
}

.image_block_two .image-box .shape .shape-1{
  top: -44px !important;
  left: 160px !important;
  background-repeat: no-repeat;
}

.image_block_two .image-box .shape .shape-2{
  left: -10px !important;
  top: 130px !important;
}

.image_block_two .image-box .shape .shape-3{
  left: 75% !important;
  top: 67% !important;
}

.image_block_two .image-box .image{
  overflow: hidden;
}

.image_block_two .image-box .image-2{
  position: absolute;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
}

.image_block_two .image-box .image:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_two .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.bg-color-1{
  background: #f7f7f7;
}


/** process-one **/

.process-one{

}

.process-one .pattern-layer .pattern-1{
  width: 455px;
  height: 496px;
  left: -100px;
  background-repeat: no-repeat;
}

.process-one .pattern-layer .pattern-2{
  width: 635px;
  height: 462px;
  left: -100px;
  background-repeat: no-repeat;
}

.process-one .pattern-layer .pattern-3{
  width: 257px;
  height: 399px;
  background-repeat: no-repeat;
}

.process-one .pattern-layer .pattern-4{
  width: 437px;
  height: 633px;
  background-repeat: no-repeat;
}

.processing-block-one .inner-box .icon-box .hov-icon{
  opacity: 0;
  color: #fff;
}

.processing-block-one .inner-box:hover .icon-box .hov-icon{
  opacity: 1;
}

.processing-block-one .inner-box:hover .icon-box .icon{
  opacity: 0;
}

.processing-block-one .inner-box .icon-box{
  background: #fff;
}

.processing-block-one .inner-box .icon-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  transform: scale(0,0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.processing-block-one .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.processing-block-one .inner-box .icon-box .count-box{
  font-family: 'Inter', sans-serif;
  color: #141417;
  right: -14px;
  background: #fff;
}

.processing-block-one .inner-box .icon-box .count-box:before{
  position: absolute;
  content: '';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  left: -10px;
  top: -10px;
  z-index: -1;
}

.processing-block-one .inner-box .icon-box .count-box:after{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.processing-block-one .inner-box .shape{
  position: absolute;
  top: 50px;
  right: -70px;
  width: 123px;
  height: 66px;
  background-repeat: no-repeat;
}


/** skills-one **/

.image_block_three .image-box img{
  border-radius: 10px;
  width: 100%;
}

.image_block_three .image-box .image{
  overflow: hidden;
}

.image_block_three .image-box .image:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_three .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_three .image-box .text{
  background: #fff;
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px 10px 10px 4px;
  width: 365px;
}

.image_block_three .image-box .text:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: -5px;
  top: 0px;
  border-radius: 10px;
  z-index: -1;
}

.image_block_three .image-box .text:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  z-index: -1;
}

.image_block_three .image-box .shape .shape-1{
  left: 88% !important;
  top: 88% !important;
}

.image_block_three .image-box .shape .shape-2{
  top: 50px !important;
}

.progress-box .bar{
  position:relative;
  width:100%;
  height:6px;
  background:#f2f2f2;
  border-radius:5px;
}

.progress-box .bar-inner{
  position:relative;
  display:block;
  border-radius:5px;
  width:0px;
  height:6px;
  -webkit-transition:all 1500ms ease;
  -ms-transition:all 1500ms ease;
  -o-transition:all 1500ms ease;
  -moz-transition:all 1500ms ease;
  transition:all 1500ms ease;
}

.progress-box h6{
  color: #141417;
  font-weight: 400;
}

.progress-box .count-text{
  color: #141417;
  font-family: "Inter", sans-serif;
}


/** clients-one **/

.clients-one .clients-logo-box{
  position: relative;
  text-align: center;
}

.clients-one .clients-logo-box .overlay-image {
  position: absolute;
  display: block;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  top: -30px;
  opacity: 0;
  transition: all 500ms ease;
}

.clients-one .clients-logo-box:hover .overlay-image{
  top: 0px;
  opacity: 1;
}

.clients-one .clients-logo-box .overlay-image img{
  opacity: 1;
}

.clients-one .clients-logo-box img{
  display: block;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  opacity: 1;
  opacity: 0.6;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}


/** testimonial-one **/


.testimonial-block-one .inner-box .text{
  background: #fff;
  border-bottom-left-radius: 0px;
  box-shadow: 0 20px 40px rgb(0 0 0 / 10%);
}

.testimonial-one .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-one .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-one .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-block-one .inner-box .rating li{
  color: #ffc92e;
}

.testimonial-block-one .inner-box .text p{
  line-height: 26px;
  color: #141417;
}

.testimonial-block-one .inner-box .thumb-box{
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.15);
}

.testimonial-block-one .inner-box .thumb-box img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-one .inner-box .text:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 30px;
  height: 30px;
  left: 0px;
  bottom: -28px;
  box-shadow: 0 30px 40px rgb(0 0 0 / 15%);
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%, 0% 0%);
}

.testimonial-one .dots-style-one .owl-dots{
  position: relative;
  display: block;
  text-align: center;
  margin-top: 48px;
}

.dots-style-one .owl-dots .owl-dot span{
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}

.dots-style-one .owl-dots .owl-dot.active span,
.dots-style-one .owl-dots .owl-dot span:hover{
  width: 30px;
  border-radius: 5px;
}


/** funfact-one **/

.funfact-one{
  overflow: hidden;
  background: #252628;
}

.counter-block-one .inner-box .count-outer{
  position: relative;
  font-family: "Inter", sans-serif;
}

.counter-block-one .inner-box p{
  color: #fff;
}

.funfact-one .shape .shape-1{
  left: 150px !important;
  top: -50px !important;
  width: 158px;
  height: 142px;
  background-repeat: no-repeat;
}

.funfact-one .shape .shape-2{
  left: 90% !important;
  top: 70% !important;
  width: 158px;
  height: 142px;
  background-repeat: no-repeat;
}


/** pricing-one **/

.pricing-one .tab-btn-box .tab-btns:before {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 40px;
  width: 80px;
  content: "";
  border-radius: 30px;
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
}

.pricing-one .tab-btn-box .tab-btns li{
  position: relative;
  display: inline-block;
  min-width: 160px;
  text-align: right;
  font-size: 15px;
  padding: 10px 50px 10px 0px;
  line-height: 20px;
  color: #141417;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 500ms ease;
}

.pricing-one .tab-btn-box .tab-btns li:last-child{
  padding-right: 0;
  padding-left: 50px;
  text-align: left;
}

.pricing-one .tab-btn-box .tab-btns li.active-btn:before {
  right: 2px;
}

.pricing-one .tab-btn-box .tab-btns li:before {
  position: absolute;
  top: 5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  content: "";
  right: -38px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-one .tab-btn-box .tab-btns li:last-child:before{
  display: none;
}

.tabs-box .tab{
  position:relative;
  display:none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab{
  display:block;
}

.tabs-box .tab{
  /* transform:scale(0.9,0.9) translateY(0px); */
}

.tabs-box .tab.active-tab{
  transform:scale(1) translateY(0px);
}

.pricing-block-one .pricing-table{
  background: #fff;
  border: 1px solid #e5e5e5;
}

.pricing-block-one .pricing-table .table-header h6{
  text-transform: uppercase;
}

.pricing-block-one .pricing-table .table-header p{
  line-height: 26px;
}

.pricing-block-one .pricing-table .table-header h2 span{
  color: #808080;
  font-weight: 400;
}

.pricing-block-one .pricing-table .table-content{
  background: #f7f7f7;
}

.pricing-block-one .pricing-table .table-content .feature-list li{
  font-family: 'Inter', sans-serif;
  color: #141417;
}

.pricing-block-one .pricing-table .table-content .feature-list li:before{
  position: absolute;
  content: '\e90e';
  font-family: 'icomoon';
  font-size: 14px;
  left: 0px;
  top: 0px;
  color: transparent;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.pricing-block-one.active-block .pricing-table{
  background: #222226;
  border-color: #222226;
  overflow: hidden;
}

.pricing-block-one.active-block .pricing-table h2,
.pricing-block-one.active-block .pricing-table h2 span,
.pricing-block-one.active-block .pricing-table .table-header p,
.pricing-block-one.active-block .pricing-table .table-content .feature-list li{
  color: #fff;
}

.pricing-block-one.active-block .pricing-table .table-content{
  background: #2d2d31;
}

.pricing-block-one .pricing-table .shape-1{
  width: 193px;
  height: 270px;
  top: -15px;
  right: -30px;
  background-repeat: no-repeat;
  opacity: 0.02;
}

.pricing-block-one .pricing-table .shape-2{
  width: 196px;
  height: 266px;
  background-repeat: no-repeat;
}


/** cta-one **/

.cta-one{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.cta-one:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: red;
  mix-blend-mode: multiply;
  z-index: 1;
}

.cta-one:after{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: #000000;
  opacity: 0.6;
}

.cta-one .inner-box{
  max-width: 720px;
  margin: 0 auto;
}

.cta-one .inner-box h2{
  line-height: 52px;
  color: #fff;
}

.cta-one .inner-box p{
  color: #fff;
  line-height: 26px;
}

.cta-one .shape .shape-1{
  width: 420px;
  height: 316px;
  left: -100px;
  bottom: -25px;
  background-repeat: no-repeat;
}

.cta-one .shape .shape-2{
  width: 336px;
  height: 289px;
  background-repeat: no-repeat;
}

.cta-one .shape .shape-3{
  width: 340px;
  height: 300px;
  right: -100px;
  top: -30px;
  background-repeat: no-repeat;
}

.cta-one .shape .shape-4{
  width: 312px;
  height: 301px;
  background-repeat: no-repeat;
}


/** news-one **/

.news-block-one .inner-box{
  background: #fff;
  overflow: hidden;
  transition: all 500ms ease;
}

.news-one .news-block-one .inner-box:hover{
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
}

.news-block-one .inner-box .post-date{
  border-radius: 30px;
  overflow: hidden;
}

.news-block-one .inner-box .post-date:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
}

.news-block-one .inner-box .image{
  overflow: hidden;
}

.news-block-one .inner-box .image img{
  width: 100%;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover .image img{
  opacity: 0.2;
  transform: scale(1.05);
}

.news-block-one .inner-box .post-info li{
  color: #808080;
}

.news-block-one .inner-box .post-info li a{
  color: #141417;
}

.news-block-one .inner-box .post-info li:before{
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 6px;
  height: 6px;
  top: 12px;
  right: -19px;
  border-radius: 50%;
}

.news-block-one .inner-box .post-info li:last-child:before{
  display: none;
}

.news-block-one .inner-box .post-info li a:hover{

}

.news-block-one .inner-box .lower-content h4 a{
  color: #141417;
}

.news-block-one .inner-box .lower-content h4 a:hover{

}

.news-block-one .theme-btn-two{
  padding-top: 11px;
  padding-bottom: 11px;
  border: 1px solid #e7e7e7;
}





/************** home-2 **************/




/** slider-two **/

.slider-two .pattern-layer{
  width: 100%;
  height: 249px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.slider-two .banner-carousel .slide-item:before{
  display: none;
}

.slider-two h2,
.slider-two p,
.slider-two .theme-btn{
  font-family: 'Oxygen', sans-serif;
}

.slider-two .banner-carousel .content-box{
  max-width: 900px;
  margin: 0 auto;
}

.slider-two .banner-carousel .slide-item{
  padding: 130px 0px 305px 0px;
}

.slider-two .shape-layer .shape-1{
  width: 706px;
  height: 131px;
  background-repeat: no-repeat;
  top: -30px;
  z-index: 2;
}

.slider-two .shape-layer .shape-2{
  width: 100%;
  height: 240px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  z-index: 2;
}

.slider-two .theme-btn-two span:after{
  color: #41a0ea;
}

.slider-two{
  overflow: hidden;
}


/** feature-two **/

.feature-two .inner-container{
  background: #fff;
  margin-top: -160px;
  z-index: 2;
}

.feature-two h4{
  font-family: 'Oxygen', sans-serif;
}

.feature-block-two .inner-box .icon-box{
  background: #f7f7f7;
}


/** about-two **/


.about-two .text-column p{
  color: #141417;
}

.about-two .video-inner{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  max-width: 970px;
  margin: 0 auto;
}

.about-two .video-inner:before{
  position: absolute;
  content: '';
  background: #000000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.6;
}

.about-two .video-inner .video-btn a{
  border: 4px solid #fff;
  color: #fff;
}

.about-two .video-inner .video-btn a:before,
.about-two .video-inner .video-btn a:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.about-two .video-inner .video-btn a:after{
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.about-two .shape .shape-1{
  width: 250px;
  height: 250px;
  background: #f7f7f7;
  border-radius: 50%;
  left: 350px !important;
  top: 70% !important;
}

.about-two .shape .shape-2{
  width: 117px;
  height: 117px;
  left: 320px !important;
  top: 65% !important;
}

.about-two .shape .shape-3{
  width: 117px;
  height: 117px;
  left: 70% !important;
  top: 38% !important;
}

.about-two .shape .shape-4{
  width: 250px;
  height: 250px;
  background: #f7f7f7;
  border-radius: 50%;
  left: 70% !important;
  top: 40% !important;
  z-index: -1;
}


/** service-two **/

.service-two{
  background: #f7f7f7;
}

.service-block-two .inner-box h4 a{
  color: #141417;
}

.service-block-two .inner-box .icon-box .icon-shape {
  left: -29px;
  top: -20px;
  width: 108px;
  height: 84px;
  background: #fff0f2;
  opacity: 0.8;
}

.service-block-two .inner-box .icon-box .overlay-icon {
  left: -29px;
  top: -20px;
  width: 108px;
  height: 84px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 500ms ease;
}

.service-block-two .inner-box:hover .icon-box .overlay-icon{
  transform: scale(0,0);
}

.service-block-two .inner-box{
  background: #fff;
  overflow: hidden;
  z-index: 1;
}

.service-block-two .inner-box .link a{
  color: #141417;
}

.service-block-two .inner-box .link a i{
  margin-left: 10px;
  position: relative;
  font-size: 14px;
  top: 1px;
}

.service-block-two .inner-box .link a:hover{
  letter-spacing: 0.5px;
}

.service-block-two .inner-box:hover{
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.10);
}

.service-two .pattern-layer .pattern-1{
  width: 667px;
  height: 500px;
  left: -100px;
  background-repeat: no-repeat;
}

.service-two .pattern-layer .pattern-2{
  width: 405px;
  height: 683px;
  background-repeat: no-repeat;
}


/** chooseus-two **/

.chooseus-two{
  background: #252628;
}

.chooseus-two .bg-layer{
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.chooseus-two .content-inner{
  width: 50%;
  float: right;
}

.chooseus-two .content-inner .content-box{
  max-width: 750px;
}

.chooseus-two .sec-title-two p{
  color: #fff;
}

.chooseus-two .inner-box .single-item .icon-box{
  background: #fff;
}

.chooseus-two .inner-box .single-item h4{
  color: #fff;
}


.chooseus-two .inner-box .single-item p{
  color: #a9a9aa;
}

.chooseus-two .pattern-layer .pattern-1{
  width: 725px;
  height: 127px;
  background-repeat: no-repeat;
}

.chooseus-two .pattern-layer .pattern-2{
  width: 479px;
  height: 116px;
  background-repeat: no-repeat;
}


/** team-one **/

.team-block-one .inner-box{
  overflow: hidden;
}

.team-block-one .inner-box .image-box{
  overflow: hidden;
  background: #000000;
}

.team-block-one .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box img{
  opacity: 0.4;
  transform: scale(1.05);
}

.team-block-one .inner-box .image-box .social-links{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%,-50%) scale(0,0);
  text-align: center;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .social-links{
  transform: translate(-50%,-50%) scale(1,1);
}

.team-block-one .inner-box .image-box .social-links li{
  position: relative;
  display: inline-block;
  margin: 0px 10px;
}

.team-block-one .inner-box .image-box .social-links li a{
  position: relative;
  display: inline-block;
  font-size: 17px;
  color: transparent;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(45deg, #fff, #fff 100%);
}

.team-block-one .inner-box .image-box .social-links li a:hover{

}

.team-block-one .inner-box .image-box .overlay-pattern-1{
  width: 200px;
  height: 49px;
  background-repeat: no-repeat;
  top: -30px;
  opacity: 0;
  z-index: 1;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .overlay-pattern-1{
  top: 0px;
  opacity: 1;
}

.team-block-one .inner-box .image-box .overlay-pattern-2{
  width: 100%;
  height: 48px;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: -30px;
  opacity: 0;
  z-index: 1;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .overlay-pattern-2{
  bottom: 0px;
  opacity: 1;
}

.team-block-one .inner-box .lower-content h4 a{
  color: #141417;
}

.team-block-one .inner-box .lower-content h4 a:hover{

}

.team-block-one .inner-box .lower-content h4:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 20px;
  height: 2px;
  left: 0px;
  top: 15px;
  transition: all 500ms ease;
}

.team-block-one .inner-box .lower-content h4:hover:before{

}


/** graph-one **/

.graph-one .image-box img{
  width: 100%;
}

.graph-one .image-box .shape .shape-1{
  left: -40px !important;
  top: -40px !important;
}

.graph-one .image-box .shape .shape-2{
  top: 90% !important;
  left: 93% !important;
}

.funfact-two .counter-block-one p{
  color: #141417;
}

.funfact-two .counter-block-one .inner-box .count-outer{
  font-family: 'Oxygen', sans-serif;
}


/** project-one **/

.project-block-one .inner-box{
  overflow: hidden;
}

.project-block-one .inner-box .image-box{
  overflow: hidden;
  background: #000;
}

.project-block-one .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.project-block-one .inner-box:hover .image-box img{
  opacity: 0.4;
  transform: scale(1.05);
}

.project-one .inner-content{
  margin-right: -400px;
}

.project-block-one .inner-box .content-box{
  width: 100%;
  opacity: 0;
  bottom: -30px;
  visibility: hidden;
  transition: all 500ms ease;
}

.project-block-one .inner-box:hover .content-box{
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}

.project-block-one .inner-box .content-box .shape{
  bottom: 0px;
  width: 100%;
  height: 191px;
  background-repeat: no-repeat;
  background-size: cover;
}

.project-block-one .inner-box .content-box h4 a{
  color: #fff;
  width:100%;
  max-width:250px;
  display:block;
}

.project-block-one .inner-box .content-box h4 a:hover{
  text-decoration: underline;
}

.project-block-one .inner-box .content-box .link a{
  border: 2px solid #fff;
  color: #fff;
}

.project-block-one .inner-box .content-box .link a:hover{
  background: #fff;
}

.project-one .owl-nav{
  position: absolute;
  top: -140px;
  right: 390px;
}

.project-one .owl-nav:before{
  position: absolute;
  content: '';
  background: #eaeaea;
  width: 1px;
  height: 35px;
  top: 5px;
  right: 45px;
}

.nav-style-two .owl-nav button span{
  display: none !important;
}

.nav-style-two .owl-nav button{
  width: 60px;
  height: 50px;
  position: relative;
  display: inline-block;
  background: transparent;
  top: 0;
  left: 20px !important;
  cursor: pointer;
  transition: all 500ms ease;
}

.nav-style-two .owl-nav button.owl-next:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 18px;
  height: 2px;
  background: #141417;
  transition: all 500ms ease;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}

.nav-style-two .owl-nav button.owl-next:hover:before,
.nav-style-two .owl-nav button.owl-prev:hover:before{
  width: 25px;
  background: #41a0ea;
}

.nav-style-two .owl-nav button.owl-next:after{
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  width: 9px;
  height: 9px;
  border-right: 2px solid #141417;
  border-top: 2px solid #141417;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  transition: all 500ms ease;
}

.nav-style-two .owl-nav button.owl-next:hover:after{
  border-right: 2px solid #41a0ea;
  border-top: 2px solid #41a0ea;
}

.nav-style-two .owl-nav button.owl-prev:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 21px;
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 18px;
  height: 2px;
  background: #141417;
  transition: all 500ms ease;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}

.nav-style-two .owl-nav button.owl-prev:after{
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  width: 9px;
  height: 9px;
  border-left: 2px solid #141417;
  border-bottom: 2px solid #141417;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  transition: all 500ms ease;
}

.nav-style-two .owl-nav button.owl-prev:hover:after{
  border-left: 2px solid #41a0ea;
  border-bottom: 2px solid #41a0ea;
}


/** testimonial-two **/

.testimonial-two{
  background: #f5f5f5;
}

.testimonial-two .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-two .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-two .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-two .pattern-layer .pattern-1{
  width: 667px;
  height: 500px;
  left: -100px;
  background-repeat: no-repeat;
}

.testimonial-two .pattern-layer .pattern-2{
  width: 405px;
  height: 683px;
  background-repeat: no-repeat;
}

.testimonial-two .owl-dots{
  text-align: center;
  margin-top: 50px;
}

.testimonial-two .dots-style-one .owl-dots .owl-dot.active span,
.testimonial-two .dots-style-one .owl-dots .owl-dot span:hover{

}


/** cta-two **/

.cta-two {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.cta-two:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: #000000;
  opacity: 0.70;
}

.cta-two .inner-box{
  max-width: 720px;
  margin: 0 auto;
}

.cta-two .inner-box h2{
  line-height: 52px;
  color: #fff;
}

.cta-two .inner-box p{
  color: #fff;
  line-height: 26px;
}

.cta-two .pattern-layer .pattern-1{
  width: 616px;
  height: 108px;
  background-repeat: no-repeat;
}

.cta-two .pattern-layer .pattern-2{
  width: 1040px;
  height: 169px;
  background-repeat: no-repeat;
}


/** news-two **/

.news-block-one .inner-box .post-date-two{
  background: #262628;
}

.news-block-one .inner-box .post-date-two h4{
  font-size: 20px;
  color: #fff;
}

.news-block-one .inner-box .post-date-two h4 span{
  font-size: 14px;
  font-weight: 400;
}

.news-two .news-block-one .inner-box .image{

}

.news-two .news-block-one .inner-box .post-info li a:hover{

}

.news-two .news-block-one .inner-box .lower-content h4 a:hover{

}

.news-two .news-block-one .inner-box .lower-content .theme-btn.btn-three:before{

}


/** subscribe-one **/

.subscribe-one .inner-container{
  background: #252628;
  overflow: hidden;
}

.subscribe-one h2{
  color: #fff;
}

.subscribe-one .form-inner .form-group{
  position: relative;
  padding-right: 170px;
  margin-bottom: 0px;

}

.default-form input[type='text'],
.default-form input[type='email'],
.default-form input[type='tel'],
.default-form input[type='password'],
.default-form textarea{
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 15px;
  color: #808080;
  padding: 15px 20px;
  transition: all 500ms ease;
}

.subscribe-one .form-inner .form-group input:focus{

}

.subscribe-one .form-inner .form-group button{
  position: absolute;
  top: 0px;
  right: 0px;
}

.subscribe-one .form-inner .form-group button span{
  position: absolute;
  left: 0px;
  top: -15px;
  right: 0px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  opacity: 0;
  transition: all 500ms ease;
}

.subscribe-one .form-inner .form-group button:hover span{
  top: 0px;
  opacity: 1;
}

.subscribe-one .pattern-layer .pattern-1{
  width: 399px;
  height: 97px;
  background-repeat: no-repeat;
}

.subscribe-one .pattern-layer .pattern-2{
  width: 680px;
  height: 120px;
  background-repeat: no-repeat;
}

.subscribe-one .shape{
  width: 100%;
  height: 237px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.lh_28{
  line-height: 28px;
}




/************** home-3 **************/

/** banner-style-three **/

.slider-three .slide-item{
  padding: 290px 0px 250px 0px;
}

.slider-three .banner-carousel .slide-item:before{
  display: none;
}

.slider-three .banner-carousel .owl-nav button:hover:before{

}

.border_bottom{
  border-bottom: 1px solid #e5e5e5;
}



/** about-three **/

.feature-block-three .inner-box .icon-box{
  background: #f7f7f7;
  z-index: 1;
}

.feature-block-three .inner-box{
  overflow: hidden;
  background: #fff;
}

.feature-block-three .inner-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.feature-block-three .inner-box:hover:before{
  height: 100%;
  top: 0px;
}


.feature-block-three .inner-box:hover h4,
.feature-block-three .inner-box:hover p{
  color: #fff;
}

.about-three .shape .shape-1{
  top: -100px !important;
  left: -50px !important;
  border-radius: 50%;
  background: #f7f7f7;
  z-index: -1;
}

.about-three .shape .shape-2{
  width: 87px;
  height: 84px;
  background-repeat: no-repeat;
  left: 50px !important;
}

.about-three .shape .shape-3{
  left: 80% !important;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.about-three .shape .shape-4{
  top: 30% !important;
  left: 75% !important;
  border-radius: 50%;
  background: #f7f7f7;
}

.about-three .shape .shape-5{
  top: 45% !important;
  left: 78% !important;
  background-repeat: no-repeat;
}


/** graph-two **/

.graph-two .image-box img{
  width: 100%;
}

.graph-two .vector-img{
  right: 235px;
}

.graph-two .shape .shape-1{
  top: 27% !important;
  left: 20% !important;
  border-radius: 50%;
  background: #f7f7f7;
}

.graph-two .shape .shape-2{
  top: 38% !important;
  left: 19% !important;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
}

.graph-two .shape .shape-3{
  top: 75% !important;
  left: 70% !important;
  background-repeat: no-repeat;
}

.graph-two .shape .shape-4{
  top: 55% !important;
  left: 75% !important;
  border-radius: 50%;
  background: #f7f7f7;
}


/** chooseus-two **/

.chooseus-three{
  background: #222226;
}

.chooseus-three .bg-layer{
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.chooseus-three .content-inner{
  width: 50%;
  float: right;
}

.chooseus-three .content-inner .content-box{
  max-width: 750px;
}

.chooseus-three .sec-title-three p{
  color: #a9a9aa;
}

.chooseus-three .inner-box .single-item .icon-box{
  background: #fff;
}

.chooseus-three .inner-box .single-item h4{
  color: #fff;
}

.chooseus-three .inner-box .single-item p{
  color: #a9a9aa;
}

.chooseus-three .pattern-layer .pattern-1{
  width: 725px;
  height: 127px;
  background-repeat: no-repeat;
}

.chooseus-three .pattern-layer .pattern-2{
  width: 479px;
  height: 116px;
  background-repeat: no-repeat;
}

.chooseus-three .progress-inner{
  float: left;
  width: 50%;
}

.chooseus-three .piechart span{
  position: absolute;
  display: flex;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 38px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
  color: #141417;
  text-align: center;
}

.chooseus-three .piechart span:after{
  color: #141417;
  content: "%";
  font-size: 22px;
}

.chooseus-three .single-progress-box{
  position: relative;
  display: inline-block;
  float: left;
  width: 200px;
  background: #fff;
  border-radius: 10px;
  margin-right: 20px;
  text-align: center;
  padding: 40px 15px 30px 15px;
}

.chooseus-three .single-progress-box:last-child{
  margin-right: 0px;
}

.chooseus-three .progress-inner{
  max-width: 420px;
  padding: 258px 0px;
}

.chooseus-three .content-inner .pattern-layer .pattern-3{
  width: 311px;
  height: 364px;
  background-repeat: no-repeat;
}


/** service-three **/

.service-block-three .inner-box{
  overflow: hidden;
}

.service-block-three .inner-box .image-box{
  overflow: hidden;
}

.service-block-three .inner-box .image-box img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.service-block-three .inner-box:hover .image-box img{
  opacity: 0.1;
}

.service-block-three .inner-box .content-box{
  width: 100%;
  height: 100%;
}

.service-block-three .inner-box:hover .content-box{
  opacity: 0;
}

.service-block-three .inner-box .icon-box{
  background: #fff;
}

.service-block-three .inner-box .content-box:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(-90deg, rgba(16,14,28,0.10) 50%, rgba(16,14,28,0.95) 100%);
  top: 0px;
  right: 0px;
}

.service-block-three .inner-box h4 a{
  color: #fff;
}

.service-block-three .inner-box h4 a:hover{
  text-decoration: underline;
}

.service-block-three .inner-box .link a{
  color: #fff;
}

.service-block-three .inner-box .link a i{
  margin-left: 10px;
  position: relative;
  font-size: 14px;
  top: 1px;
}

.service-block-three .inner-box .link a:hover{
  letter-spacing: 0.5px;
}

.service-block-three .inner-box p{
  color: #fff;
}

.service-block-three .inner-box .overlay-content{
  width: 100%;
  height: 100%;
  top: 30px;
  opacity: 0;
  visibility: hidden;
}

.service-block-three .inner-box:hover .overlay-content{
  top: 0px;
  opacity: 1;
  visibility: visible;
}

.service-block-three .inner-box .shape{
  top: -30px;
  width: 281px;
  height: 195px;
  opacity: 0;
  background-repeat: no-repeat;
}

.service-block-three .inner-box:hover .shape{
  top: 0px;
  opacity: 1;
}

.service-three .more-btn .theme-btn{
  border-radius: 30px;
}

.service-three .more-btn .theme-btn:before{

}

.service-three .shape .shape-1{
  left: 75px !important;
  top: 190px !important;
  width: 221px;
  height: 129px;
}


/** skills-two **/

.skills-two .progress-box .bar-inner{

}

.skills-two .image_block_three .image-box .shape .shape-2{
  top: -40px !important;
  left: -40px !important;
}

.skills-two .image_block_three .image-box .shape .shape-3{
  top: -85px !important;
  background: #f7f7f7;
  border-radius: 50%;
  z-index: -1;
}

.skills-two .image_block_three .image-box .shape .shape-4{
  top: 80% !important;
  left: 80% !important;
  background: #f7f7f7;
  border-radius: 50%;
  z-index: -1;
}


/** funfact-three **/

.funfact-three{
  background: #f7f7f7;
  overflow: hidden;
}

.funfact-three .count-outer,
.funfact-three .counter-block-one .inner-box p{
  color: #141417;
}


/** project-two **/


.project-block-two .inner-box .content-box{
  width: calc(100% - 60px);
  background: #fff;
  bottom: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.project-block-two .inner-box:hover .content-box{
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

.project-block-two .inner-box h4 a{
  color: #141417;
}

.project-block-two .inner-box h4 a:hover{

}

.project-block-two .inner-box .link a{
  color: #141417;
}

.project-block-two .inner-box .link a:hover{
  transform: scale(1.2);
}

.project-block-two .inner-box .image-box{
  background: #000;
  overflow: hidden;
}

.project-block-two .inner-box .image-box img{
  width: 100%;
  border-radius: 5px;
  transition: all 500ms ease;
}

.project-block-two .inner-box:hover .image-box img{
  opacity: 0.6;
  transform: scale(1.05);
}

.sortable-masonry .filter-tabs li{
  position: relative;
  display: inline-block;
  font-size: 17px;
  font-family: 'Oxygen', sans-serif;
  line-height: 24px;
  color: #808080;
  cursor: pointer;
  margin: 0px 15px;
  transition: all 500ms ease;
}

.sortable-masonry .filter-tabs li.active,
.sortable-masonry .filter-tabs li:hover{

}

.sortable-masonry .filter-tabs li:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 1px;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.sortable-masonry .filter-tabs li.active:before,
.sortable-masonry .filter-tabs li:hover:before{
  width: 100%;
}


/** testimonial-three **/


.nav-style-one .owl-nav button{
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #141417;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav button.owl-prev{
  transform: rotate(180deg);
  margin-right: 50px;
  top: -2px;
}

.testimonial-three .owl-nav:before{
  position: absolute;
  content: '';
  background: #eaeaea;
  width: 1px;
  height: 35px;
  top: 8px;
  right: 45px;
}

.testimonial-three .nav-style-one .owl-nav button:hover{

}

.testimonial-three .inner-content{
  margin-left: 60px;
}

.testimonial-three .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-three .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-three .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-three .owl-nav{
  position: absolute;
  left: -497px;
  bottom: 65px;
}

.testimonial-three .shape .shape-1 {
  left: 75% !important;
  top: -100px !important;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.testimonial-three .shape .shape-2{
  left: 40% !important;
  top: 40% !important;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
}

.testimonial-three .nav-style-two .owl-nav button.owl-next:hover:before,
.testimonial-three .nav-style-two .owl-nav button.owl-prev:hover:before {
  background: #6a36ff;
}

.testimonial-three .nav-style-two .owl-nav button.owl-prev:hover:after {
  border-left: 2px solid #6a36ff;
  border-bottom: 2px solid #6a36ff;
}

.testimonial-three .nav-style-two .owl-nav button.owl-next:hover:after {
  border-right: 2px solid #6a36ff;
  border-top: 2px solid #6a36ff;
}


/** cta-three **/

.cta-three {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.cta-three:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: #000000;
  opacity: 0.70;
}

.cta-three .inner-box{
  max-width: 720px;
  margin: 0 auto;
}

.cta-three .inner-box h2{
  line-height: 52px;
  color: #fff;
}

.cta-three .inner-box p{
  color: #fff;
  line-height: 26px;
}

.cta-three .video-btn a{
  border: 4px solid #fff;
  color: #fff;
}

.cta-three .video-btn a:before,
.cta-three .video-btn a:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.cta-three .video-btn a:after{
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}


/** news-three **/

.news-three .news-block-one .inner-box .post-info li a:hover{

}

.news-three .news-block-one .inner-box .lower-content h4 a:hover{

}

.news-three .news-block-one .inner-box .lower-content .theme-btn{
  background: transparent;
  color: #141417 !important;
  border: 1px solid #e7e7e7;
}

.news-three .news-block-one .inner-box .lower-content .theme-btn:hover{
  border-color: #141417;
  background: #141417;
}

.news-three .news-block-one .inner-box .lower-content .theme-btn:hover{
  color: #fff !important;
}

.news-three .news-block-one .inner-box .lower-content .theme-btn:before{

}

.news-block-one .inner-box .pattern-layer{
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 71px;
  height: 58px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover .pattern-layer{
  right: 0px;
  bottom: 0px;
  opacity: 1;
}

.news-three .shape .shape-1 {
  top: 60% !important;
  left: 200px !important;
  background: #f7f7f7;
  border-radius: 50%;
}

.news-three .shape .shape-2 {
  top: 240px !important;
  left: 75% !important;
  background: #f7f7f7;
  border-radius: 50%;
}

.news-three .shape .shape-3{
  top: 360px !important;
  left: 80% !important;
  background-repeat: no-repeat;
}


/** contact-one **/

.contact-one{
  background: #f7f7f7;
}

.contact-one .sec-title-three p{
  max-width: 400px;
}

.contact-one input,
.contact-one textarea{
  border-radius: 30px !important;
  color: #141417 !important;
  font-family: 'Oxygen', sans-serif !important;
  font-size: 16px !important;
}

.contact-one textarea{
  height: 150px;
  border-radius: 5px !important;
  resize: none;
  display: block;
}

.contact-one input:focus,
.contact-one textarea:focus{

}

.contact-one .form-group button{
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-family: 'Oxygen', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  text-transform: capitalize;
  border-radius: 30px;
  color: #fff;
  width: 100%;
  transition: all 500ms ease;
}

.contact-one .form-group button span{
  position: absolute;
  left: 0px;
  top: -15px;
  right: 0px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  opacity: 0;
  transition: all 500ms ease;
}

.contact-one .form-group button:hover span{
  top: 0px;
  opacity: 1;
}


.contact-one .form-group button i{
  position: relative;
  top: 2px;
  margin-left: 10px;
}

.contact-one .form-group{
  padding: 0px 10px;
}

.contact-one .shape .shape-1{
  background: #fff;
  border-radius: 50%;
  left: 50px !important;
  top: 50px !important;
}

.contact-one .shape .shape-2 {
  left: 150px !important;
  top: 150px !important;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
}

.contact-one .shape .shape-3 {
  left: 78% !important;
  top: 40% !important;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
}

.contact-one .shape .shape-4{
  top: 70% !important;
  left: 40% !important;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.contact-one .pattern-layer{
  width: 397px;
  height: 429px;
  background-repeat: no-repeat;
}



/************** home-4 **************/


/** banner-one **/

.banner-one{
  background: #fff;
}

.banner-one h2 span{
  color: transparent;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.banner-one .video-btn{
  color: #141417;
  margin-top: 0px;
  line-height: 26px;
  min-height: 70px;
  top: 10px;
}

.banner-one .video-btn i{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 20px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.20);
}

.banner-one .video-btn:hover{

}

.banner-one .pattern-layer .pattern-1{
  width: 758px;
  height: 687px;
  background-repeat: no-repeat;
}

.banner-one .pattern-layer .pattern-2{
  width: 716px;
  height: 603px;
  background-repeat: no-repeat;
}

.banner-one .image-box img{
  width: 100%;
}

.banner-one .image-box{
  margin-right: -14px;
}

.banner-one .shape .shape-1{
  left: 90px !important;
  top: 90% !important;
  background: #fab567;
  border-radius: 50%;
}

.banner-one .shape .shape-2{
  left: 50% !important;
  top: 150px !important;
  background: #f18b92;
  border-radius: 50%;
}

.banner-one .shape .shape-3{
  width: 135px;
  height: 135px;
  left: 78% !important;
  top: 65% !important;
  border: 17px solid #90dfbc;
  border-radius: 50%;
}

.banner-one .shape .shape-4{
  left: -110px !important;
  top: 75% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.banner-one .shape .shape-5{
  left: 55% !important;
  top: 110px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.banner-one .shape .shape-6{
  left: 90% !important;
  top: 60% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}


/** feature-three **/

.feature-three .inner-container{
  margin-top: 0px;
}

.feature-three .feature-block:first-child .icon-box{
  background: #ccf3f8;
  color: #10a1d4;
}

.feature-three .feature-block:nth-child(2) .icon-box{
  background: #fbcbd7;
  color: #d64e6f;
}

.feature-three .feature-block:last-child .icon-box{
  background: #d6d5ff;
  color: #612ade;
}

.feature-three .feature-block .icon-box:before{
  background: #141417;
}

.feature-three .feature-block-two .inner-box:hover .icon-box .icon{
  background: transparent;
}


/** about-four **/

.about-four .image-box img{
  width: 100%;
}

.content_block_four .content-box .list li{
  width: 50%;
  color: #141417;
}

.content_block_four .content-box .list li:before{
  position: absolute;
  content: '\e90f';
  font-family: 'icomoon';
  left: 0px;
  top: 0px;
  font-size: 12px;
}

.about-four .image-box .shape .shape-1{
  left: -60px !important;
  top: 130px !important;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 17px solid #90dfbc;
}

.about-four .image-box .shape .shape-2{
  left: 52% !important;
  top: 90% !important;
  background: #f18b92;
  border-radius: 50%;
}

.about-four .image-box .shape .shape-3{
  left: 80px !important;
  top: 50px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.about-four .image-box .shape .shape-4{
  width: 117px;
  height: 117px;
  left: 70% !important;
  top: 70% !important;
  background-repeat: no-repeat;
}


/** service-four **/

.service-block-four .inner-box h4 a,
.service-block-four .inner-box p{
  color: #141417;
}

.service-block-four .inner-box{
  background: #fff;
}

.service-block-four .inner-box h4 a:hover{

}

.service-block-four .inner-box .icon-box{
  background: #ccf3f8;
  color: #10a1d4;
  z-index: 1;
  transition: all 500ms ease;
}

.service-block-four .inner-box .link a{

}

.service-block-four .inner-box .link a:hover{
  color: #141417;
  letter-spacing: 0.25px;
}

.service-block-four .inner-box .link a i {
  margin-left: 10px;
  position: relative;
  font-size: 14px;
  top: 1px;
}

.service-block:nth-child(2) .service-block-four .inner-box .icon-box{
  background: #fcdae3;
  color: #d64e6f;
}

.service-block:nth-child(3) .service-block-four .inner-box .icon-box{
  background: #ffe0b9;
  color: #ff931e;
}

.service-block:last-child .service-block-four .inner-box .icon-box{
  background: #e2e2ff;
  color: #1e70b3;
}

.service-block-four .inner-box .icon-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
  border-radius: 50%;
}

.slider-four.home-6 .banner-carousel .owl-nav button.owl-next:hover:before,
.slider-four.home-6 .banner-carousel .owl-nav button.owl-prev:before{
  line-height: 60px;
}

.service-block:first-child .service-block-four .inner-box .icon-box:before{
  background: #10a1d4;
}

.service-block:nth-child(2) .service-block-four .inner-box .icon-box:before{
  background: #d64e6f;
}

.service-block:nth-child(3) .service-block-four .inner-box .icon-box:before{
  background: #ff931e;
}

.service-block:last-child .service-block-four .inner-box .icon-box:before{
  background: #ff931e;
}

.service-block-four .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.service-block-four .inner-box:hover .icon-box{
  color: #fff !important;
}

.service-four .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


/** chooseus-four **/

.chooseus-four{
  background: #f5f8fc;
}

.accordion-box .block .acc-btn .icon-outer{
  top: 24px;
  right: 30px;
  color: #141417;
}

.accordion-box .block .acc-btn.active .icon-outer{
  transform: rotate(180deg);
}

.accordion-box .block .acc-btn{
  cursor: pointer;
}

.accordion-box .block .acc-btn.active{
  background: #fff;
  border-radius: 40px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, .05);
}

.accordion-box .block .acc-content{
  display:none;
}

.accordion-box .block .acc-content.current{
  display:block;
}

.accordion-box .block .acc-content p{
  color: #141417;
}

.image_block_four .image-box img{
  width: 100%;
}

.image_block_four .image-box .shape .shape-1 {
  top: 70px !important;
  background: #f18b92;
  border-radius: 50%;
}

.image_block_four .image-box .shape .shape-2{
  left: 80% !important;
  top: 50% !important;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 17px solid #90dfbc;
}

.image_block_four .image-box .shape .shape-3{
  background-repeat: no-repeat;
  z-index: -1;
}

.image_block_four .image-box .shape .shape-4{
  width: 117px;
  height: 117px;
  left: 50% !important;
  top: 65% !important;
  background-repeat: no-repeat;
}

.chooseus-four .pattern-layer{
  height: 460px;
  background-repeat: no-repeat;
}

.team-block-one .inner-box .image-box .social-links-two{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%,-50%) scale(0,0);
  text-align: center;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .social-links-two{
  transform: translate(-50%,-50%) scale(1,1);
}

.team-block-one .inner-box .image-box .social-links-two li{
  margin: 0px 3.5px;
}

.team-block-one .inner-box .image-box .social-links-two li a{
  position: relative;
  display: inline-block;
  background: #fff;
  color: #141417;
}

.team-block-one .inner-box .image-box .social-links-two li a:hover{
  color: #fff;
}


/** quote-one **/

.content_block_six .content-box{
  background: #fff;
}

.content_block_six .content-box .tab-btn-box{
  background: #fff;
}

.content_block_six .content-box .tab-btns li{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #141417;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  margin: 0px 15px;
  padding-bottom: 4px;
  transition: all 500ms ease;
}

.content_block_six .content-box .tab-btns li:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 2px;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.content_block_six .content-box .tab-btns li:hover:before,
.content_block_six .content-box .tab-btns li.active-btn:before{
  width: 100%;
}

.content_block_six .content-box .form-group input{
  border: 1px solid #e5e5e5;
}

.content_block_six .content-box .form-group .theme-btn{
  width: 100%;
  padding: 12px 40px;
}

.content_block_six .content-box .form-group{
  margin-bottom: 10px;
}

.content_block_six .content-box .form-group:last-child{
  margin-bottom: 0px;
  padding-top: 10px;
}

.content_block_six .content-box .form-group input:focus{

}

.content_block_six .content-box p{
  color: #141417;
}

.content_block_six .bg-shape{
  background: #f2f6fa;
  width: 540px;
  height: 540px;
  top: -35px;
  z-index: -1;
}

.content_block_six .shape .shape-1{
  top: -60px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  z-index: -1;
}

.content_block_six .shape .shape-2{
  width: 117px;
  height: 117px;
  left: 70% !important;
  top: 65% !important;
  background-repeat: no-repeat;
  z-index: -1;
}

.content_block_seven .content-box .list li {
  color: #141417;
}

.content_block_seven .content-box .list li:before {
  position: absolute;
  content: '\e90f';
  font-family: 'icomoon';
  left: 0px;
  top: 0px;
  font-size: 12px;
}

.funfact-three.home-4{
  background: #f5f8fc;
}

.funfact-three .pattern-layer .pattern-1{
  width: 305px;
  height: 203px;
  left: -100px;
  background-repeat: no-repeat;
}

.funfact-three .pattern-layer .pattern-2{
  width: 306px;
  height: 203px;
  background-repeat: no-repeat;
}


/** testimonial-four **/

.testimonial-four .owl-dots{
  margin-top: 50px;
  text-align: center;
}

.testimonial-four .dots-style-one .owl-dots .owl-dot.active span,
.testimonial-four .dots-style-one .owl-dots .owl-dot span:hover {

}

.overflow_visible .owl-stage-outer{
  overflow: visible;
}

.overflow_visible .owl-stage-outer .owl-item{
  opacity: 0;
}

.overflow_visible .owl-stage-outer .owl-item.active{
  opacity: 1;
}


/** pricing-two **/

.pricing-two .tab-btns{
  position: relative;
  display: inline-block;
  width: 260px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  overflow: hidden;
}

.pricing-two .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  width: 50%;
  text-align: center;
  font-size: 15px;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #141417;
  cursor: pointer;
  padding: 17px 15px;
  transition: all 500ms ease;
}

.pricing-two .tab-btns li:hover,
.pricing-two .tab-btns li.active-btn{
  color: #fff;
}

.pricing-two .pricing-block-one .pricing-table .table-header h6{
  background: transparent;
  line-height: 28px;
  padding: 0px 15px;
}

.pricing-two .pricing-block-one .pricing-table .table-header h6{
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.pricing-two .pricing-block-one .pricing-table .table-content{
  background: #f5f8fc;
}

.pricing-two .theme-btn:before{

}

.pricing-two .pricing-block-one.active-block .pricing-table{
  background: #2d2d31;
}

.pricing-two .pricing-block-one.active-block .pricing-table .table-header h6{
  border-color: #4c4c50;
}

.pricing-two .pricing-block-one.active-block .pricing-table .table-content{
  background: #36363b;
}

.pricing-two .shape .shape-1{
  top: -70px !important;
  left: 80% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  z-index: -1;
}

.pricing-two .shape .shape-2{
  width: 117px;
  height: 117px;
  left: -50px !important;
  top: 70% !important;
  background-repeat: no-repeat;
  z-index: -1;
}


/** news-four **/

.news-four .shape .shape-1 {
  left: 90px !important;
  background: #fab567;
  border-radius: 50%;
}

.news-four .shape .shape-2 {
  left: -110px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.news-four .news-block-one .inner-box .image{

}

.news-four .news-block-one .inner-box .theme-btn{
  padding: 16px 30px;
}


/************** home-5 **************/

/** banner-two **/

.banner-two{
  width: 100%;
  padding-top: 255px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-two:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.6;
}

.banner-two .content-box h2{
  color: #fff;
}

.content_block_eight .form-inner{
  width: 100%;
  background: #fff;
}

.nice-select{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #848484;
  font-family: 'Oxygen', sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0px 20px;
}

.nice-select:focus{

}

.nice-select:after{
  position: absolute;
  content: "\f107";
  font-family: 'Font Awesome 5 Pro';
  font-size: 16px;
  color: #848484;
  top: 0px;
  right: 30px;
  margin: 0px;
  margin: 0px;
  border: none !important;
  transform: rotate(0deg) !important;
}

#ui-datepicker-div.ui-widget-content {
  background: transparent;
  color: #252525;
  font-size: 14px;
  border-radius: 0px;
  width: 270px;
  padding: 0px;
}

#ui-datepicker-div.ui-datepicker .ui-datepicker-header {
  background: #151515 none repeat scroll 0 0;
  border: 2px solid #252525;
  border-radius: 0;
  color: #fff;
  font-weight: 700;
  padding: 5px 0;
  position: relative;
}

.ui-datepicker td a {
  color: #000000 !important;
  text-align: center;
  background-image: none !important;
  background: #f5f5f5 !important;
  border: 1px solid #f9f9f9 !important;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight{
  border: 1px solid #333333 !important;
  background: #333333 !important;
  color: #ffffff !important;
}

.ui-datepicker .ui-datepicker-prev {
  left: 5px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  border-radius: 30%;
  height: 20px;
  position: absolute;
  top: 7px;
  width: 20px;
  background: #fff;
  transition: all 500ms ease;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-next {
  right: 5px;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  top: 7px;
  border: none;
}

.ui-datepicker table {
  border-collapse: collapse;
  font-size: 13px;
  margin: 0 0 0.4em;
  width: 100%;
  z-index: 99999999;
  background: #ffffff;
}

.ui-datepicker th {
  border: medium none;
  font-weight: 600;
  padding: 2px 3px;
  text-align: center;
}

.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active{
  background: #43c3ea;
  color: #fff;
  border-color: #43c3ea;
}

.content_block_eight .content-box h3{
  font-size: 28px;
  line-height: 36px;
}

.content_block_eight .content-box .form-group input,
.content_block_eight .content-box .form-group textarea{
  border: 1px solid #e5e5e5;
}

.content_block_eight .content-box .form-group textarea{
  height: 100px;
  resize: none;
}

.content_block_eight .content-box .form-group input:focus,
.content_block_eight .content-box .form-group textarea:focus{

}

.content_block_eight .content-box .form-group{
  margin-bottom: 20px;
  padding: 0px 10px;
}

.content_block_eight .content-box .default-form{
  margin: 0px 5px;
}

.content_block_eight .content-box .theme-btn{
  padding: 15px 32px;
  width: 100%;
  color: #fff !important;
}

.content_block_eight .content-box .theme-btn:hover{
  background: transparent;
  color: #bc8664 !important;
}

.content_block_eight .content-box .theme-btn:before,
.content_block_eight .content-box .theme-btn:after{
  display: none;
}

.banner-two .theme-btn.btn-two{
  font-family: 'Oxygen', sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding-top: 17px;
  padding-bottom: 17px;
}

.banner-two .pattern-layer{
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}


/** service-five **/

.service-five .upper-box .text p{
  color: #141417;
}

.service-block-five .inner-box{
  overflow: hidden;
  background: #f8f6f4;
}

.service-block-five .inner-box h3{
  font-size: 22px;
}

.service-block-five .inner-box h3 a{
  color: #141417;
}

.service-block-five .inner-box:hover h3 a{
  color: #fff;
}

.service-block-five .inner-box h3 a:hover{
  text-decoration: underline;
}

.service-block-five .inner-box .link a{
  border: 1px solid #dfddda;
  color: #2d2d31;
}

.service-block-five .inner-box:hover .link a{
  color: #fff;
}

.service-block-five .inner-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  background: #2d2d31;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.service-block-five .inner-box:hover:before{
  height: 100%;
  top: 0px;
}


/** funfact-four **/

.funfact-four .counter-block-one .inner-box .count-outer{
  font-family: 'Frank Ruhl Libre', serif;
  color: #141417;
}

.funfact-four .counter-block-one .inner-box p{
  color: #141417;
}

.funfact-four .pattern-layer{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}


/** about-five **/

.image_block_five .image-box .image{
  overflow: hidden;
}

.image_block_five .image-box .image img{
  width: 100%;
}

.image_block_five .image-box .image:before{
  position: absolute;
  top: 0;
  left: -85%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_five .image-box:hover .image:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_five .image-box .text{
  width: 200px;
  background: #2d2d31;
}

.image_block_five .image-box .text p{
  color: #fff;
}

.image_block_five .image-box .text p span{

}

.image_block_five .image-box .icon-box{
  background: #fff;
}

.image_block_five .image-box .shape .shape-1{
  left: 90px !important;
  top: -40px !important;
}

.image_block_five .image-box .shape .shape-2 {
  top: 60% !important;
  left: 50% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_five .image-box .shape .shape-3 {
  top: 30% !important;
  left: 10% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_five .image-box .shape .shape-4{
  top: 30% !important;
  left: 85% !important;
  width: 62px;
  height: 62px;
  background-repeat: no-repeat;
}

.list-style-two li {
  color: #141417;
}

.list-style-two li:before {
  position: absolute;
  content: '\e90f';
  font-family: 'icomoon';
  left: 0px;
  top: 0px;
  font-size: 12px;
}

.content_block_nine .content-box .video-btn a{
  color: #fff;
  line-height: 24px;
  min-height: 88px;
}

.content_block_nine .content-box .video-btn a i{
  position: absolute;
  left: 35px;
  top: 25px;
  font-size: 40px;
  color: #fff;
  text-align: center;
}

.content_block_nine .content-box .video-btn a:hover{
  background: #141417;
}


/** team-one-light **/

.team-two{
  background: #222226;
  padding: 280px 0px 245px 0px;
}

.team-two .team-block-one .inner-box .image-box .social-links-two li a:hover{

}

.team-two .team-block-one .inner-box .lower-content h4:before{
  display: none;
}

.team-two .team-block-one .inner-box .lower-content h4{
  padding: 0px;
}

.team-two .team-block-one .inner-box .lower-content h4 a{
  color: #fff;
}

.team-two .team-block-one .inner-box .lower-content h4 a:hover{

}

.team-two .pattern-layer .pattern-1{
  width: 100%;
  height: 141px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.team-two .pattern-layer .pattern-2{
  width: 100%;
  height: 141px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.team-two .pattern-layer .pattern-3{
  width: 662px;
  height: 426px;
  left: -100px;
  background-repeat: no-repeat;
}

.team-two .pattern-layer .pattern-4{
  width: 669px;
  height: 794px;
  background-repeat: no-repeat;
}


/** testimonial-five **/

.testimonial-five .owl-nav{
  position: absolute;
  left: -460px;
  bottom: 56px;
}

.testimonial-five .owl-nav button{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
}

.testimonial-five .nav-style-one .owl-nav button.owl-prev:before{
  display: none;
}

.testimonial-five .nav-style-one .owl-nav button.owl-prev{
  top: 0px;
  margin-right: 10px;
}

.testimonial-five .owl-nav button:hover{
  color: #fff;
}

.testimonial-five .testimonial-block-one .inner-box{
  background: #fff;
  box-shadow: 0 20px 40px rgb(0 0 0 / 10%);
}

.testimonial-five .testimonial-block-one .inner-box .text {
  box-shadow: none;
}

.testimonial-five .shape .shape-1{
  left: 75% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}


/** pricing-three **/

.pricing-three .tab-btn-box .tab-btns:before {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 40px;
  width: 80px;
  content: "";
  border-radius: 30px;
  background: #f7f7f7;
  border: 1px solid #e5e5e5;
}

.pricing-three .tab-btn-box .tab-btns li{
  position: relative;
  display: inline-block;
  min-width: 160px;
  text-align: right;
  font-size: 15px;
  padding: 10px 50px 10px 0px;
  line-height: 20px;
  color: #141417;
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 500ms ease;
}

.pricing-three .tab-btn-box .tab-btns li:last-child{
  padding-right: 0;
  padding-left: 50px;
  text-align: left;
}

.pricing-three .tab-btn-box .tab-btns li.active-btn:before {
  right: 2px;
}

.pricing-three .tab-btn-box .tab-btns li:before {
  position: absolute;
  top: 5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  content: "";
  right: -38px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-three .tab-btn-box .tab-btns li:last-child:before{
  display: none;
}

.pricing-three .theme-btn.btn-seven{
  padding-top: 16px;
  padding-bottom: 16px;
}

.pricing-three .pricing-block-one.active-block .theme-btn.btn-seven{
  color: #fff !important;
}

.pricing-three .pricing-block-one.active-block .theme-btn.btn-seven:after{
  border-right: 2px solid #fff !important;
  border-top: 2px solid #fff !important;
}

.pricing-three .pricing-block-one.active-block .theme-btn.btn-seven span:before{
  color: #fff;
}

.pricing-three .pricing-block-one.active-block .theme-btn.btn-seven:hover:before{
  background: #fff;
}

.pricing-three .pricing-block-one.active-block .theme-btn.btn-seven:hover span:after{
  color: #141417;
}


/** contact-two **/

.contact-two{
  background: #f8f6f4;
  overflow: hidden;
}

.contact-two .pattern-layer .pattern-1{
  width: 688px;
  height: 641px;
  background-repeat: no-repeat;
}

.contact-two .pattern-layer .pattern-3{
  width: 100%;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.contact-two .inner-box h2 a{
  color: #141417;
}

.contact-two .inner-box h2 a:hover{

}

.contact-two .inner-box .theme-btn:before{
  background: #141417;
}

.contact-two .inner-box .theme-btn{
  color: #fff !important;
}

.contact-two .inner-box .theme-btn:after{
  border-right: 2px solid #fff !important;
  border-top: 2px solid #fff !important;
}

.contact-two .inner-box .theme-btn span:before{
  color: #fff;
}

.contact-two .inner-box .theme-btn:hover span:after{
  color: #141417;
}

.contact-two .inner-box .theme-btn{
  color: #fff !important;
  padding-top: 16px;
  padding-bottom: 16px;
}

.contact-two  .light-icon{
  top: 60px;
  left: 2px;
  color: #efedeb;
  font-size: 300px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.contact-two .pattern-layer .pattern-2{
  width: 689px;
  height: 707px;
  background-repeat: no-repeat;
}


/** news-five **/

.news-five .news-block-one .inner-box .post-date-two{
  background: #fff;
}

.news-five .news-block-one .inner-box .post-date-two h4{
  color: #141417;
}

.news-five .news-block-one .inner-box .image{

}

.news-five .news-block-one .theme-btn.btn-three{
  padding-top: 16px;
  padding-bottom: 16px;
}

.news-five .upper-box p{
  color: #141417;
}


/************** home-6 **************/

.slider-four{
  background: #f2f0ed;
  overflow: hidden;
}

.slider-four .slide-item:before,
.slider-four .slide-item:after{
  display: none;
}

.slider-four .slide-item{
  padding-bottom: 390px;
}

.slider-four .content-box{
  max-width: 630px;
}

.slider-four .content-box h2,
.slider-four .content-box p{
  color: #141417;
}

.slider-four .image-box .shape .shape-1{
  width: 370px;
  height: 370px;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.slider-four .image-box .shape .shape-2{
  top: 100px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.slider-four .image-box .shape .shape-3{
  left: 60%;
  top: 60%;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.slider-four .image-box .image{
  color: #fff;
  opacity: 0;
  -webkit-transform: translateY(200px);
  -moz-transform: translateY(200px);
  -ms-transform: translateY(200px);
  -o-transform: translateY(200px);
  transform: translateY(200px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.slider-four .active .image-box .image{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.slider-four .shape .shape-4{
  left: 230px !important;
  top: -160px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.slider-four .pattern-layer .pattern-1{
  width: 520px;
  height: 815px;
  background-repeat: no-repeat;
}

.slider-four .pattern-layer .pattern-2{
  width: 797px;
  height: 694px;
  background-repeat: no-repeat;
}

.slider-four .banner-carousel .owl-nav button{
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.40);
}

.slider-four .banner-carousel .owl-nav button:hover{
  background: #222;
}

.slider-four .banner-carousel .owl-nav button:before{
  background: rgba(0, 0, 0, 0.40);
}


/** feature-four **/

.feature-four .inner-container{
  overflow: hidden;
  margin-top: -210px;
  z-index: 2;
}

.feature-block-four .inner-box{
  background: #2d2d31;
  overflow: hidden;
}

.feature-block-four .inner-box .icon-box{
  background: rgba(255, 255, 255, 0.05);
  overflow: hidden;
  z-index: 1;
}

.feature-block-four .inner-box .icon-box{
  transition: all 500ms ease;
}

.feature-block-four .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.feature-block-four .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.feature-block-four .inner-box h4{
  color: #fff;
}

.feature-block-four .inner-box p{
  color: #a9a9aa;
}

.feature-four .feature-block{
  padding: 0px 0px;
}

.feature-four .feature-block:nth-child(2) .feature-block-four .inner-box{
  background: #222226;
}

.feature-block-four .inner-box:before{
  content: '';
  width: 100%;
  height: 273px;
  border-radius: 50%;
  position: absolute;
  top: -148px;
  left: 0px;
  background: rgba(255, 96, 0, 0.1);
  transform: scale(0);
  transition: all 500ms ease;
}

.feature-block-four .inner-box:hover:before{
  transform: scale(1);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.feature-block-four .inner-box .hover-curve{
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.3s linear;
}

.feature-block-four .inner-box:hover .hover-curve{
  opacity: 1;
}

.feature-block-four .inner-box .hover-curve:before{
  content: '';
  width: 130%;
  height: 250px;
  border-radius: 50%;
  position: absolute;
  top:0px;
  left:-15%;
  background: rgba(255, 96, 0, 0.1);
  z-index: 1;
  transform: translate(0, -50%);
  opacity: 0;
  transition: all 300ms ease;
}

.feature-block-four .inner-box:hover .hover-curve:before{
  opacity: 1;
  transform: translate(0% , -20%);
  transition: all 0.6s linear;
}

.feature-block-four .inner-box .hover-curve:after{
  content: '';
  width: 130%;
  height: 320px;
  border-radius: 50%;
  position: absolute;
  top:0px;
  left:-15%;
  z-index: 0;
  background: rgba(255, 96, 0, 0.1);
  transform: translate(0, -50%);
  opacity: 0;
  transition: all 300ms ease;
}

.feature-block-four .inner-box:hover .hover-curve:after{
  opacity: 1;
  transform: translate(0% , -15%);
  transition: all 0.9s linear;
}



/** about-six **/


.image_block_six .image-box img{
  width: 100%;
}

.image_block_six .shape .shape-1{
  width: 203px;
  height: 212px;
  background-repeat: no-repeat;
}

.image_block_six .shape .shape-2{
  left: 60% !important;
  top: 50% !important;
  width: 203px;
  height: 212px;
  background-repeat: no-repeat;
}

.image_block_six .shape .shape-3{
  top: 70% !important;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.about-six .content_block_nine .content-box .video-btn a{
  border-radius: 50px;
}

.bg-color-2{
  background: #f2f0ed;
}


/** service-six **/

.service-six .pattern-layer .pattern-1{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.service-block-six .inner-box{
  background: #fff;
  overflow: hidden;
  z-index: 1;
}

.service-block-six .inner-box:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 0%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.service-block-six .inner-box:hover:before{
  height: 100%;
}

.service-block-six .inner-box .icon-box{
  background: #f7f5f2;
}

.service-block-six .inner-box:hover .icon-box{
  color: #fff;
}

.service-block-six .inner-box .icon-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.service-block-six .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.service-block-six .inner-box h4 a{
  display: inline-block;
  color: #141417;
}

.service-block-six .inner-box p{
  transition: all 500ms ease;
}

.service-block-six .inner-box:hover h4 a,
.service-block-six .inner-box:hover p{
  color: #fff;
}

.service-block-six .inner-box .link a{
  color: #141417;
}

.service-block-six .inner-box .link a:hover{
  letter-spacing: 0.5px;
}

.service-block-six .inner-box:hover .link a{
  color: #fff;
}

.service-block-six .inner-box h4 a:hover{
  text-decoration: underline;
}

.service-block-six .inner-box:hover{
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
}

.service-six .theme-btn.btn-eight{
  padding-top: 17px;
  padding-bottom: 17px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
}

.service-six .pattern-layer .pattern-2{
  width: 392px;
  height: 616px;
  background-repeat: no-repeat;
}

.service-six .pattern-layer .pattern-3{
  width: 461px;
  height: 684px;
  background-repeat: no-repeat;
}


/** support-area **/

.content_block_ten .content-box .list-style-two li{
  width: 50%;
}

.content_block_ten .content-box .list-style-two li:before{

}

.support-area .theme-btn.btn-eight{
  padding-top: 17px;
  padding-bottom: 17px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
}

.support-area .map-box img{
  width: 100%;
}

.support-area .map-box .shape .shape-1{
  left: 55% !important;
  width: 203px;
  height: 212px;
  background-repeat: no-repeat;
}

.support-area .map-box .shape .shape-2{
  left: 75px !important;
  top: 35% !important;
  width: 203px;
  height: 212px;
  background-repeat: no-repeat;
}


/** process-two **/

.process-two{
  background: #252628;
}

.process-two .sec-title-six h2{
  color: #fff;
}

.processing-block-two .inner-box .icon-box .hov-icon{
  opacity: 0;
  color: #fff;
}

.processing-block-two .inner-box:hover .icon-box .hov-icon{
  opacity: 1;
}

.processing-block-two .inner-box:hover .icon-box .icon{
  opacity: 0;
}

.processing-block-two .inner-box .icon-box{
  background: #fff;
}

.processing-block-two .inner-box .icon-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  transform: scale(0,0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.processing-block-two .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.processing-block-two .inner-box .icon-box .count-box{
  right: -14px;
  color: #fff;
  box-shadow: 0px 0px 30px 0px rgba(241, 89, 42, 0.50);
}

.processing-block-two .inner-box:hover .icon-box .count-box{
  background: #fff;
}

.processing-block-two .inner-box .shape{
  position: absolute;
  top: 60px;
  right: -65px;
  width: 92px;
  height: 30px;
  background-repeat: no-repeat;
}

.processing-block-two .inner-box h4{
  color: #fff;
}


/** testimonial-six **/


.testimonial-six .owl-nav {
  position: absolute;
  left: -400px;
  bottom: 35px;
}

.testimonial-six .owl-nav button{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  color: #141417;
  border-radius: 50%;
}

.testimonial-six .nav-style-one .owl-nav button.owl-prev:before{
  display: none;
}

.testimonial-six .nav-style-one .owl-nav button.owl-prev{
  margin-right: 10px;
}

.testimonial-six .testimonial-block-one .inner-box .text{
  box-shadow: none;
}

.testimonial-six .owl-nav button:hover{
  color: #fff;
}

.testimonial-six .pattern-layer{
  width: 780px;
  height: 677px;
  background-repeat: no-repeat;
}


/** team-three **/

.team-three .team-block-one .inner-box .image-box .social-links-two li a:hover{

}

.team-three .team-block-one .inner-box .lower-content .inner{
  background: #fff;
  margin-top: -53px;
}

.team-three .team-block-one .inner-box .lower-content h4:before{
  display: none;
}

.team-three .team-block-one .inner-box .lower-content h4 a:hover{

}

.team-three .pattern-layer{
  width: 383px;
  height: 815px;
  bottom: 315px;
  left: -100px;
  background-repeat: no-repeat;
}


/** cta-four **/

.cta-four{
  background: #252628;
  padding: 135px 0px;
}

.cta-four .text{
  padding-left: 350px;
}

.cta-four .text h2{
  color: #fff;
}

.cta-four .text .image{
  top: -60px;
  left: -50px;
}

.cta-four .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  animation: slide 60s linear infinite;
  -webkit-animation: slide 60s linear infinite;
}


/** news-six **/

.news-six .news-block-one .inner-box .category a{

}

.news-six .news-block-one .inner-box .lower-content h4{
  line-height: 28px;
}

.news-six .news-block-one .inner-box .lower-content h4 a:hover{

}

.news-six .news-block-one .inner-box .post-info li:last-child a{
  color: #808080;
}

.news-six .news-block-one .theme-btn.btn-three{
  font-size: 16px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  border-radius: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.news-six .news-block-one .theme-btn.btn-three:before{

}

.news-six .news-block-one .inner-box .post-info li:before{
  right: -16px;
}

.news-six .news-block-one .inner-box:hover{
  transform: translateY(-10px);
}


/** subscribe-two **/

.subscribe-two .inner-container{
  overflow: hidden;
}

.subscribe-two h2{
  color: #fff;
}

.subscribe-two .form-inner .form-group{
  position: relative;
  padding-right: 180px;
  margin-bottom: 0px;
}

.subscribe-two .form-inner .form-group input{
  height: 60px;
  color: #808080;
  border-radius: 30px;
  padding: 15px 30px;
}

.subscribe-two .form-inner .form-group input:focus{

}

.subscribe-two .form-inner .form-group button{
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Muli', sans-serif;
  border-radius: 30px;
  padding: 17px 41px;
}

.subscribe-two .inner-container .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: center;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}

.subscribe-two .inner-container{
  margin-bottom: -120px;
  overflow: hidden;
}

.subscribe-two .form-inner .form-group button{
  background: #fff;
  color: #141417 !important;
}

.subscribe-two .form-inner .form-group button:hover{
  color: #fff !important;
}


/************** home-7 **************/

.slider-five .pattern-layer{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.slider-five{
  padding-top: 245px;
}

.slider-five .banner-carousel .slide-item{
  padding-bottom: 330px;
  padding-top: 30px;
}

.slider-five .banner-carousel .slide-item:before,
.slider-five .banner-carousel .slide-item:after{
  display: none;
}

.slider-five .content-box h2{
  font-weight: 900;
  color: #141417;
  line-height: 76px;
}

.slider-five .content-box{
  margin-right: 70px;
  width: auto;
}

.slider-five .image-box{
  overflow: hidden;
}

.slider-five .image-box .image{
  -webkit-transform: translateX(300px);
  -moz-transform: translateX(300px);
  -ms-transform: translateX(300px);
  -o-transform: translateX(300px);
  transform: translateX(300px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.slider-five .active .image-box .image{
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0ms;
  -moz-transition-delay: 0ms;
  -ms-transition-delay: 0ms;
  -o-transition-delay: 0ms;
  transition-delay: 0ms;
}

.slider-five .shape .shape-1{
  left: -100px !important;
  top: 60% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.slider-five .shape .shape-2{
  left: 65% !important;
  top: 270px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.slider-five .shape .shape-3{
  left: 90% !important;
  top: 60% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}


/** feature-five **/

.feature-five .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.feature-five .inner-container{
  margin-top: -173px;
}

.feature-block-five .inner-box .icon-box{
  background: rgba(255, 255, 255, 0.07);
}

.feature-block-five .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.feature-block-five .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.feature-block-five .inner-box h3{
  font-size: 22px;
  color: #fff;
}

.feature-block-five .inner-box p,
.feature-block-five .inner-box p a{
  color: #a9a9aa;
}

.feature-block-five .inner-box p a:hover{

}


/** about-seven **/

.image_block_seven .image-box{
  padding-right: 240px;
}

.image_block_seven .image-box img{
  width: 100%;
}

.image_block_seven .shape .shape-1{
  left: 40px !important;
  top: 70% !important;
  background-repeat: no-repeat;
}

.image_block_seven .shape .shape-2{
  left: 90% !important;
  top: 125px !important;
  background-repeat: no-repeat;
}

.content_block_11 .content-box .list li{
  width: 50%;
  color: #141417;
}

.content_block_11 .content-box .list li:before{
  position: absolute;
  content: '\e933';
  font-family: 'icomoon';
  left: 0px;
  top: 0px;
  font-size: 26px;
  font-weight: 400;
}

.about-seven .bg-layer{
  width: 265px;
  height: 398px;
  background-repeat: no-repeat;
}


/** service-seven **/

.service-block-seven .inner-box{
  background: #fff;
}

.service-block-seven .inner-box:before{
  position: absolute;
  content: '';
  background: #2d2d31;
  width: 100%;
  height: 0%;
  left: 0px;
  top: 0px;
  transition: all 500ms ease;
}

.service-block-seven .inner-box:hover:before{
  height: 100%;
}

.service-block-seven .inner-box .icon-box{
  border: 1px solid #e5e5e5;
}

.service-block-seven .inner-box:hover .icon-box{
  color: #fff;
}

.service-block-seven .inner-box .icon-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.service-block-seven .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.service-block-seven .inner-box h3{
  font-size: 22px;
}

.service-block-seven .inner-box h3 a{
  color: #141417;
}

.service-block-seven .inner-box:hover h3 a,
.service-block-seven .inner-box:hover .link a{
  color: #fff;
}

.service-block-seven .inner-box p{
  color: #141417;
}

.service-block-seven .inner-box .link a{
  color: #141417;
}

.service-block-seven .inner-box .link a:hover{
  letter-spacing: 0.5px;
}

.service-block-seven .inner-box .link a i{
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.service-block-seven .inner-box:hover p{
  color: #a9a9aa;
}

.service-seven .bg-layer{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


/** chooseus-five **/

.chooseus-five{
  background: #27272b;
}

.chooseus-five .bg-layer{
  width: calc(50% + 150px);
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.chooseus-five .pattern-layer{
  width: calc(50% + 60px);
  height: calc(100% + 100px);
  top: -50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.content_block_12 .content-box h2{
  color: #fff;
}

.content_block_12 .content-box p{
  color: #a9a9aa;
}

.content_block_12 .content-box .single-item .icon-box{
  color: #fff;
}

.content_block_12 .content-box .single-item h3{
  font-size: 22px;
  color: #fff;
}

.content_block_12 .content-box .single-item p{
  color: #a9a9aa;
}

.testimonial-one.home-7{
  background: #fefefe;
}

.testimonial-one.home-7 .bg-layer{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


/** process-three **/

.process-three .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.processing-block-three .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 110px;
  height: 100px;
  left: 0px;
  top: 0px;
  z-index: -1;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%, 10px 0px);
}

.processing-block-three .inner-box .icon-box .count-text{
  color: #fff;
  top: -25px;
  right: -14px;
}

.processing-block-three .inner-box h3{
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}

.processing-block-three .inner-box .shape{
  top: 35px;
  right: -60px;
  width: 92px;
  height: 30px;
  background-repeat: no-repeat;
}

.processing-block:nth-child(2) .processing-block-three .inner-box .shape{
  right: -100px;
}


/** team-four **/

.team-four .team-block-one .inner-box{
  max-width: 370px;
  margin: 0 auto;
  background: #fff;
}

.team-four .team-block-one .inner-box .lower-content .shape{
  top: -39px;
  width: 100%;
  height: 39px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.team-four .team-block-one .inner-box .lower-content .overlay-shape{
  top: -39px;
  width: 100%;
  height: 39px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  opacity: 0;
}

.team-four .team-block-one .inner-box:hover .lower-content .overlay-shape{
  opacity: 1;
}

.team-four .team-block-one .inner-box:hover .lower-content .shape{
  opacity: 0;
}

.team-four .team-block-one .inner-box .lower-content:before{
  position: absolute;
  content: '';
  background: #2d2d31;
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.team-four .team-block-one .inner-box:hover .lower-content:before{
  height: 100%;
  top: 0px;
}

.team-four .team-block-one .inner-box .lower-content h4{
  font-size: 22px;
}

.team-four .team-block-one .inner-box:hover .lower-content h4 a,
.team-four .team-block-one .inner-box:hover .lower-content .designation{
  color: #fff;
}

.team-four .team-block-one .inner-box:hover .lower-content h4:before{
  background: #fff;
}

.team-four .team-block-one .inner-box .lower-content h4 a:hover{
  text-decoration: underline;
}

.clients-one.home-7 .pattern-layer .pattern-1{
  width: 629px;
  height: 100%;
  background-repeat: no-repeat;
}

.clients-one.home-7 .pattern-layer .pattern-2{
  width: 623px;
  height: 100%;
  background-repeat: no-repeat;
}


/** news-seven **/

.news-seven .upper-box .text p{
  color: #141417;
}

.news-seven .news-block-one .inner-box .post-date-two{
  background: #fff;
}

.news-seven .news-block-one .inner-box .post-date-two h4{
  color: #141417;
}

.news-seven .news-block-one .inner-box .lower-content h4{
  font-size: 22px;
}

.news-seven .news-block-one .inner-box .lower-content .theme-btn{
  border-radius: 0px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
  padding: 16px 30px;
}


/** appointment-one **/

.appointment-one{
  overflow: hidden;
}

.appointment-one .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.appointment-one .content-box .column{
  padding: 0px 10px;
}

.appointment-one .content-box .form-inner{
  margin: 0px 5px;
}

.appointment-one .content-box .form-group input,
.appointment-one .content-box .form-group textarea{
  border-radius: 0px;
  color: #141417;
  font-family: 'Oxygen', sans-serif;
}

.appointment-one .content-box .form-group textarea{
  height: 120px;
  resize: none;
  display: block;
}

.appointment-one .content-box .form-group button{
  width: 100%;
  padding: 17px 30px;
  font-size: 16px;
}

.appointment-one .image-layer .image-2{
  left: 395px;
}

.appointment-one .shape .shape-1{
  left: 300px !important;
  top: 300px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.appointment-one .shape .shape-2{
  left: 90% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.appointment-one .shape .shape-3{
  left: 35% !important;
  top: 80% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}


/************** home-8 **************/

/** slider-six **/

.slider-six .banner-carousel .slide-item:before{
  display: none;
}

.slider-six .content-inner{
  overflow: hidden;
  max-width: 550px;
  width: 100%;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.slider-six .active .content-inner{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.slider-six .content-inner h2,
.slider-six .content-inner p{
  color: #fff;
  z-index: 1;
}

.slider-six .content-inner .theme-btn{
  background: #fff;
  color: #141417 !important;
  padding-top: 22px;
  padding-bottom: 22px;
}

.slider-six .content-inner .theme-btn:hover{
  color: #fff !important;
}

.bubbleContainer {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

div[class^="bubble-"] {
  height: 1px;
  width: 1px;
  position: absolute;
  background: url(../images/icons/bubble.png) no-repeat center center;
  background-size: cover;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}

.bubble-1 {
  bottom: 0px;
  left: 60%;
  -webkit-animation: bubble-movement 30s infinite ease-in -5.7s;
  animation: bubble-movement 30s infinite ease-in -5.7s;
}

.bubble-2 {
  bottom: 0px;
  left: 90%;
  -webkit-animation: bubble-movement 30s infinite ease-in -4.94s;
  animation: bubble-movement 30s infinite ease-in -4.94s;
}

.bubble-3 {
  bottom: 0px;
  left: 20%;
  -webkit-animation: bubble-movement 30s infinite ease-in -1.2s;
  animation: bubble-movement 30s infinite ease-in -1.2s;
}

@-webkit-keyframes bubble-movement {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    -webkit-transform: translate3d(-50%, -700px, 0);
    transform: translate3d(-50%, -700px, 0);
    height: 200px;
    width: 200px; } }

@-moz-keyframes bubble-movement {
  0% {
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    transform: translate3d(-50%, -700px, 0);
    height: 200px;
    width: 200px; } }

@-o-keyframes bubble-movement {
  0% {
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    transform: translate3d(-50%, -700px, 0);
    height: 200px;
    width: 200px; } }

@keyframes bubble-movement {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    height: 1px;
    width: 1px; }
  100% {
    -webkit-transform: translate3d(-50%, -700px, 0);
    transform: translate3d(-50%, -700px, 0);
    height: 200px;
    width: 200px; } }

.slider-six .pattern-layer{
  width: 100%;
  height: 249px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  z-index: 2;
}


/** feature-six **/

.feature-block-six .inner-box .image-box{
  overflow: hidden;
}

.feature-block-six .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.feature-block-six .inner-box:hover .image-box img{
  opacity: 0.2;
  transform: scale(1.05);
}

.feature-block-six .inner-box .lower-content h3{
  font-size: 24px;
  line-height: 32px;
}

.feature-block-six .inner-box .lower-content h3 a{
  color: #141417;
}

.feature-block-six .inner-box .lower-content h3 a:hover{

}

.feature-block-six .inner-box{
  background: #fff;
}

.feature-block-six .inner-box:hover{
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, .10);
}

.feature-block-six .inner-box .lower-content{
  padding-left: 30px;
  padding-right: 30px;
}

.sec-title-eight h5{
  text-transform: uppercase;
}


/** about-eight **/

.about-eight .content_block_nine .content-box .video-btn a{
  background: transparent;
  border: 2px solid #fbdbc2;
  min-height: 92px;
}

.about-eight .content_block_nine .content-box .video-btn a:hover{
  background: #222;
  border-color: #222;
}

.image_block_eight .image-box .image{
  overflow: hidden;
}

.image_block_eight .image-box .image img{
  width: 100%;
}

.image_block_eight .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_eight .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_eight .image-box .text{
  width: 270px;
  background: #2b2f2e;
}

.image_block_eight .image-box .text p{
  color: #fff;
}

.image_block_eight .image-box .shape .shape-1{
  left: 30px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_eight .image-box .shape .shape-2{
  left: 65% !important;
  top: 65% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_eight .image-box .shape .shape-3{
  left: 80% !important;
  top: -60px !important;
  width: 135px;
  height: 135px;
  background-repeat: no-repeat;
}



/** service-eight **/

.service-eight{
  background: #f7f6f4;
}

.service-eight .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  border-radius: 10px;
  width:110px;
  margin-right: 10px;
  margin-bottom: 20px;
  background: #ccc;
  padding: 12px 6px;
  text-align: center;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #141417;
  cursor: pointer;
  z-index: 1;
  transition: all 500ms ease;
}

.service-eight .tab-btns li:before{
  position: absolute;
  content: '';
  width: 100%;
  border-radius: 10px;
  height: 0%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 300ms ease;
}

.service-eight .tab-btns li:hover:before,
.service-eight .tab-btns li.active-btn:before{
  height: 100%;
}

.service-eight .tab-btns li:hover,
.service-eight .tab-btns li.active-btn{
  color: #fff;
}

.service-eight .tab-btns li:after{
  position: absolute;
  content: '';
  width: 24px;
  height: 24px;
  left: 45px;
  bottom: 0px;
  opacity: 0;
  transform: rotate(45deg);
  z-index: -1;
  transition: all 700ms ease;
}

.service-eight .tab-btns li:last-child{
  margin-right: 0px;
}

.service-eight .tab-btns li:hover:after,
.service-eight .tab-btns li.active-btn:after{
  bottom: -7px;
  opacity: 1;
}

.service-eight .image-box-one .shape .shape-1{
  left: -70px;
  bottom: -60px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.service-eight .image-box-one .shape .shape-2{
  right: -20px;
  top: 30px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.service-eight img{
  width: 100%;
}

.service-eight .image{
  overflow: hidden;
}

.service-eight .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.service-eight .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.service-eight .content-box .theme-btn{
  padding-top: 12px;
  padding-bottom: 12px;
}

.service-eight .content-box .list li{
  color: #141417;
}

.content_block_13 .content-box .list li{
  width: 50%;
}

.service-eight .content-box .list li:before{
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 0px;
  top: 7px;
}


/** project-three **/

.project-block-three .inner-box .image-box{
  overflow: hidden;
  background: #000;
}

.project-block-three .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.project-block-three .inner-box:hover .image-box img{
  opacity: 0.2;
  transform: scale(1.05);
}

.project-block-three .inner-box .view-btn{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.project-block-three .inner-box .view-btn a{
  font-size: 60px;
  line-height: 60px;
  color: #fff;
  opacity: 0;
}

.project-block-three .inner-box:hover .view-btn a{
  opacity: 1;
}


/** testimonial-seven **/

.testimonial-seven{
  overflow: hidden;
  background: #f7f6f4;
}

.testimonial-block-two{
  position: relative;
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
}

.testimonial-block-two .inner-box{
  background: #fff;
}

.testimonial-seven .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-seven .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-seven .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-block-two .inner-box .rating li{
  color: #ffc92e;
}

.testimonial-block-two .inner-box p{
  color: #141417;
  line-height: 32px;
}

.testimonial-block-two .inner-box .author h3{
  font-size: 22px;
}

.testimonial-seven .owl-nav{
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 100%;
}

.testimonial-seven .owl-nav button{
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #fff;
  text-align: center;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}

.testimonial-seven .owl-nav button.owl-prev{
  left: 0px;
}

.testimonial-seven .owl-nav button.owl-next{
  right: 0px;
}

.testimonial-seven .owl-nav button:hover{
  color: #fff;
}

.testimonial-seven .shape .shape-1{
  left: 120px;
  bottom: -50px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.testimonial-seven .shape .shape-2{
  right: 120px;
  top: -50px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.testimonial-seven .pattern-layer .pattern-1{
  width: 555px;
  height: 555px;
  background: #fff;
  border-radius: 50%;
  left: -85px;
  top: -150px;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.testimonial-seven .pattern-layer .pattern-2{
  width: 555px;
  height: 555px;
  background: #fff;
  border-radius: 50%;
  right: -300px;
  bottom: -85px;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}


/** pricing-four **/

.pricing-four .pricing-block-one .pricing-table{
  border: none;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
}

.pricing-four .pricing-block-one .pricing-table .table-content .feature-list li{
  font-family: 'Poppins', sans-serif;
}

.pricing-four .pricing-block-one .pricing-table .table-header h6{
  padding: 0px;
  border: none;
}

.pricing-four .pricing-block-one .pricing-table .table-content{
  background: #f7f6f4;
}

.pricing-four .pricing-block-one .theme-btn{
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.pricing-four .pricing-block-one.active-block .pricing-table .table-header h6{
  color: #fff;
}

.pricing-four .pricing-block-one.active-block .pricing-table .table-content .feature-list li:before{
  background: #fff;
}

.pricing-four .pricing-block-one.active-block .pricing-table .theme-btn{
  background: #fff;
  border-color: #fff;
}

.pricing-four .pricing-block-one.active-block .shape .shape-3{
  width: 240px;
  height: 200px;
  background: #f9ae46;
  border-radius: 50%;
  left: -50px;
  top: -170px;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.pricing-four .pricing-block-one.active-block .shape .shape-4{
  right: -100px;
  bottom: -100px;
  width: 200px;
  height: 200px;
  background: #f9ad46;
  border-radius: 50%;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}


/** cta-five **/

.cta-five{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
}

.cta-five:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.6;
  z-index: 1;
}

.cta-five .sec-title-eight h2{
  color: #fff;
}

.cta-five .shape .shape-1{
  width: 200px;
  height: 209px;
  background-repeat: no-repeat;
}

.cta-five .shape .shape-2{
  left: 95% !important;
  top: 50% !important;
  width: 200px;
  height: 209px;
  background-repeat: no-repeat;
}


/** news-eight **/

.news-eight .news-block-one .inner-box .post-date-two{
  background: #fff;
}

.news-eight .news-block-one .inner-box .post-date-two h4{
  color: #141417;
}

.news-eight .news-block-one .inner-box .lower-content h4{
  font-size: 22px;
}

.news-eight .news-block-one .inner-box .lower-content .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.news-eight .upper-box .text p {
  color: #141417;
}


/** appointment-two **/

.appointment-two{
  padding-top: 285px;
}

.appointment-two .pattern-layer{
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.appointment-two .content-box .form-group input,
.appointment-two .content-box .form-group textarea{
  font-size: 16px;
  border-radius: 0px;
  color: #141417;
  font-family: 'Poppins', sans-serif;
}

.appointment-two .content-box .form-group textarea{
  height: 120px;
  resize: none;
  display: block;
}

.appointment-two .content-box .form-group button{
  width: 100%;
  padding: 12px 30px;
  font-size: 16px;
}

.appointment-two .content-box .form-group i{
  position: absolute;
  top: 17px;
  right: 30px;
  color: #808080;
  z-index: 1;
}

.appointment-two .content-box .column{
  padding: 0px 10px;
}

.appointment-two .content-box .form-inner{
  margin: 0px 5px;
}

.appointment-two .map-inner .map-box{
  background: #fff;
  padding: 10px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07);
}

.appointment-two #contact-google-map{
  position: relative;
  width: 100%;
  height: 430px;
}

.appointment-two .shape .shape-1{
  left: -65px !important;
  top: -65px !important;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
}

.appointment-two .shape .shape-2{
  left: 70% !important;
  top: 70% !important;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
}


.appointment-two .shape-3{
  top: 230px;
  right: -150px;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}


/** home-nine **/

/** slider-seven **/

.slider-seven{
  border-bottom: 7px solid #494949;
}

.slider-seven .slide-item{
  padding-top: 280px;
}

.slider-seven .slide-item:before{
  display: none;
}

.slider-seven .content-box{
  max-width: 550px;
}

.slider-seven .content-box .theme-btn{
  padding-top: 18px;
  padding-bottom: 16px;
}


/** feature-seven **/

.feature-seven{
  background: #1c1c1c;
}

.feature-block-seven .inner-box .icon-box{
  display: inline-block;
  color: #fff;
  transition: all 500ms ease;
}

.feature-block-seven .inner-box:hover .icon-box{
  transform: scale(1.1);
  color: #ff7f4c;
}

.feature-block-seven .inner-box h6{
  color: #fff;
  line-height: 26px;
}

.feature-block-seven .inner-box:before{
  position: absolute;
  content: '';
  background: rgba(255, 255, 255, 0.10);
  width: 1px;
  height: 100%;
  top: 0px;
  right: 0px;
}

.feature-block:last-child .feature-block-seven .inner-box:before{
  display: none;
}


/** about-nine **/

.about-nine{
  overflow: hidden;
}

.image_block_nine .image-box{
  position: relative;
  min-height: 660px;
}

.image_block_nine .image-box img{
  width: 100%;
}

.image_block_nine .image-box .image{
  bottom: -55px;
}

.image_block_nine .image-box .text{
  width: 250px;
  text-align: center;
}

.image_block_nine .image-box .text:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 20px;
  top: 20px;
}

.image_block_nine .image-box .text:after{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.image_block_nine .image-box .shape .shape-1{
  top: 85px !important;
  left: 270px !important;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
}

.image_block_nine .image-box .shape .shape-2{
  top: 60% !important;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
}

.image_block_nine .image-box .shape .shape-3{
  top: 160px !important;
  width: 172px;
  height: 172px;
  background-repeat: no-repeat;
}


/** service-nine **/

.service-nine{
  background: #f8f6f4;
}

.service-block-eight .inner-box .icon-box{
  top: -40px;
  color: #fff;
}

.service-block-eight .inner-box h4 a{
  color: #141417;
}

.service-block-eight .inner-box h4 a:hover{

}

.service-block-eight .inner-box .image-box{
  overflow: hidden;
}

.service-block-eight .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.service-block-eight .inner-box:hover .image-box img{
  transform: scale(1.05);
  opacity: 0.3;
}

.service-nine .theme-btn{
  padding-top: 18px;
  padding-bottom: 16px;
}



/** chooseus-six **/

.chooseus-six{
  background: #1c1c1c;
}

.chooseus-six .bg-layer{
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.chooseus-six .content-inner{
  width: 50%;
  float: right;
}

.chooseus-six .content-inner .content-box{
  max-width: 750px;
}

.chooseus-six p{
  color: #a9a9aa;
}

.chooseus-six .inner-box .single-item .icon-box{
  background: rgba(255, 255, 255, 0.10);
}

.chooseus-six .inner-box .single-item h4{
  color: #fff;
}

.chooseus-six .sec-title h2{
  color: #fff;
}

.chooseus-six .content-inner{
  padding-bottom: 220px;
}


/** funfact-five **/

.funfact-five .inner-container{
  margin-top: -80px;
  background: #fff;
  width: 100%;
  top: -26px;
  padding-left: 60px;
  padding-right: 60px;
}

.funfact-five .count-outer,
.funfact-five .counter-block-one p{
  color: #141417;
}

.funfact-five .count-outer span{
  font-family: 'Spartan', sans-serif;
}

.funfact-five .inner-container:before{
  position: absolute;
  content: '';
  width: calc(100% - 100px);
  height: 100%;
  left: 50px;
  top: 20px;
  z-index: -1;
}

.funfact-five .counter-block-one p{
  font-weight: 500;
}

.testimonial-three .owl-dots{
  position: relative;
  display: block;
  text-align: center;
  margin-top: 50px;
}


/** pricing-five **/

.pricing-five .tabs-content .shape .shape-2{
  top: 60% !important;
  width: 230px;
  height: 230px;
  background-repeat: no-repeat;
}

.pricing-block-two .pricing-table{
  background: #fff;
}

.pricing-five .content-box{
  margin-right: -30px;
}

.pricing-five .tab-btns{
  border-radius: 0px;
}

.pricing-five .tab-btns li{
  font-family: 'Spartan', sans-serif;
  padding: 12px 15px;
}

.pricing-block-two .pricing-table .feature-list li{
  color: #141417;
}

.pricing-block-two .pricing-table .feature-list li i{
  font-size: 14px;
  margin-right: 8px;
}

.pricing-block-two .pricing-table .theme-btn{
  border: 1px solid #e7e7e7;
  border-radius: 0px;
  color: #141417 !important;
  font-weight: 500;
  font-family: 'Spartan', sans-serif;
  padding-top: 11px;
  padding-bottom: 11px;
}

.pricing-block-two.active-block .pricing-table .theme-btn span{
  color: #141417 !important;
}

.pricing-block-two.active-block .pricing-table{
  background: #1c1c1c;
}

.pricing-block-two.active-block .pricing-table h2,
.pricing-block-two.active-block .pricing-table h5,
.pricing-block-two.active-block .pricing-table span,
.pricing-block-two.active-block .pricing-table p,
.pricing-block-two.active-block .pricing-table li{
  color: #fff;
}

.pricing-block-two.active-block .pricing-table .theme-btn{
  color: #fff !important;
}

.pricing-one.pricing-six.pricing-page-2 .pricing-block-two.active-block .pricing-table .theme-btn span:before,
.pricing-one.pricing-six.pricing-page-2 .pricing-block-two.active-block .pricing-table .theme-btn span:after{
  color: #fff;
}

.pricing-one.pricing-six.pricing-page-2 .pricing-block-two.active-block .pricing-table .theme-btn:after {
  border-right: 2px solid #fff !important;
  border-top: 2px solid #fff !important;
}


/** project-four **/

.project-four .sortable-masonry .filter-tabs li{
  font-family: 'Spartan', sans-serif;
  font-size: 16px;
}

.project-four .project-block-two .inner-box .image-box img{
  border-radius: 0px;
}


/** cta-six **/

.cta-six{
  background: #f8f6f4;
  overflow: hidden;
}

.cta-six .text{
  padding-left: 395px;
}

.cta-six .theme-btn{
  padding-top: 18px;
  padding-bottom: 16px;
}

.cta-six .text .image{
  left: -130px;
}

.cta-six .shape .shape-1{
  top: -100px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.cta-six .shape .shape-2{
  top: 160px !important;
  left: 20% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.cta-six .shape .shape-3{
  top: 100px !important;
  left: 75% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

/** news-nine **/

.news-nine .news-block-one .inner-box .theme-btn.btn-three{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Spartan', sans-serif;
}

.news-nine .news-block-one .inner-box .post-date-two{
  background: #fff;
}

.news-nine .news-block-one .inner-box .post-date-two h4{
  color: #141417;
}


/************* home-ten **************/

/** slider-eight **/

.slider-eight .slide-item{
  padding: 245px 0px;
}

.slider-eight .slide-item:before{
  display: none;
}

.slider-eight .banner-carousel .owl-nav button{
  border-radius: 0px;
}

.slider-eight .mouse-btn-down{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  z-index: 2;
}

.slider-eight .mouse-btn-down .scroll-arrow-box{
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}

.uppercase{
  text-transform: uppercase;
}

.color_black{
  color: #141417;
}

.fs_22{
  font-size: 22px;
}


/** feature-eight **/

.feature-eight{

}

.feture-black-eight .inner-box .link a span{
  margin-right: 10px;
}

.feture-black-eight .inner-box{
  border: 1px solid #e5e5e5;
}

.feture-black-eight .inner-box:hover .icon-box{

}

.feture-black-eight .inner-box:hover{
  border-color: transparent;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.10);
}


/** about-ten **/

.image_block_ten .image-box .image{
  overflow: hidden;
}

.image_block_ten .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_ten .image-box:hover .image:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_ten .image-box .image img{
  width: 100%;
}

.image_block_ten .image-box .text{
  width: 200px;
  background: #fff;
  z-index: 1;
}

.image_block_ten .image-box .shape .shape-1{
  left: -70px !important;
  top: -45px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_ten .image-box .shape .shape-2{
  left: 40% !important;
  top: 65% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.fs_24{
  font-size: 24px;
}

/** service-ten **/

.service-ten{
  position: relative;
  padding: 0px 70px;
}

.service-ten .outer-container{
  background: #f6f9fc;
  overflow: hidden;
}

.service-block-nine .inner-box{
  overflow: hidden;
}

.service-block-nine .inner-box .image-box{
  overflow: hidden;
}

.service-block-nine .inner-box .image-box img{
  width: 100%;
}

.service-block-nine .inner-box .overlay-content{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2d2d31;
}

.service-block-nine .inner-box:hover .lower-content{
  opacity: 0;
}

.service-block-nine .inner-box .overlay-content h3 a{
  color: #fff;
}

.service-block-nine .inner-box .overlay-content p{
  color: #a9a9aa;
}

.service-block-nine .inner-box .overlay-content{
  opacity: 0;
}

.service-block-nine .inner-box:hover .overlay-content{
  top: 0px;
  opacity: 1;
}

.service-ten .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
}


/** chooseus-seven **/

.content_block_14 .content-box .single-item .icon-box{
  overflow: hidden;
  color: #fff;
  z-index: 1;
}

.content_block_14 .content-box .single-item .icon-box:before{
  position: absolute;
  content: '';
  background: #222;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.content_block_14 .content-box .single-item:hover .icon-box:before{
  transform: scale(1,1);
}

.chooseus-seven .image-box .image{
  overflow: hidden;
}

.chooseus-seven .image-box .image:before{
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.chooseus-seven .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.chooseus-seven .image-box .image img{
  width: 100%;
}

.chooseus-seven .image-box .shape .shape-1{
  top: 50% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.chooseus-seven .image-box .shape .shape-2{
  left: 70% !important;
  top: -70px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}


/** project-five **/

.project-five{
  padding: 0px 70px;
}

.project-block-four .inner-box .image{
  overflow: hidden;
}

.project-block-four .inner-box .image img{
  width: 100%;
  transition: all 500ms ease;
}

.project-block-four .inner-box:hover .image img{
  opacity: 0.3;
  transform: scale(1.05);
}

.project-block-four .inner-box .image-box .link{
  left: 50%;
  top: 35%;
  transform: translate(-50%,-50%) scale(0,0);
}

.project-block-four .inner-box:hover .image-box .link{
  transform: translate(-50%,-50%) scale(1,1);
}

.project-block-four .inner-box .image-box .link a{
  background: #fff;
}

.project-block-four .inner-box .image-box .link a:hover{
  color: #fff;
  background: #222;
}

.project-block-four .inner-box .content-box .inner{
  background: #fff;
  margin-top: -40px;
}

.project-block-four .inner-box:hover .content-box .inner{
  transform: translateY(-40px);
}


/** testimonial-eight **/

.testimonial-eight .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-eight .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-eight .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-eight .owl-nav{
  position: absolute;
  left: -465px;
  bottom: 70px;
}

.testimonial-eight .owl-nav button{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f2f2f2;
}

.testimonial-eight .nav-style-one .owl-nav button.owl-prev:before{
  display: none;
}

.testimonial-eight .nav-style-one .owl-nav button.owl-prev{
  top: 0px;
  margin-right: 10px;
}

.testimonial-eight .owl-nav button:hover{
  color: #fff;
}

.testimonial-eight .inner-content .shape .shape-1{
  top: -55px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.testimonial-eight .inner-content .shape .shape-2{
  top: 20% !important;
  left: 75% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}


/** team-five **/

.team-five .team-block-one .inner-box .lower-content h4:before{
  display: none;
}


/** skills-three **/

.skills-three{
  padding: 0px 70px;
}

.skills-three .outer-container{
  background: #f4f7fa;
  overflow: hidden;
}

.skills-three .progress-box .bar-inner{
  border-radius: 0px;
}

.skills-three .progress-box .bar{
  border-radius: 0px;
  background: #dee1e6;
}

.skills-three .pattern-layer{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-animation: zoom-fade-two 15s infinite linear;
  animation: zoom-fade-two 15s infinite linear;
}


/** news-ten **/

.news-ten .news-block-one .theme-btn{
  border-radius: 0px;
}


/** subscribe-three **/

.subscribe-three .inner-container{
  overflow: hidden;
  margin-bottom: -100px;
}

.subscribe-three h2{
  color: #fff;
}

.subscribe-three .form-inner .form-group{
  position: relative;
  padding-right: 170px;
  margin-bottom: 0px;
}

.subscribe-three .form-inner .form-group input{
  height: 60px;
  color: #808080;
  padding: 15px 30px;
  font-size: 16px;
  font-family: 'Oxygen', sans-serif;
  border-radius: 0px;
}

.subscribe-three .form-inner .form-group input:focus{

}

.subscribe-three .form-inner .form-group button{
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 15px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
  border-radius: 0px;
  padding: 17px 40px;
  width: 160px;
  background: #fff;
}

.subscribe-three .form-inner .form-group button{
  color: #141417 !important;
}

.subscribe-three .form-inner .form-group button:hover{
  color: #fff !important;
}

.subscribe-three .form-inner .form-group button:before{
  background: #141417;
}

.subscribe-three .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-position: center;
  animation: slide 100s linear infinite;
  -webkit-animation: slide 100s linear infinite;
}


/************ home-11 *************/

/** slider-nine **/

.slider-nine .banner-carousel .slide-item:before{
  display: none;
}

.slider-nine .banner-carousel .slide-item:after{
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.20) 100%);
}

.slider-nine .content-box{
  max-width: 650px;
}

.slider-nine .content-box .theme-btn.btn-two{
  border-radius: 0px;
  font-family: 'Jost', sans-serif;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 17px;
}

.slider-nine .content-box .theme-btn.btn-14{
  margin-right: 17px;
  padding-top: 17px;
  padding-bottom: 17px;
}


/** feature-nine **/

.feature-nine{
  background: #f8f6f4;
}


/** about-11 **/

.image_block_11 .image-box img{
  width: 100%;
}

.image_block_11 .image-box .image{
  overflow: hidden;
}

.image_block_11 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_11 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_11 .image-box .text{
  background: #001b47;
  width: 240px;
  z-index: 1;
}

.image_block_11 .image-box .text h5{
  color: #fff;
}

.image_block_11 .image-box .shape .shape-1{
  left: -50px !important;
  top: 62% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_11 .image-box .shape .shape-2{
  width: 135px;
  height: 135px;
  border: 17px solid #ece9e6;
  border-radius: 50%;
  left: 60% !important;
  top: -65px !important;
}

.about-11 .content_block_nine .content-box .theme-btn{
  padding: 17px 43px;
  font-weight: 600;
  font-family: 'Jost', sans-serif;
  font-size: 17px;
  background: #ff5e14;
}

.about-11 .image-layer{
  top: 240px;
}


/** service-11 **/

.service-11{
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  padding: 145px 0px 275px 0px;
}

.service-11:before{
  position: absolute;
  content: '';
  background: #001b47;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.8;
}

.service-11 .bg-layer{
  position: absolute;
  left: 0px;
  top: -30%;
  right: 0px;
  width: 100%;
  height: 150%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  background-attachment: fixed;
  background-position: center;
}

.service-11 .sec-title h5,
.service-11 .sec-title h2{
  color: #fff;
}

.service-block-eight .inner-box{
  background: #fff;
}

.service-block-eight .inner-box .icon-box{
  z-index: 1;
}

.service-block-eight .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: #222;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.service-block-eight .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.service-11 .theme-btn.btn-two{
  border-radius: 0px;
  font-family: 'Jost', sans-serif;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 17px;
}


/** funfact-six **/

.funfact-six .counter-block-one .inner-box .icon-box,
.funfact-six .counter-block-one .inner-box .count-outer{
  color: #fff;
}

.funfact-six .inner-container{
  margin-top: -125px;
  overflow: hidden;
}

.funfact-six .inner-container .shape{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: cover;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}


/** chooseus-eight **/

.image_block_12 .image-box .image{
  overflow: hidden;
}

.image_block_12 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_12 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_12 .image-box .image-2{
  border: 7px solid #fff;
}

.image_block_12 .image-box .image img{
  width: 100%;
}

.image_block_12 .image-box .shape .shape-1{
  left: 110px !important;
  top: -40px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_12 .image-box .shape .shape-2{
  left: 70% !important;
  top: 55% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}


/** team-six **/

.team-six .image-layer{
  top: -190px;
  width: 337px;
  height: 337px;
  background-repeat: no-repeat;
}

.team-six .team-block-one .inner-box .lower-content h4:before{
  display: none;
}

.project-block-three .inner-box .lower-content{
  width: 100%;
  opacity: 0;
}

.project-block-three .inner-box .lower-content h3 a,
.project-block-three .inner-box .lower-content p{
  color: #fff;
}

.project-block-three .inner-box:hover .lower-content{
  bottom: 33px;
  opacity: 1;
}


/** news-11 **/

.news-11 .news-block-one .inner-box .theme-btn{
  border-radius: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 17px;
  font-weight: 600;
  font-family: 'Jost', sans-serif;
}


/** subscribe-four **/

.subscribe-four h2{
  color: #fff;
}

.subscribe-four .form-inner .form-group{
  position: relative;
  padding-right: 170px;
  margin-bottom: 0px;
}

.subscribe-four .form-inner .form-group input{
  height: 60px;
  color: #808080;
  padding: 15px 30px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  border-radius: 0px;
}

.subscribe-four .form-inner .form-group button{
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 17px 41px;
  background: #001b47;
}

.subscribe-four .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}

.subscribe-four .form-inner .form-group button:before{
  background: #fff;
}

.subscribe-four .form-inner .form-group button:hover{
  color: #ff5e14 !important;
}


/************* home-12 *******************/

/** banner-three **/

.banner-three{
  padding-top: 275px;
  overflow: hidden;
}

.banner-three .bg-layer{
  width: 720px;
  height: 660px;
  background-repeat: no-repeat;
}

.banner-three .pattern-layer{
  top: -40px;
  right: 0px;
  /* width: 839px;
  height: 827px; */
  width: 780px;
  height: 780px;
  /* background: rgba(255, 97, 68, 0.15); */
  background-repeat: no-repeat;
  animation: heroShapeFour 6s linear infinite;
}

.banner-three .pattern-layer-2{
  top: -70px;
  right: 0px;
  /* width: 809px;
  height: 827px; */
  width: 780px;
  height: 780px;
  background-repeat: no-repeat;
  animation: heroShapeFour 6s linear infinite;
}

.banner-three .video-btn{
  color: #141417;
  margin-top: 0px;
  line-height: 50px;
}

.banner-three .video-btn i{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 20px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.20);
}

.banner-three .shape .shape-1{
  left: -100px !important;
  top: 70% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.banner-three .shape .shape-2{
  left: 55% !important;
  top: 230px !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.banner-three .shape .shape-3{
  left: 95% !important;
  top: 70% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.banner-three .shape .shape-4{
  left: 90px !important;
  top: 90% !important;
  width: 40px;
  height: 40px;
  background: #fab567;
  border-radius: 50%;
}


/** about-12 **/

.image_block_13 .image-box{
  min-height: 565px;
}

.image_block_13 .image-box .image-1{
  top: 0px;
  background: #fff;
  padding: 10px;
  right: 25px;
  border-radius: 10px;
  width:300px;
  z-index: 2;
}

.image_block_13 .image-box .image-2{
  top: 47px;
  left: -25px;
  background: #fff;
  padding: 10px;
  z-index: 1;
  width:310px;
  border-radius: 10px;
}

.image_block_13 .image-box .image-3{
  left: 150px;
  bottom: 50px;
  background: #fff;
  padding: 10px;
  width:200px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
}ç

.image_block_13 .image-box .image img{
  width: 100%;
}

.image_block_13 .image-box .shape .shape-1{
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_13 .image-box .shape .shape-2{
  left: 45% !important;
  top: 10% !important;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_13 .image-box .shape .shape-3{
  top: 60% !important;
  width: 100px;
  height: 100px;
  background: #fab567;
  border-radius: 50%;
}

.image_block_13 .image-box .shape .shape-4{
  left: 60% !important;
  width: 55px;
  height: 55px;
  background: #632dde;
  border-radius: 50%;
}

.image_block_13 .image-box .shape .shape-5{
  top: 35% !important;
  left: 55% !important;
  border: 17px solid #fc911e;
  width: 190px;
  height: 190px;
  border-radius: 50%;
}


/** service-12 **/

.service-block-10 .inner-box{
  background: #f1f9fc;
}

.service-block-10 .inner-box .icon-box{
  width: 94px;
  height: 104px;
  line-height: 104px;
  color: #10a1d4;
  z-index: 1;
}

.service-block-10 .inner-box .icon-box .shape-1{
  width: 94px;
  height: 104px;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.15;
  background: #10a1d4;
  transition: all 500ms ease;
}

.service-block-10 .inner-box:hover .icon-box .shape-1{
  opacity: 1;
}

.service-block:nth-child(2) .service-block-10 .inner-box .icon-box .shape-1{
  background: #fb4754;
}

.service-block:nth-child(3) .service-block-10 .inner-box .icon-box .shape-1{
  background: #632ddf;
}

.service-block:nth-child(4) .service-block-10 .inner-box .icon-box .shape-1{
  background: #ff931e;
}

.service-block:nth-child(5) .service-block-10 .inner-box .icon-box .shape-1{
  background: #49ca8f;
}

.service-block:nth-child(6) .service-block-10 .inner-box .icon-box .shape-1{
  background: #ff6840;
}

.service-block-10 .inner-box .icon-box .shape-2{
  width: 100px;
  height: 94px;
  background-repeat: no-repeat;
  opacity: 0.3;
  transition: all 500ms ease;
}

.service-block-10 .inner-box:hover .icon-box .shape-2{
  opacity: 1;
}

.service-block-10 .inner-box .link a i{
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 16px;
  opacity: 0;
  transition: all 500ms ease;
}

.service-block-10 .inner-box .link a:hover{
  padding-right: 30px;
}

.service-block-10 .inner-box .link a:hover i{
  opacity: 1;
}

.service-block-10 .inner-box .icon-box{
  transition: all 500ms ease;
}

.service-block-10 .inner-box:hover .icon-box{
  color: #fff !important;
}

.service-block .service-block-10 .inner-box{
  height:330px;
}

.service-block:nth-child(2) .service-block-10 .inner-box{
  background: #fff4f5;
}

.service-block-10 .inner-box:hover{
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.10);
}

.service-block:nth-child(2) .service-block-10 .inner-box .icon-box{
  color: #fb4754;
}

.service-block:nth-child(3) .service-block-10 .inner-box .icon-box{
  color: #632ddf;
}

.service-block:nth-child(3) .service-block-10 .inner-box{
  background: #f6f3fd;
}

.service-block:nth-child(4) .service-block-10 .inner-box .icon-box{
  color: #ff931e;
}

.service-block:nth-child(4) .service-block-10 .inner-box{
  background: #fff9f2;
}

.service-block:nth-child(5) .service-block-10 .inner-box .icon-box{
  color: #49ca8f;
}

.service-block:nth-child(5) .service-block-10 .inner-box{
  background: #f4fcf8;
}

.service-block:last-child .service-block-10 .inner-box .icon-box{
  color: #ff6840;
}

.service-block:last-child .service-block-10 .inner-box{
  background: #fff6f4;
}


/** chooseus-nine **/

.chooseus-nine .accordion-box .block .acc-btn.active{
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, .10);
}

.chooseus-nine .accordion-box .block .acc-btn.active .icon-outer{
  color: #141417;
}

.image_block_14 .image-box img{
  width: 100%;
}

.image_block_14 .image-box .shape .shape-1{
  top: -50px;
  left: -30px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_14 .image-box .shape .shape-2{
  top: 175px;
  right: 50px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.image_block_14 .image-box .shape .shape-3{
  position: absolute;
  left: -15px;
  top: 110px;
  width: 40px;
  height: 40px;
  background: #48c88e;
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 4s infinite linear;
  animation: zoom-fade-two 4s infinite linear;
}

.image_block_14 .image-box .shape .shape-4{
  top: -45px;
  right: 140px;
  width: 135px;
  height: 135px;
  border: 17px solid #fb4754;
  border-radius: 50%;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.image_block_14 .image-box .shape .shape-5{
  left: 175px;
  bottom: 35px;
  width: 189px;
  height: 189px;
  border: 17px solid #ff931e;
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 6s infinite linear;
  animation: zoom-fade-two 6s infinite linear;
}


/** project-six **/

.project-block-five .inner-box .image{
  overflow: hidden;
  background: #000;
}

.project-block-five .inner-box .image img{
  width: 100%;
  border-radius: 5px;
  transition: all 500ms ease;
}

.project-block-five .inner-box:hover .image img{
  opacity: 0.4;
  transform: scale(1.05);
}

.project-block-five .inner-box .image-box .link{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0,0);
}

.project-block-five .inner-box:hover .image-box .link{
  transform: translate(-50%,-50%) scale(1,1);
}

.project-block-five .inner-box .image-box .link a{
  color: #fff;
  border-radius: 50%;
}

.project-block-five .inner-box .image-box .link a:hover{
  background: #fff;
}


/** testimonial-nine **/


.testimonial-nine .owl-nav{
  position: absolute;
  left: -465px;
  bottom: 36px;
}

.testimonial-nine .owl-nav button{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #ffecee;
  border-radius: 50%;
  color: #141417;
}

.testimonial-nine .nav-style-one .owl-nav button.owl-prev:before{
  display: none;
}

.testimonial-nine .nav-style-one .owl-nav button.owl-prev{
  top: 0px;
  margin-right: 10px;
}

.testimonial-nine .owl-nav button:hover{
  color: #fff;
}

.testimonial-nine .pattern-layer{
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: repeat-x;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}


/** funfact-seven **/

.funfact-seven{
  background: #fef6eb;
  overflow: hidden;
}

.funfact-seven .counter-block-one .inner-box p{
  color: #141417;
}

.funfact-seven .funfact-block:first-child .counter-block-one .inner-box .count-outer{
  color: #10a1d4;
}

.funfact-seven .funfact-block:nth-child(2) .counter-block-one .inner-box .count-outer{
  color: #fb4754;
}

.funfact-seven .funfact-block:nth-child(3) .counter-block-one .inner-box .count-outer{
  color: #48c88d;
}

.funfact-seven .funfact-block:last-child .counter-block-one .inner-box .count-outer{
  color: #632ddf;
}


/** news-12 **/

.news-12 .news-block-one .inner-box .lower-content .theme-btn{
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding-top: 16px;
  padding-bottom: 16px;
}


/** subscribe-five **/

.subscribe-five .subscribe-form .form-group input{
  border-color: #e5e5e5;
  height: 60px;
}

.subscribe-five .subscribe-form .form-group{
  margin-bottom: 0px;
}

.subscribe-five .subscribe-form{
  padding-right: 235px;
}

.subscribe-five .subscribe-form .message-btn{
  position: absolute;
  top: 0px;
  right: 0px;
}

.subscribe-five .pattern-layer{
  width: 100%;
  height: 794px;
  background-repeat: repeat-x;
  background-position: bottom center;
  z-index: -1;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}


/************** home-13 ****************/


/** slider-ten **/

.slider-ten .banner-carousel .slide-item:before,
.slider-ten .banner-carousel .slide-item:after{
  display: none;
}

.slider-ten .pattern-layer{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background-size: 100% 100%;
  background-position: top center;
  z-index: 2;
}

.slider-ten .image-box{
  margin-right: -75px;
}

.slider-ten .image-box img{
  width: 100%;
}

.slider-ten .image-box{
  opacity: 0;
  -webkit-transform: translateY(200px);
  -moz-transform: translateY(200px);
  -ms-transform: translateY(200px);
  -o-transform: translateY(200px);
  transform: translateY(200px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.slider-ten .active .image-box{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.slider-ten .slide-item{
  padding: 60px 0px 290px 0px;
}

.slider-ten .slide-item .shape .shape-1{
  top: -60px;
  left: 200px;
  width: 350px;
  height: 350px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: zoom-fade-two 10s infinite linear;
  animation: zoom-fade-two 10s infinite linear;
}

.slider-ten .slide-item .shape .shape-2{
  top: -135px;
  right: 0px;
  width: 520px;
  height: 520px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: zoom-fade 10s infinite linear;
  animation: zoom-fade 10s infinite linear;
}

.slider-ten .slide-item .shape .shape-3{
  top: -400px;
  right: 240px;
  width: 520px;
  height: 520px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: zoom-fade-two 8s infinite linear;
  animation: zoom-fade-two 8s infinite linear;
}


/** domain-field **/

.domain-field h2{
  font-size: 36px;
}

.domain-field .inner-container{
  margin-top: -210px;
  background: #fff;
}

.domain-field .search-form{
  position: relative;
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.domain-field .search-form .form-group{
  position: relative;
  margin: 0px;
}

.domain-field .search-form .form-group input[type='search']{
  position: relative;
  display: block;
  width: 100%;
  height: 82px;
  border: 2px solid #f2f2f2;
  border-radius: 50px;
  background: #f7f7f7;
  padding: 15px 220px 15px 50px;
  font-size: 20px;
  line-height: 30px;
  color: #b3b3b3;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  transition: all 500ms ease;
}

.domain-field .search-form .form-group input:focus{

}

.domain-field .search-form .form-group button{
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 15px;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 12px 31px;
  border-radius: 30px;
  color: #fff;
  transition: all 500ms ease;
}

.domain-field .search-form .form-group button:hover{
  background: #141417;
}

.domain-field .domain-list h4,
.domain-field .domain-list h4 a{
  color: #1d439b;
}

.domain-field .domain-list li{
  position: relative;
}

.domain-field .domain-list li:before{
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 30px;
  top: 1px;
  right: -10px;
}


/** feature-ten **/

.feature-block-ten .inner-box .image-box img{
  transition: all 500ms ease;
}

.feature-block-ten .inner-box:hover .image-box img{
  transform: rotateY(180deg);
}

.feature-block-ten .inner-box:before{
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 130px;
  top: 46px;
  right: -15px;
}

.feature-block:last-child .feature-block-ten .inner-box:before{
  display: none;
}


/** about-13 **/

.about-13 .image-box img{
  width: 100%;
}

.about-13 .image-box .shape .shape-1{
  left: -20px;
  top: 8px;
  width: 100px;
  height: 100px;
  border: 12px solid #f0c134;
  border-radius: 50%;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.about-13 .image-box .shape .shape-2{
  right: 230px;
  bottom: -37px;
  width: 40px;
  height: 40px;
  background: #f18b92;
  border-radius: 50%;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.about-13 .image-box .shape .shape-3{
  left: 55px;
  top: -60px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  z-index: -1;
}


/** pricing-six **/

.pricing-six .tab-btn-box .tab-btns li{
  font-family: 'Poppins', sans-serif;
}

.pricing-six .pricing-block-two .pricing-table .theme-btn.btn-two{
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding-top: 16px;
  padding-bottom: 16px;
}

.pricing-six .pricing-block-two .pricing-table .theme-btn.btn-two span:after{
  color: #00d0f5;
}

.pricing-six .pricing-block-two .pricing-table .theme-btn.btn-two:hover:after {
  border-right: 2px solid #00d0f5 !important;
  border-top: 2px solid #00d0f5 !important;
}

.white_color{
  color: #fff;
}


/** chooseus-ten **/

.chooseus-ten{
  padding: 226px 0px 166px 0px;
}

.content_block_15 .inner-box .single-item .icon-box{
  background: #fff;
}

.content_block_15 .content-box .sec-title h6,
.content_block_15 .content-box .sec-title h2{
  color: #fff;
}

.content_block_15 .inner-box .single-item p{
  color: #bbd2ec;
}

.chooseus-ten .image-box img{
  width: 100%;
}

.chooseus-ten .image-box{
  margin-right: -94px;
}

.chooseus-ten .shape .shape-1{
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.chooseus-ten .shape .shape-2{
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.chooseus-ten .shape .shape-3{
  width: 100%;
  height: 135px;
  background-repeat: no-repeat;
  animation: slide 70s linear infinite;
  -webkit-animation: slide 70s linear infinite;
}

.chooseus-ten .shape .shape-4{
  top: 250px;
  width: 100%;
  height: 102px;
  background-repeat: no-repeat;
  animation: slide 50s linear infinite;
  -webkit-animation: slide 50s linear infinite;
}


/** service-13 **/

.service-13{
  background: #f5f8fc;
}

.service-block-11 .inner-box .icon-box{
  background: #eff4fb;
}

.service-block-11 .inner-box:hover .icon-box{
  color: #fff;
}

.service-block-11 .inner-box .icon-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transform: scale(0,0);
  border-radius: 50%;
  transition: all 500ms ease;
}

.service-block-11 .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}


/** faq-one **/

.image_block_15 .image-box img{
  width: 100%;
}

.image_block_15 .image-box .shape .shape-1{
  width: 100px;
  height: 100px;
  left: -17px;
  top: 160px;
  border-radius: 50%;
  border: 13px solid #f0c134;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.image_block_15 .image-box .shape .shape-2{
  width: 40px;
  height: 40px;
  right: 5px;
  bottom: 183px;
  border-radius: 50%;
  background: #f18b92;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.image_block_15 .image-box .shape .shape-3{
  right: 20px;
  top: 10px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.image_block_15 .image-box .shape .shape-4{
  left: -65px;
  top: 230px;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.faq-one .accordion-box .block .acc-btn{
  box-shadow: none !important;
  border-radius: 30px;
  border: 1px solid transparent;
}

.faq-one .accordion-box .block .acc-btn.active{
  background: #f5f8fc;
  border-color: #e5e5e5;
}

.faq-one .accordion-box .block .acc-btn.active .icon-outer{
  color: #141417;
}


/** testimonial-ten **/

.testimonial-ten .owl-dots{
  text-align: center;
  margin-top: 48px;
}


/** cta-seven **/

.cta-seven .text{
  padding-left: 400px;
}

.cta-seven .shape .shape-1{
  width: 100%;
  height: 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.cta-seven .shape .shape-2{
  width: 100%;
  height: 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.cta-seven .text .image{
  top: -70px;
}

.cta-seven .btn-box{
  margin-top: 20px;
}

.cta-seven .shape .shape-3{
  width: 100%;
  height: 135px;
  background-repeat: no-repeat;
  animation: slide 70s linear infinite;
  -webkit-animation: slide 70s linear infinite;
}

.cta-seven .shape .shape-4{
  left: 30%;
  top: 250px;
  width: 100%;
  height: 102px;
  background-repeat: no-repeat;
  animation: slide 50s linear infinite;
  -webkit-animation: slide 50s linear infinite;
}

.cta-seven .shape .shape-5{
  left: 50%;
  width: 100%;
  height: 135px;
  background-repeat: no-repeat;
  animation: slide 70s linear infinite;
  -webkit-animation: slide 70s linear infinite;
}


/** news-13 **/

.news-13 .news-block-one .inner-box .image{
  background: #000;
}

.news-13 .news-block-one .inner-box:hover{
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
}



/************** home-14 ****************/

.banner-four{
  padding: 100px 0px 110px 0px;
}

.banner-four .content-box h2{
  max-width: 430px;
}

.banner-four .content-box h2 span{
  padding-bottom: 14px;
}

.banner-four .content-box h2 span:before{
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-152.png);
  width: 217px;
  height: 30px;
  left: 3px;
  bottom: 0px;
  background-repeat: no-repeat;
}

.banner-four .content-box .btn-box a{
  overflow: hidden;
  vertical-align: middle;
  border-radius: 30px;
  padding: 14.5px 30px;
  z-index: 1;
}

.banner-four .content-box .btn-box a i{
  position: relative;
  top: 5px;
  margin-right: 10px;
  font-size: 24px;
}

.banner-four .content-box .btn-box a.play-store{
  margin-right: 16px;
}

.banner-four .content-box .btn-box a.app-store{
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.banner-four .content-box .btn-box a:hover{
  color: #fff;
}

.banner-four .content-box .btn-box a:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.banner-four .content-box .btn-box a:hover:before{
  width: 100%;
}

.banner-four .image-box img{
  width: 100%;
}

.banner-four .shape .shape-1{
  width: 688px;
  height: 757px;
  background-repeat: no-repeat;
}

.banner-four .shape .shape-2{
  width: 624px;
  height: 621px;
  background-repeat: no-repeat;
}

.banner-four .shape .shape-3{
  left: -100px;
  bottom: 0px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.banner-four .shape .shape-4{
  left: 55%;
  top: 110px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 9s infinite linear;
  animation: zoom-fade 9s infinite linear;
}

.banner-four .shape .shape-5{
  bottom: 170px;
  right: 230px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  z-index: -1;
}

.banner-four .shape .shape-6{
  left: 80px;
  bottom: 0px;
  background: #fab567;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.banner-four .shape .shape-7{
  left: 225px;
  top: 130px;
  background: #f9f5fc;
  width: 300px;
  height: 300px;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.banner-four .shape .shape-8{
  left: 53%;
  top: 265px;
  background: #f18b92;
  -webkit-animation: zoom-fade-two 6s infinite linear;
  animation: zoom-fade-two 6s infinite linear;
}

.banner-four .shape .shape-9{
  right: 200px;
  bottom: 200px;
  border: 13px solid #67e7fa;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.banner-four .shape .shape-10{
  top: -300px;
  right: 325px;
  width: 590px;
  height: 590px;
  background: rgba(255, 255, 255, 0.03);
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
  display: none;
}

.banner-four .shape .shape-11{
  top: 400px;
  right: -150px;
  width: 590px;
  height: 590px;
  background: rgba(255, 255, 255, 0.03);
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.banner-four .shape .shape-12{
  top: 235px;
  right: 335px;
  background: rgba(255, 255, 255, 0.03);
  -webkit-animation: zoom-fade-two 6s infinite linear;
  animation: zoom-fade-two 6s infinite linear;
}


/** feature-11 **/

.feature-block-11 .inner-box h3 a:hover{

}

.feature-block-11 .inner-box:hover{
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
}

.feature-block-11 .inner-box:hover .icon-box{
  transform: rotateY(180deg);
}


/** about-14 **/

.image_block_16 .image-box img{
  width: 100%;
}

.image_block_16 .image-box .shape .shape-1{
  left: -30px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.image_block_16 .image-box .image:before{
  position: absolute;
  content: '';
  background: #f4f0fa;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: -1;
}

.image_block_16 .image-box .shape .shape-2{
  right: 0px;
  bottom: 160px;
  border: 13px solid #fab567;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}

.about-14 .content_block_nine .content-box .video-btn a{
  border-radius: 50px;
}


/** process-four **/

.content_block_16 .content-box .single-item .icon-box:before{
  position: absolute;
  content: '';
  background: #f4ecf8;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  z-index: -1;
}

.content_block_16 .content-box .single-item .icon-box .shape{
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 74px;
  background: -webkit-linear-gradient(90deg, #e9e3f6, #f2e3f3 100%);
}

.image_block_17 .image-box img{
  width: 100%;
}

.image_block_17 .image-box .shape .shape-1{
  right: -50px;
  top: 188px;
  width: 531px;
  height: 387px;
  background-repeat: no-repeat;
}

.image_block_17 .image-box .shape .shape-2{
  top: 150px;
  right: 0px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.image_block_17 .image-box .shape .shape-3{
  left:30px;
  top: 120px;
  border: 13px solid #eb888f;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
}


/** service-14 **/

.service-14{
  background: #f9f5fc;
}

.service-14 .service-block-11 .inner-box .icon-box{
  background: #f1ecf9;
}

.service-14 .service-block-11 .inner-box .icon-box:before{
  display: none;
}

.service-14 .service-block-11 .inner-box{
  transition: all 500ms ease;
}

.service-14 .service-block-11 .inner-box:hover{
  box-shadow: 0 0 50px rgb(0 0 0 / 20%);
}

.service-14 .shape{
  left: 40%;
  top: 30%;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.service-14 .service-block-11 .inner-box{
  background: #fff;
}


/** screenshot-section **/

.screenshot-section .screen-mobile{
  width: 438px;
  height: 778px;
  left: -66px;
  top: 2px;
  background-repeat: no-repeat;
}

.screenshot-section .inner-box{
  margin-right: -545px;
}

.screenshot-section .screenshot{
  position: relative;
  display: block;
}

.screenshot-section .screenshot{
  overflow: hidden;
  border-radius: 20px;
  padding:10px;
  margin-left:15px;
}

.screenshot-section .screenshot img{
  width: 100%;
  border-radius: 20px;
}

.screenshot-section .owl-nav{
  position: absolute;
  left: -400px;
  bottom:-20px;
}

.screenshot-section .owl-nav button{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  background: #f4ecf8;
  font-size: 16px;
  color: #141417;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
  transition: all 500ms ease;
}

.screenshot-section .owl-nav button.owl-prev{
  /* transform: rotate(180deg); */
}

.screenshot-section .owl-nav button.owl-next{
  margin-left: 10px;
}

.screenshot-section .owl-nav button:hover{
  color: #fff;
}

.screenshot-section .owl-nav button:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.screenshot-section .owl-nav button:hover:before{
  transform: scale(1,1);
}


/** testimonial-11 **/

.testimonial-block-three .inner-box .thumb-box img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-three .inner-box{
  background: #fff;
}

.testimonial-11 .owl-dots{
  margin-top: 48px;
}

.testimonial-11:before{
  position: absolute;
  content: '';
  background: #f8f5fc;
  width: 100%;
  height: 240px;
  left: 0px;
  bottom: 0px;
}


/** download-section **/

.download-section{
  background: #f8f5fc;
}

.image_block_18 .image-box img{
  width: 60%;
}

.image_block_18 .image-box .image:before{
  position: absolute;
  content: '';
  width: 300px;
  height: 300px;
  border-radius: 50%;
  bottom: 1px;
  z-index: -1;
}

.image_block_18 .image-box .shape .shape-1{
  top: -20px;
  right: 40px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.image_block_18 .image-box .shape .shape-2{
  left: -60px;
  bottom: 110px;
  border: 13px solid #fab567;
  z-index: 2;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.content_block_17 .content-box .btn-box a{
  overflow: hidden;
  vertical-align: middle;
  border-radius: 30px;
  padding: 14.5px 30px;
  z-index: 1;
}

.content_block_17 .content-box .btn-box a i{
  position: relative;
  top: 5px;
  margin-right: 10px;
  font-size: 24px;
}

.content_block_17 .content-box .btn-box a.play-store{
  margin-right: 16px;
}

.content_block_17 .content-box .btn-box a.app-store{
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.content_block_17 .content-box .btn-box a:hover{
  color: #fff;
}

.content_block_17 .content-box .btn-box a:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.content_block_17 .content-box .btn-box a:hover:before{
  width: 100%;
}


/** news-14 **/

.subscribe-one.home-14 .inner-container input{
  height: 60px;
  border-radius: 30px;
  padding: 10px 30px;
}

.subscribe-one.home-14 .form-inner .form-group button{
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border-radius: 30px;
  width: 174px;
  padding: 17px 30px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.subscribe-one.home-14 .form-inner .form-group{
  padding-right: 184px;
}

.subscribe-one.home-14 .inner-container{
  margin-bottom: -110px;
}


/*************** home-15 ****************/

/** slider-11 **/

.slider-11 .slide-item{
  padding: 165px 0px 230px 0px;
}

.slider-11 .slide-item:before{
  display: none;
}

.slider-11 .content-box h2{
  font-weight: 900;
}

.slider-11 .pattern-layer{
  width: 100%;
  height: 292px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.slider-11 .banner-carousel .slide-item:after{
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.9) 30%, rgba(0,0,0,0.20) 100%);
}


/** feature-12 **/

.feature-12 .upper-box:before{
  position: absolute;
  content: '';
  background: #b9ebd5;
  height: 115px;
  width: 4px;
  border-radius: 3px;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.feature-block-12 .inner-box:hover .icon-box{
  transform: rotateY(180deg);
}


/** about-15 **/


.image_block_19 .image-box{
  padding-right: 205px;
  padding-bottom: 277px;
}

.image_block_19 .image-box img{
  width: 100%;
}

.image_block_19 .image-box .video-btn{
  bottom: 70px;
}

.image_block_19 .image-box .video-btn a{
  background: #fff;
  color: #141417;
  line-height: 24px;
  border-radius: 50px;
  min-height: 88px;
}

.image_block_19 .image-box .video-btn a i{
  position: absolute;
  left: 35px;
  top: 25px;
  font-size: 40px;
  text-align: center;
  transition: all 500ms ease;
}

.image_block_19 .image-box .video-btn a:hover i{
  color: #fff;
}

.image_block_19 .image-box .video-btn a:hover{
  color: #fff;
}

.image_block_19 .image-box .shape .shape-1{
  left: -50px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.image_block_19 .image-box .shape .shape-2{
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.content_block_18 .content-box .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #141417;
  background: #f1fbf7;
  padding: 11px 30px 11px 30px;
  cursor: pointer;
  transition: all 500ms ease;
}

.content_block_18 .content-box .tab-btns li:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 3px;
  left: 0px;
  bottom: 0px;
  transition: all 500ms ease;
}

.content_block_18 .content-box .tab-btns li.active-btn:before,
.content_block_18 .content-box .tab-btns li:hover:before{
  width: 100%;
}

.content_block_18 .content-box .list-style-two li{
  float: left;
  width: 50%;
}

.about-15 .pattern-layer{
  width: 100%;
  height: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}


/** service-15 **/

.service-15{
  background: #f5f9fa;
}

.service-block-12 .inner-box .icon-box{
  margin-top: -40px;
  color: #fff;
}

.service-block-12 .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.service-block-12 .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.service-block-12 .inner-box{
  overflow: hidden;
  background: #fff;
}

.service-block-12 .inner-box .lower-content .link a i{
  position: relative;
  margin-left: 8px;
  top: 2px;
}

.service-block-12 .inner-box .image-box{
  overflow: hidden;
}

.service-block-12 .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.service-block-12 .inner-box:hover .image-box img{
  opacity: 0.2;
  transform: scale(1.05);
}

.service-15 .more-btn .theme-btn{
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  background: #fff;
  color: #141417 !important;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  border-radius: 30px;
  padding-top: 17px;
  padding-bottom: 17px;
}

.service-15 .more-btn .theme-btn:before{
  background: #17be74;
}

.service-15 .more-btn .theme-btn:after{
  border-right: 2px solid #141417;
  border-top: 2px solid #141417;
}

.service-15 .more-btn .theme-btn:hover:after{
  border-right: 2px solid #17be74;
  border-top: 2px solid #17be74;
}

.service-15 .more-btn .theme-btn span:before{
  color: #141417;
}

.service-15 .more-btn .theme-btn span:after{
  color: #17be74;
}

.service-15 .shape .shape-1{
  bottom: 335px;
  border: 13px solid #f18b92;
  -webkit-animation: zoom-fade-two 5s infinite linear;
  animation: zoom-fade-two 5s infinite linear;
  z-index: 1;
}

.service-15 .shape .shape-2{
  width: 300px;
  height: 300px;
  border: 2px solid #e8eced;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.service-15 .shape .shape-3{
  width: 400px;
  height: 400px;
  border: 2px solid #e8eced;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.service-15 .shape .shape-4{
  right: 420px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}


/** chooseus-11 **/

.chooseus-11 .sec-title h2{
  color: #fff;
}

.chooseus-11 p{
  color: #a9a9aa;
}

.chooseus-11 .inner-box .single-item .icon-box{
  background: rgba(255, 255, 255, 0.10);
}

.chooseus-11 .inner-box .single-item h3{
  color: #fff;
}

.chooseus-11{
  overflow: hidden;
}

.chooseus-11 .pattern-layer .pattern-4{
  top: -65px;
  right: -25px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}


/** team-seven **/

.team-seven .team-block-one .inner-box .lower-content h4:before{
  display: none;
}

.testimonial-eight.home-15 .owl-nav button{
  border-radius: 50%;
  background: #e7f8f1;
}

.testimonial-eight.home-15 .inner-content .shape .shape-2{
  top: -20% !important;
  left: 80% !important;
}

.testimonial-eight.home-15 .inner-content .shape .shape-3{
  top: -18% !important;
  left: 90% !important;
  width: 300px;
  height: 300px;
  border: 2px solid #e8eced;
}

/** pricing-seven **/

.pricing-seven .tab-btn-box .tab-btns li:before{

}

.pricing-block-two .pricing-table .feature-list-two li:before{
  position: absolute;
  content: '';
  width: 11px;
  height: 11px;
  left: 0px;
  top: 8px;
  border-radius: 50%;
}

.pricing-seven .pricing-block-two .pricing-table .theme-btn{
  border-radius: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.pricing-seven .pricing-block-two .pricing-table .table-header{
  padding-bottom: 30px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e5e5e5;
}

.pricing-seven .pricing-block-two .pricing-table .table-header h2 span{
  color: #808080;
}

.pricing-seven .pricing-block-two.active-block .pricing-table{
  background: #2d2d31;
}

.pricing-seven .pricing-block-two.active-block .pricing-table .table-header h2 span{
  color: #fff;
}

.pricing-seven .pricing-block-two.active-block .pricing-table .table-header{
  border-bottom: 1px solid #424246;
}


/** cta-eight **/

.cta-eight{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.cta-eight .inner-box h2{
  color: #fff;
}


/** news-15 **/

.news-15 .news-block-one .inner-box .image{
  background: #141417;
}

.news-15 .shape .shape-1{
  height: 240px;
  width: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.news-15 .shape .shape-2{
  right: 230px;
  height: 240px;
  width: 230px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.news-15 .shape .shape-3{
  right: -50px;
  bottom: -120px;
  width: 400px;
  height: 400px;
  border: 2px solid #e8eced;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}


.subscribe-one.home-15 .inner-container input{
  height: 60px;
  border-radius: 30px;
  padding: 10px 30px;
}

.subscribe-one.home-15 .form-inner .form-group{
  padding-right: 234px;
}

.subscribe-one.home-15 .inner-container{
  overflow: hidden;
}

.subscribe-one.home-15 .inner-container .shape{
  position: absolute;
  left: 40%;
  top: -7%;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}



/****************** home-16 ******************/

.slider-12 .banner-carousel .slide-item{
  padding: 180px 0px 225px 0px;
}

.slider-12 .banner-carousel .slide-item:before{
  display: none;
}

.slider-12 .banner-carousel .slide-item:after{
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.10) 100%);
  opacity: 1;
}

.slider-12 .content-box:before{
  position: absolute;
  content: '';
  width: 7px;
  height: calc(100% - 21px);
  left: 0px;
  top: 13px;
  clip-path: polygon(0% 3%, 100% 0%, 100% 97%, 0% 100%, 0% 0%);
}


/** request-form **/

.request-form{
  background: #343d51;
}

.request-form .small-title{
  border-radius: 10px 10px 0px 0px;
  top: -50px;
}

.request-form .small-title h4{
  color: #fff;
}

.request-form .form-group{
  margin-bottom: 0px;
  position: relative;
  padding: 0px 10px;
}

.request-form .form-group i{
  position: absolute;
  top: 16px;
  right: 34px;
  z-index: 2;
  font-size: 18px;
  color: #808080;
}

.request-form .default-form{
  position: relative;
  padding-right: 186px;
  margin: 0px 5px;
}

.request-form .default-form .message-btn{
  position: absolute;
  top: 0px;
  right: 0px;
}

.request-form .default-form .message-btn button{
  padding: 12px 20px;
  width: 166px;
}


/** about-16 **/


.image_block_20 .image-box img{
  width: 100%;
}

.image_block_20 .image-box .text{
  width: 320px;
  left: -50px;
}

.image_block_20 .image-box .text .icon-box{
  color: #fff;
}

.image_block_20 .image-box .text h4{
  color: #f5cfc4;
}

.image_block_20 .image-box .text h3{
  font-size: 26px;
  line-height: 34px;
  color: #fff;
}

.image_block_20 .image-box .text h3 a{
  display: inline-block;
  color: #fff;
}

.image_block_20 .image-box .text h3 a:hover{
  text-decoration: underline;
}

.image_block_20 .image-box .shape .shape-1{
  bottom: -75px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.image_block_20 .image-box .shape .shape-2{
  left: 60px;
  top: 130px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.content_block_19 .content-box .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #141417;
  margin-right: 35px;
  cursor: pointer;
  transition: all 500ms ease;
}

.content_block_19 .content-box .tab-btns li:last-child{
  margin: 0px !important;
}

.content_block_19 .content-box .tab-btns li.active-btn,
.content_block_19 .content-box .tab-btns li:hover{

}

.content_block_19 .content-box .tab-btns li:before{
  position: absolute;
  content: '';
  width: 2px;
  height: 13px;
  background: #141417;
  top: 7px;
  right: -18px;
}

.content_block_19 .content-box .tab-btns li:last-child:before{
  display: none;
}


/** service-16 **/

.service-16{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
}

.service-16:before{
  position: absolute;
  content: '';
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.95;
}

.service-16 .bg-layer{
  position: absolute;
  left: 0px;
  top: -30%;
  right: 0px;
  width: 100%;
  height: 150%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

.service-block-13 .inner-box{
  max-width: 370px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
}

.service-block-13 .inner-box .image-box{
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 88%, 0% 0%);
}

.service-block-13 .inner-box .image-box:before{
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.30);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0;
  z-index: 1;
  transition: all 500ms ease;
}

.service-block-13 .inner-box:hover .image-box:before{
  opacity: 1;
}

.service-block-13 .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.service-block-13 .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.service-block-13 .inner-box .lower-content .icon-box{
  color: #fff;
  top: -70px;
}

.service-block-13 .inner-box .lower-content .icon-box:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  border-radius: 50%;
  z-index: -1;
  transition: all 500ms ease;
}

.service-block-13 .inner-box:hover .lower-content .icon-box:before{
  transform: scale(1,1);
}

.service-block-13 .inner-box .lower-content .link a i{
  position: relative;
  top: 2px;
  opacity: 0;
  transition: all 500ms ease;
}

.service-block-13 .inner-box .lower-content .link a:hover i{
  opacity: 1;
  margin-left: 10px;
}

.service-block-13 .inner-box .lower-content .link a:hover{

}

.service-16 .tab-btn-box .tab-btns{
  position: relative;
  width: 470px;
  margin: 0 auto;
  border-radius: 10px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
}

.service-16 .tab-btn-box .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  width: 50%;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #141417;
  cursor: pointer;
  padding: 11px 15px;
  text-align: center;
  transition: all 500ms ease;
}

.service-16 .tab-btn-box .tab-btns li.active-btn,
.service-16 .tab-btn-box .tab-btns li:hover{
  color: #fff;
}


/** service-17 **/

.service-17{
  padding: 145px 0px 250px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
}

.service-17:before{
  position: absolute;
  content: '';
  background: #262c3b;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.88;
}

.service-17 .bg-layer{
  position: absolute;
  left: 0px;
  top: -30%;
  right: 0px;
  width: 100%;
  height: 150%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

.service-17 .sec-title-11 h2{
  color: #fff;
}

.service-17 .image-box img{
  width: 100%;
  border-radius: 10px;
}

.service-17 .image-box .image{
  overflow: hidden;
}

.service-17 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.service-17 .image-box:hover .image:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.service-17 .image-box .image-3{
  background: #fff;
  padding: 10px;
}

.content_block_20 .content-box h3{
  color: #fff;
}

.content_block_20 .content-box p{
  color: #fff;
}

.content_block_20 .content-box .list li{
  color: #fff;
}

.content_block_20 .content-box .list li.pull-left{
  width: 50%;
}

.content_block_20 .content-box .list li:before{
  position: absolute;
  content: '\e97d';
  font-family: 'icomoon';
  left: 0px;
  top: 2px;
  font-size: 20px;
}

.service-17 .image-box .shape .shape-1{
  top: 78px;
  left: 90px;
  width: 170px;
  height: 178px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.service-17 .image-box .shape .shape-2{
  bottom: -40px;
  right: -80px;
  width: 170px;
  height: 178px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.service-17 .tab-btns{
  position: relative;
  display: block;
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
}

.service-17 .tab-btns li{
  position: relative;
  float: left;
  width: 33%;
  padding: 27px 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  transition: all 500ms ease;
}

.service-17 .tab-btns li:last-child{
  border-right: none;
  width: 34%;
  border-radius: 0px 10px 10px 0px;
}

.service-17 .tab-btns li:first-child{
  border-radius: 10px 0px 0px 10px;
}

.service-17 .tab-btns li i{
  position: relative;
  margin-right: 10px;
  top: 5px;
  font-size: 30px;
}

.service-17 .tab-btns li.active-btn,
.service-17 .tab-btns li:hover{

}

.service-17 .tab-btns li:before{
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  left: 50%;
  bottom: 0px;
  opacity: 0;
  transition: all 500ms ease;
  transform: translateX(-50%) rotate(45deg);
}

.service-17 .tab-btns li.active-btn:before,
.service-17 .tab-btns li:hover:before{
  opacity: 1;
  bottom: -6px;
}

.service-17 .image-shape .shape-1{
  width: 260px;
  height: 262px;
  background-repeat: no-repeat;
}

.service-17 .image-shape .shape-2{
  width: 344px;
  height: 459px;
  right: -150px;
  background-repeat: no-repeat;
}


/** funfact-eight **/

.funfact-eight .inner-container{
  margin-top: -103px;
}

.funfact-eight .count-outer{
  color: #fff;
}

.funfact-eight .counter-block-one .inner-box .icon-box{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: rgba(255, 255, 255, 0.20);
}


/** team-eight **/

.team-block-two .inner-box{
  max-width: 260px;
  margin: 0 auto;
}

.team-block-two .inner-box .image-box .image{
  width: 193px;
  height: 193px;
  border-radius: 50%;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25);
}

.team-block-two .inner-box .image-box .image img{
  width: 100%;
  border-radius: 50%;
}

.team-block-two .inner-box .image-box .image:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.8;
  border-radius: 50%;
  transform: scale(0,0);
  z-index: 1;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .image:before{
  transform: scale(1,1);
}

.team-block-two .inner-box .image-box .social-links{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0,0);
  z-index: 2;
  width: 100%;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .social-links{
  transform: translate(-50%,-50%) scale(1,1);
}

.team-block-two .inner-box .image-box .social-links li{
  position: relative;
  display: inline-block;
  margin: 0px 12px;
}

.team-block-two .inner-box .image-box .social-links li a{
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #fff;
}

.team-block-two .inner-box .image-box .social-links li a:hover{
  color: #141417;
}

.team-block-two .inner-box .image-box .image-shape{
  left: -44px;
  top: -25px;
  width: 260px;
  height: 262px;
  background-repeat: no-repeat;
  opacity: 0.2;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .image-shape{
  opacity: 1;
}

.team-eight .owl-dots{
  margin-top: 42px;
}

.team-eight .dots-style-one .owl-dots .owl-dot span{
  width: 8px !important;
  opacity: 0.2;
}

.team-eight .dots-style-one .owl-dots .owl-dot.active span,
.team-eight .dots-style-one .owl-dots .owl-dot span:hover{
  opacity: 1;
}


/** project-seven **/

.project-seven{
  background: #f7f7f7;
}

.project-seven .sortable-masonry{
  margin: 0px 15px;
}

.project-seven .masonry-item{
  padding: 0px 0px;
}

.project-seven .sortable-masonry .filter-tabs li{
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  color: #141417;
}

.project-seven .sortable-masonry .filter-tabs li:before{
  display: none;
}

.project-seven .sortable-masonry .filter-tabs li:last-child{
  margin-right: 0px;
}

.project-block-six .inner-box{
  overflow: hidden;
}

.project-block-six .inner-box .image-box{
  overflow: hidden;
  background: #000;
}

.project-block-six .inner-box .image-box img{
  width: 100%;
  opacity: 0.8;
  transition: all 500ms ease;
}

.project-block-six .inner-box:hover .image-box img{
  opacity: 0.5;
  transform: scale(1.05);
}

.project-block-six .inner-box .view-btn{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0,0);
}

.project-block-six .inner-box .view-btn a:hover{
  color: #fff;
}

.project-block-six .inner-box:hover .view-btn{
  transform: translate(-50%,-50%) scale(1,1);
}

.project-block-six .inner-box .content-box{
  max-width: 270px;
  bottom: 0px;
  opacity: 0;
  padding-right: 15px;
}

.project-block-six .inner-box:hover .content-box{
  bottom: 42px;
  opacity: 1;
}

.project-block-six .inner-box .content-box h3 a{
  color: #fff;
}


/** testimonial-12 **/

.testimonial-12{
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.testimonial-12:before{
  position: absolute;
  content: '';
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.97;
}

.testimonial-block-four .inner-box{
  background: #fff;
  padding-right: 330px;
}

.testimonial-block-four .inner-box .author .thumb-box{
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
}

.testimonial-block-four .inner-box .author img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-four .inner-box .text p{
  line-height: 32px;
}

.testimonial-block-four .inner-box .image-layer{
  right: -77px;
}

.testimonial-block-four .inner-box .shape-layer .shape-1{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 212px;
  height: 283px;
}

.testimonial-block-four .inner-box .shape-layer .shape-2{
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.testimonial-12 .inner-content .shape .shape-1{
  left: -110px;
  top: -70px;
  width: 222px;
  height: 222px;
  background-repeat: no-repeat;
}

.testimonial-12 .inner-content .shape .shape-2{
  left: -25px;
  top: -80px;
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.testimonial-12 .owl-nav{
  position: absolute;
  left: -400px;
  bottom: 80px;
}

.testimonial-12 .owl-nav button{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  font-size: 24px;
  border-radius: 50%;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
}

.testimonial-12 .nav-style-one .owl-nav button.owl-prev{
  transform: rotate(0deg);
  margin-right: 10px;
  top: 0px;
}

.testimonial-12 .nav-style-one .owl-nav button.owl-prev:before{
  display: none;
}

.testimonial-12 .owl-nav button:hover{
  color: #fff;
}


/** pricing-eight **/

.pricing-block-three .pricing-table .theme-btn{
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #141417 !important;
  border: 1px solid #e7e7e7;
  padding: 16px 28px;
}

.pricing-block-three .pricing-table .theme-btn:hover{
  color: #fff !important;
}

.pricing-block-three .pricing-table .feature-list{
  background: #f7f7f7;
}

.pricing-block-three .pricing-table .feature-list li:before{
  position: absolute;
  content: '\e90e';
  font-family: 'icomoon';
  font-size: 14px;
  left: 0px;
  top: 0px;
}

.pricing-block-three .pricing-table .table-header h2 span{
  color: #808080;
}

.pricing-eight .pattern-layer .pattern-1{
  width: 320px;
  height: 402px;
  background-repeat: no-repeat;
}

.pricing-eight .pattern-layer .pattern-2{
  width: 882px;
  height: 636px;
  background-repeat: no-repeat;
}

.pricing-block-three .pricing-table{
  background: #fff;
}


/** cta-nine **/

.cta-nine{
  background: #f7f7f7;
  overflow: hidden;
}

.content_block_21 .content-box .support-box .icon-box{
  color: #fff;
}

.content_block_21 .content-box .support-box h5{
  color: #818182;
}

.content_block_21 .content-box .support-box h3{
  font-size: 26px;
}

.cta-nine .image-box{
  margin-left: -130px;
}

.cta-nine .image-box img{
  width: 100%;
}

.cta-nine .image-box .shape .shape-1{
  top: -50px;
  width: 230px;
  height: 240px;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.cta-nine .image-box .shape .shape-2{
  width: 230px;
  height: 240px;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

.cta-nine .image-box .shape .shape-3{
  bottom: -20px;
  right: -120px;
  width: 360px;
  height: 481px;
  background-repeat: no-repeat;
}

.cta-nine .pattern-layer{
  height: 100%;
  width: 501px;
  background-repeat: no-repeat;
  background-size: cover;
}


/** news-16 **/

.news-16{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.news-16:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.75;
}

.news-16 .news-block-one .inner-box .lower-content{
  margin-top: -70px;
  background: #fff;
}

.news-16 .news-block-one .inner-box .lower-content .theme-btn{
  font-family: 'Poppins', sans-serif;
  padding: 16px 30px;
}

.news-16 .news-block-one .inner-box .lower-content .theme-btn:hover{
  border-color: #e35712;
}

.news-16 .inner-content .single-item{
  border-bottom: 1px solid #e5e5e5;
}

.news-16 .inner-content .single-item:last-child{
  border-bottom: none;
}

.news-16 .inner-content .single-item .post-info li{
  color: #808080;
}

.news-16 .inner-content .single-item .post-info li a{
  color: #141417;
}

.news-16 .inner-content .single-item .post-info li:before{
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 6px;
  height: 6px;
  top: 12px;
  right: -19px;
  border-radius: 50%;
}

.news-16 .inner-content .single-item .post-info li:last-child:before{
  display: none;
}

.news-16 .inner-content .single-item .post-info li a:hover{

}

.news-16 .inner-content .single-item  h4 a{
  color: #141417;
}

.news-16 .inner-content .single-item h4 a:hover{

}


.subscribe-one.home-16 .inner-container input{
  height: 60px;
  padding: 10px 30px;
}

.subscribe-one.home-16 .form-inner .form-group button{
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  width: 204px;
  padding: 17px 20px;
  background: #262c3b;
}

.subscribe-one.home-16 .form-inner .form-group{
  padding-right: 224px;
}

.subscribe-one.home-16 .inner-container{
  background: transparent;
}

.subscribe-one.home-16 .form-inner .form-group button:before,
.subscribe-one.home-16 .form-inner .form-group button:after{
  display: none;
}

.subscribe-one.home-16 .form-inner .form-group button span:after{
  color: #e35712;
}



/************* about-page **************/

.page-title{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 250px 0px 135px 0px;
}

.page-title:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.7;
}

.page-title .content-box h1{
  color: #fff;
  font-size: 24px;
}

.page-title .bread-crumb li{
  color: #a9a9aa;
}

.page-title .bread-crumb li a{
  color: #fff;
}

.page-title .bread-crumb li a:hover,
.page-title .bread-crumb li.current{
  color: #fff;
}

.page-title .bread-crumb li:before{
  position: absolute;
  content: "\f105";
  font-family: 'Font Awesome 5 Pro';
  top: 1px;
  right: -16px;
}

.page-title .bread-crumb li:last-child:before{
  display: none;
}


/** ourmission-section **/

.content_block_22 .content-box .image-box img{
  width: 100%;
  border-radius: 10px;
}

.content_block_22 .content-box .image-box:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.content_block_22 .content-box .image-box:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.content_block_23 .content-box .image-box img{
  width: 100%;
  border-radius: 10px;
}

.content_block_23 .content-box .image-box:before{
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.content_block_23 .content-box .image-box:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.content_block_22 .content-box .image-box{
  overflow: hidden;
}

.content_block_23 .content-box .image-box{
  overflow: hidden;
}

.ourmission-section .pattern-layer{
  top: -100px;
  right: 230px;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.content_block_22 .content-box .shape{
  top: 240px;
  right: -30px;
  background-repeat: no-repeat;
}

.content_block_23 .content-box .shape .shape-1{
  right: -105px;
  top: 40%;
  width: 250px;
  height: 250px;
  background: #f7f7f7;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.content_block_23 .content-box .shape .shape-2{
  bottom: 180px;
  right: -75px;
  width: 117px;
  height: 117px;
  background-repeat: no-repeat;
}

.skills-two.about-page-1{
  background: #f7f7f7;
  overflow: hidden;
}

.skills-two.about-page-1 .progress-box .bar-inner{
  border-radius: 0px;
}

.skills-two.about-page-1 .progress-box .bar{
  border-radius: 0px;
}

.skills-two.about-page-1 .pattern-layer{
  width: 230px;
  height: 240px;
  top: -100px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 7s infinite linear;
  animation: zoom-fade 7s infinite linear;
}

.team-five.about-page-1 .team-block-one .inner-box .image-box .social-links-two li a{
  border-radius: 50%;
}

.testimonial-three.about-page-1 .shape .shape-1{
  top: 70px !important;
}

.subscribe-five.about-page-1{
  background: #f7f7f7;
}

.subscribe-five.about-page-1 .subscribe-form .form-group input{
  border-color: #fff !important;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}

.subscribe-five.about-page-1 .shape{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: cover;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}

/** page-title-two **/

.page-title.style-two{
  padding: 90px 0px 90px 0px;
}

.page-title.style-two:before{
  display: none;
}

.page-title.style-two .content-box h1{
  color: #141417;
}

.page-title.style-two .bread-crumb li{
  color: #808080;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.page-title.style-two .bread-crumb li a{
  color: #808080;
}

.page-title.style-two .bread-crumb li a:hover{
  text-decoration: underline;
  color: #141417;
}

.page-title.style-two .bread-crumb li.current{
  color: #141417;
}

.page-title.style-two .pattern-layer .shape-1{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.page-title.style-two .pattern-layer .shape-2{
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

/** about-17 **/

.about-17 .image-box .image{
  background: #fff;
  padding: 10px;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.about-17 .image-box .image::before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255, 255, 255,.2);
  border-radius: 0%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.about-17 .image-box .image:hover::before {
  -webkit-animation: circle .95s;
  animation: circle .95s;
}

.about-17 .image-box .image img{
  width: 100%;
  border-radius: 50%;
}

.about-17 .image-box .shape-1{
  left: -40px;
  bottom: 94px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.about-17 .image-box .shape-2{
  top: 135px;
  background-repeat: no-repeat;
}

.project-three.about-page-2 .project-block-three{
  max-width: 546px;
  width: 100%;
}

.project-three.about-page-2 .owl-carousel {
  max-width: 1162px;
  margin: 0 auto;
  width: 100%;
  display: block;
}

.project-three.about-page-2 .owl-stage-outer{
  overflow:visible;
}

.project-three.about-page-2 .project-block-three .inner-box:hover .image-box img{
  opacity: 0.5;
}

.project-three.about-page-2 .shape-layer .shape-1{
  left: 320px;
  top: -85px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.project-three.about-page-2 .shape-layer .shape-2{
  right: 320px;
  bottom: -60px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
}

.content_block_25 .content-box .single-item .icon-box{
  transition: all 500ms ease;
  display: inline-block;
}

.content_block_25 .content-box .single-item:hover .icon-box{
  transform: rotateY(180deg);
}


/** process-five **/

.processing-block-four .inner-box .count-box{
  color: #fff;
  box-shadow: 0px 0px 0px 25px rgba(251, 71, 84, 0.10);
}

.processing-block-four .inner-box:hover .count-box{
  color: #fff;
  box-shadow: 0px 0px 0px 25px #fb4754;
}

.processing-block-four .inner-box .count-box .overlay-icon{
  opacity: 0;
}

.processing-block-four .inner-box:hover .count-box .overlay-icon{
  opacity: 1;
}

.processing-block-four .inner-box:hover .count-box span{
  opacity: 0;
}

.processing-block-four .inner-box .count-box:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  transform: scale(0,0);
  z-index: -1;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transition: all 500ms ease;
}

.processing-block-four .inner-box:hover .count-box:before{
  transform: scale(1,1);
}

.processing-block-four .inner-box .count-box:after{
  position: absolute;
  content: '';
  width: 170px;
  height: 170px;
  left: -51px;
  top: -51px;
  border-radius: 50%;
  opacity: 0.3;
  transition: all 500ms ease;
}

.processing-block-four .inner-box:hover .count-box:after{
  opacity: 1;
}

.processing-block-four .inner-box .line-shape{
  right: -46%;
  transform: translateX(-50%);
  width: 155px;
  height: 28px;
  background-repeat: no-repeat;
  opacity: 0.4;
}

.processing-block-four .inner-box:hover .line-shape{
  opacity: 1;
}

.process-five .shape{
  top: 290px;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}


/** faq-two **/

.faq-two{
  background: #f7f7f7;
}

.faq-two .image_block_one .video-inner{
  width: 250px;
}

.faq-two .image_block_one .image-box img{
  border-radius: 0px;
}

.faq-two .image_block_one .image-box{
  padding-right: 170px;
}

.faq-two .image_block_one .image-box .video-inner:before{
  border-radius: 0px;
  opacity: 0.6;
}

.faq-two .image_block_one .image-box .video-inner .video-btn a{
  color: #fff;
}

.faq-two .shape .shape-3{
  left: -70px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.faq-two .shape .shape-4{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.faq-two .accordion-box .block .acc-btn{
  background: #fff;
  border-radius: 0px !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, .08);
}

.faq-two .accordion-box .block .acc-btn .icon-outer{
  color: #141417 !important;
}

.faq-two .accordion-box .block .acc-content p{
  color: #808080;
}

.skills-two.about-page-2{
  background: #222226;
}

.skills-two.about-page-2 .sec-title-12 h2{
  color: #fff;
}

.skills-two.about-page-2 .sec-title-12 p{
  color: #a9a9aa;
}

.skills-two.about-page-2 .progress-box h6,
.skills-two.about-page-2 .progress-box .count-text{
  color: #a9a9aa;
}

.skills-two.about-page-2 .progress-box .bar{
  border-radius: 0px;
  background: #434346;
}

.skills-two.about-page-2 .progress-box .bar-inner{
  border-radius: 0px;
  top: -6px;
}

.skills-two.about-page-2 .pattern-layer .pattern-1{
  width: 223px;
  height: 287px;
  left: -100px;
  background-repeat: no-repeat;
}

.skills-two.about-page-2 .pattern-layer .pattern-2{
  width: 286px;
  height: 373px;
  background-repeat: no-repeat;
}


/** team-nine **/

.team-block-three .inner-box .image{
  background: #000;
  overflow: hidden;
}

.team-block-three .inner-box .image img{
  width: 100%;
  transition: all 500ms ease;
}

.team-block-three .inner-box:hover .image img{
  transform: scale(1.05);
  opacity: 0.5;
}

.team-block-three .inner-box .social-links li a{
  border: 1px solid #e5e5e5;
  color: #808080;
}

.team-block-three .inner-box .social-links li a:hover{
  color: #fff;
}

.page-title.about-page-3{
  padding-top: 139px;
}


/** about-18 **/

.image_block_21 .image-box{
  padding-right: 240px;
}

.image_block_21 .image-box img{
  width: 100%;
}

.image_block_21 .image-box .image{
  overflow: hidden;
}

.image_block_21 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_21 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_21 .image-box .shape .shape-1{
  left: -45px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.image_block_21 .image-box .shape .shape-2{
  right: -45px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.image_block_21 .image-box .image-2{
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
}


/** feature-13 **/

.feature-block-eight .inner-box{
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.08);
  z-index: -1;
}

.feature-block-eight .inner-box:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.feature-block-eight .inner-box:hover:before{
  height: 100%;
  top: 0px;
}

.feature-block-eight .inner-box .text h4,
.feature-block-eight .inner-box .text p{
  transition: all 500ms ease;
}

.feature-block-eight .inner-box:hover .text h4,
.feature-block-eight .inner-box:hover .text p{
  color: #fff;
}


/** team-ten **/

.team-ten{
  background: #f7f7f7;
}

.team-block-four .inner-box{
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.team-block-four .inner-box .image{
  width: 320px;
  height: 320px;
  background: #000;
}

.team-block-four .inner-box .image img{
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.team-block-four .inner-box:hover .image img{
  opacity: 0.5;
}

.team-block-four .inner-box .social-links{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0,0);
  width: 100%;
}

.team-block-four .inner-box:hover .social-links{
  transform: translate(-50%,-50%) scale(1,1);
}

.team-block-four .inner-box .social-links li{
  margin: 0px 3.5px;
}

.team-block-four .inner-box .social-links li a{
  background: #fff;
}

.team-block-four .inner-box .social-links li a:hover{
  color: #fff;
}

.team-block-four .inner-box .lower-content h4 a:hover{

}

.team-ten .shape .shape-1{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.team-ten .shape .shape-2{
  top: 215px;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.team-ten .shape .shape-3{
  width: 301px;
  height: 298px;
  background-repeat: no-repeat;
}


/** funfact-nine **/

.funfact-nine{
  overflow: hidden;
}

.funfact-nine .counter-block-one .count-outer,
.funfact-nine .counter-block-one .icon-box,
.funfact-nine .counter-block-one p{
  color: #fff;
}

.funfact-nine .shape .shape-1{
  top: -65px;
  width: 213px;
  height: 190px;
  background-repeat: no-repeat;
}

.funfact-nine .shape .shape-2{
  bottom: -65px;
  width: 229px;
  height: 191px;
  background-repeat: no-repeat;
}


/** chooseus-12 **/

.content_block_26 .content-box .single-item .icon-box{
  color: #fff;
}

.image_block_22 .image-box .image{
  overflow: hidden;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
}

.image_block_22 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_22 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_22 .image-box .shape .shape-1{
  left: -63px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.image_block_22 .image-box .shape .shape-2{
  right: -80px;
  width: 182px;
  height: 107px;
  background-repeat: no-repeat;
}

.testimonial-five.about-page-3 .owl-dots{
  position: relative;
  display: block;
  text-align: center;
  margin-top: 48px;
}

.testimonial-five.about-page-3 .dots-style-one .owl-dots .owl-dot span{
  opacity: 0.3;
}

.testimonial-five.about-page-3 .dots-style-one .owl-dots .owl-dot.active span,
.testimonial-five.about-page-3 .dots-style-one .owl-dots .owl-dot span:hover{
  opacity: 1;
}

.testimonial-block-one .inner-box .quote{
  color: #f0f0f0;
}

.testimonial-five.about-page-3 .shape .shape-1{
  top: 270px;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.testimonial-five.about-page-3 .shape .shape-2{
  left: 230px;
  width: 182px;
  height: 107px;
  background-repeat: no-repeat;
}

.feature-13.about-page-4{
  background: #f7f7f7;
}

.lh_52{
  line-height: 52px;
}

.about-19 .image_block_20 .image-box .text{
  left: 0px;
  z-index: 1;
}

.about-19 .image_block_20 .image-box .image{
  overflow: hidden;
}

.about-19 .image_block_20 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.about-19 .image_block_20 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.about-19 .image_block_20 .image-box .shape .shape-3{
  top: -60px;
  right: -55px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.about-19 .image_block_20 .image-box .shape .shape-4{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.about-19 .pattern-layer{
  width: 182px;
  height: 107px;
  background-repeat: no-repeat;
}


/** funfact-ten **/

.funfact-ten .counter-block-one .inner-box .count-outer{
  color: #141417;
}

.funfact-ten .counter-block-one .inner-box p{
  color: #141417;
}

.funfact-ten .pattern-layer .pattern-1{
  width: 713px;
  height: 473px;
  background-repeat: no-repeat;
}

.funfact-ten .pattern-layer .pattern-2{
  width: 593px;
  height: 483px;
  background-repeat: no-repeat;
}


/** process-six **/

.process-six{
  background: #f7f7f7;
}

.processing-block-five .inner-box .count-box{
  color: #fff;
  box-shadow: 0px 0px 0px 20px rgba(227, 87, 18, 0.10);
}

.processing-block-five .inner-box .count-box:before{
  position: absolute;
  content: '';
  background: rgba(227, 87, 18, 0.10);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  left: -40px;
  top: -40px;
}

.processing-block-five .inner-box .count-box .overlay-icon{
  opacity: 0;
  transform: scale(0,0);
}

.processing-block-five .inner-box:hover .count-box .overlay-icon{
  opacity: 1;
  transform: scale(1,1);
}

.processing-block-five .inner-box:hover .count-box span{
  opacity: 0;
}

.processing-block-five .inner-box .line-shape{
  right: -50%;
  transform: translateX(-50%);
  width: 169px;
  height: 2px;
  background-repeat: no-repeat;
}

.clients-one.light{
  background: #252628;
  overflow: hidden;
}

.clients-one.light .pattern-layer{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: cover;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}

.testimonial-three .shape .shape-3{
  right: 280px;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.team-three.about-page-4{
  background: #f7f7f7;
}

.team-three.about-page-4 .team-block-one .inner-box{
  overflow: visible;
}

.team-three.about-page-4 .shape .shape-1{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.team-three.about-page-4 .shape .shape-2{
  top: 215px;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.team-three.about-page-4 .shape .shape-3{
  width: 301px;
  height: 298px;
  background-repeat: no-repeat;
}

.page-title.style-two .pattern-layer .shape-3{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: -1;
}

.page-title.about-page-5{
  padding-bottom: 175px;
}


/** about-20 **/

.about-20 .title-box:before{
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 83px;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}

.about-20 .title-box{
  border-bottom: 1px solid #e5e5e5;
}

.about-20 .title-box .text p{
  line-height: 34px;
}

.about-20 .feature-block-nine .inner-box .icon-box{
  background: -webkit-linear-gradient(45deg, #f0ecf9 0%, #f9f3fa 100%);
}

.about-20 .feature-block-nine .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(45deg, #7443c3 30%, #a149c6 70%);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.about-20 .feature-block-nine .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.about-20 .image-box .image{
  overflow: hidden;
}

.about-20 .image-box .image img{
  width: 100%;
  border-radius: 5px;
}

.about-20 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.about-20 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.about-20 .image-box .image-shape .shape-1{
  left: -125px;
  bottom: -90px;
  width: 250px;
  height: 250px;
  background: #f7f7f7;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.about-20 .image-box .image-shape .shape-2{
  right: -135px;
  top: -55px;
  width: 250px;
  height: 250px;
  background: #f7f7f7;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.about-20 .image-box .image-shape .shape-3{
  width: 117px;
  height: 117px;
  left: -156px;
  background-repeat: no-repeat;
}

.about-20 .image-box .image-shape .shape-4{
  top: -85px;
  width: 117px;
  height: 117px;
  right: -50px;
  background-repeat: no-repeat;
}


/** team-11 **/

.team-11 .shape .shape-1{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.team-11 .shape .shape-2{
  top: 215px;
  width: 221px;
  height: 129px;
  background-repeat: no-repeat;
}

.team-11 .shape .shape-3{
  width: 301px;
  height: 298px;
  background-repeat: no-repeat;
}

.team-block-five .inner-box .image-box{
  overflow: hidden;
  background: #000;
}

.team-block-five .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.team-block-five .inner-box:hover .image-box img{
  opacity: 0.5;
  transform: scale(1.05);
}

.team-block-five .inner-box .lower-content{
  width: 230px;
  background: #fff;
}

.team-block-five .inner-box .lower-content:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.team-block-five .inner-box:hover .lower-content:before{
  height: 100%;
  top: 0px;
}

.team-block-five .inner-box:hover .lower-content{
  padding-bottom: 55px;
}

.team-block-five .inner-box .lower-content .social-links li a{
  color: #fff;
}

.team-block-five .inner-box .lower-content .social-links{
  opacity: 0;
}

.team-block-five .inner-box:hover .lower-content .social-links{
  opacity: 1;
}

.team-block-five .inner-box:hover .lower-content h4 a,
.team-block-five .inner-box:hover .lower-content .designation{
  color: #fff;
}

.team-block-five .inner-box .lower-content h4 a:hover{
  text-decoration: underline;
}


/** skills-four **/

.image_block_23 .image-box .image{
  overflow: hidden;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
}

.image_block_23 .image-box .image img{
  width: 100%;
}

.image_block_23 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_23 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_23 .image-box .image-shape .shape-1{
  bottom: -70px;
  width: 250px;
  height: 250px;
  background: #f7f7f7;
}

.image_block_23 .image-box .image-shape .shape-2{
  width: 117px;
  height: 117px;
  background-repeat: no-repeat;
}

.image_block_23 .image-box .image-shape .shape-3{
  top: -60px;
  right: -60px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.skills-four .progress-box .bar{
  border-radius: 0px;
  background: #f2f2f2;
}

.skills-four .progress-box .bar-inner{
  border-radius: 0px;
}

.news-three.about-page-5 .news-block-one .inner-box .lower-content .theme-btn{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #e7e7e7;
}

.news-three.about-page-5 .pattern-layer .shape-1{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.news-three.about-page-5 .news-block-one .inner-box:hover{
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.10);
}

.news-three.about-page-5{
  padding-top: 250px;
}

.news-three.about-page-5 .pattern-layer .shape-2{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.news-three.about-page-5 .pattern-layer .shape-3{
  right: 260px;
  width: 182px;
  height: 107px;
  background-repeat: no-repeat;
}



/************* testimonial-page *****************/

.testimonial-one.testimonial-page-1 .testimonial-block-one .inner-box{
  margin-bottom: 70px;
}

.testimonial-five.testimonial-page-2 .testimonial-block-one .inner-box{
  margin-bottom: 30px;
}

.testimonial-11.testimonial-page-5:before{
  display: none;
}




/************** service-page ****************/

/** service-18 **/

.service-block-14 .inner-box .icon-box .icon-shape{
  left: -40%;
  top: -15%;
  background-repeat: no-repeat;
  background: #fff0f2;
  z-index: -1;
}

.icon-shape{
  left: -40%;
  top: -15%;
  background-repeat: no-repeat;
  background: #fff0f2;
  z-index: 0;
}

.service-block-14 .inner-box .link a:hover{
  padding-right: 30px;
}

.service-block-14 .inner-box{
  transition: all 500ms ease;
}

.service-block-14 .inner-box:hover{
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.20);
}

.service-block-14 .inner-box .link a i{
  position: absolute;
  top: 6px;
  right: 0px;
  opacity: 0;
  transition: all 500ms ease;
}

.service-block-14 .inner-box .link a:hover i{
  opacity: 1;
}

.pricing-block-one .pricing-table .table-content-two .feature-list li:before{
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  left: 0px;
  top: 6px;
  border-radius: 50%;
}

.pricing-block-one .pricing-table .table-content-two .feature-list li:after{
  position: absolute;
  left: 2px;
  top: 8px;
  content: '';
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.pricing-one.service-page-1 .pricing-block-one .pricing-table{
  border: none;
}

.pricing-one.service-page-1 .pricing-block-one .pricing-table .table-header{
  border-bottom: 1px solid #e5e5e5;
}

.pricing-one.service-page-1 .pricing-block-one .pricing-table .table-header h6{
  text-transform: capitalize;
}

.pricing-one.service-page-1 .pricing-block-one .pricing-table .theme-btn{
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'Poppins', sans-serif;
}

.pricing-one.service-page-1 .pricing-block-one.active-block .pricing-table .table-header{
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.pricing-block-one.active-block .pricing-table .table-content-two .feature-list li{
  color: #fff;
}

.pricing-block-one.active-block .pricing-table .table-content-two .feature-list li:after{
  background: #141417;
}

.pricing-one.service-page-1 .tab-btn-box .tab-btns li{
  font-family: 'Poppins', sans-serif;
}

.subscribe-five.service-page-1 .subscribe-form .form-group input{
  border-color: #fff !important;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}

.subscribe-five.service-page-1 .shape{
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: cover;
  animation: slide 80s linear infinite;
  -webkit-animation: slide 80s linear infinite;
}


/** service-19 **/

.service-19 .tab-btn-box{
  border-bottom: 1px solid #e5e5e5;
}

.service-19 .tab-btns li{
  position: relative;
  display: block;
  float: left;
  width: 25%;
  padding: 32px 20px 26px 100px;
  cursor: pointer;
  transition: all 500ms ease;
}

.service-19 .tab-btns li:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 3px;
  left: 0px;
  bottom: -1px;
  transition: all 500ms ease;
}

.service-19 .tab-btns li.active-btn:before,
.service-19 .tab-btns li:hover:before{
  width: 100%;
}

.content_block_27 .content-box .list-style-one li:before{
  background: #141417;
}

.image_block_24 .image-box .image{
  overflow: hidden;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
}

.image_block_24 .image-box .image img{
  width: 100%;
  border-radius: 10px;
}

.image_block_24 .image-box .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.image_block_24 .image-box .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.image_block_24 .image-box .shape .shape-1{
  top: -30px;
  left: -30px;
  background-repeat: no-repeat;
}

.image_block_24 .image-box .shape .shape-2{
  right: -100px;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
}

.image_block_24 .image-box .shape .shape-3{
  bottom: -60px;
  right: -70px;
  z-index: -1;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.image_block_24 .image-box .shape .shape-4{
  top: -30px;
  right: -30px;
  background-repeat: no-repeat;
}

.image_block_24 .image-box .shape .shape-5{
  left: -100px;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
}

.image_block_24 .image-box .shape .shape-6{
  bottom: -60px;
  left: -70px;
  z-index: -1;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}


/** service-20 **/

.service-20 .tab-btn-box{
  position: relative;
  display: block;
}

.service-20 .tab-btns li{
  position: relative;
  display: inline-block;
  width: 255px;
  margin: 0px 3.5px;
  padding: 40px 15px 42px 15px;
  text-align: center;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  transition: all 500ms ease;
}

.service-20 .tab-btns li:before{
  position: absolute;
  content: '';
  background: #fff;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  left: -15px;
  top: -15px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  opacity: 0;
  z-index: 1;
  transition: all 500ms ease;
}

.service-20 .tab-btns li.active-btn:before,
.service-20 .tab-btns li.active-btn:after{
  opacity: 1;
}

.service-20 .tab-btns li:after{
  position: absolute;
  content: '';
  background: #fff;
  width: 22px;
  height: 22px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  bottom: -22px;
  z-index: 1;
  opacity: 0;
  transition: all 500ms ease;
}

.service-20 .content_block_27 .content-box .theme-btn{
  border-radius: 30px;
}

.funfact-ten .pattern-layer .pattern-3{
  width: 158px;
  height: 93px;
  background-repeat: no-repeat;
}

.funfact-ten .pattern-layer .pattern-4{
  width: 158px;
  height: 82px;
  background-repeat: no-repeat;
}

.pricing-block-four .pricing-table{
  background: #fff;
  border-radius: 15px;
}

.pricing-block-four .pricing-table .table-inner{
  padding-left: 260px;
}

.pricing-block-four .pricing-table .table-header{
  background: #f7f7f7;
  width: 230px;
  border-radius: 15px;
}

.pricing-block-four .pricing-table .table-header .theme-btn{
  background: #fff;
  border-radius: 30px;
  color: #141417 !important;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  padding: 12px 34px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.pricing-block-four .pricing-table .table-header .theme-btn:hover{
  color: #fff !important;
}

.subscribe-five.service-page-5 .subscribe-form .message-btn .theme-btn span:after{
  color: #222;
}

.pricing-block-four.active-block .pricing-table .table-header h5,
.pricing-block-four.active-block .pricing-table .table-header h2,
.pricing-block-four.active-block .pricing-table .table-header p{
  color: #fff;
}

.pricing-block-four.active-block .pricing-table .table-header .theme-btn:hover{
  color: #fff !important;
  background: #141417;
}

.pricing-one.service-page-3 .pricing-block-four.active-block .pricing-table .table-header .theme-btn:hover{
  background: #fff;
}

.pricing-one.service-page-3 .pricing-block-four .pricing-table .table-header .theme-btn span:after{
  color: #e45712;
}

.pricing-one.service-page-3 .tab-btn-box .tab-btns:before{
  background: transparent;
}

.pricing-one.service-page-3 .tab-btn-box .tab-btns li{
  font-family: 'Poppins', sans-serif;
}

.service-15.service-page-4{
  background: #fff;
}

.service-15.service-page-4 .service-block-12 .inner-box .lower-content .link a{
  position: relative;
}

.service-15.service-page-4 .service-block-12 .inner-box .lower-content .link a:hover{
  padding-right: 30px;
}

.service-15.service-page-4 .service-block-12 .inner-box .lower-content .link a i{
  position: absolute;
  top: 6px;
  right: 0px;
  opacity: 0;
  transition: all 500ms ease;
}

.service-15.service-page-4 .service-block-12 .inner-box .lower-content .link a:hover i{
  opacity: 1;
}


/** pricing-nine **/

.pricing-nine .tab-btns{
  position: relative;
  display: inline-block;
  width: 260px;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.pricing-nine .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  width: 50%;
  text-align: center;
  font-size: 15px;
  line-height: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #141417;
  cursor: pointer;
  padding: 12px 15px;
  transition: all 500ms ease;
}

.pricing-nine .tab-btns li:hover,
.pricing-nine .tab-btns li.active-btn{
  color: #fff;
}

.pricing-block-five .pricing-table .theme-btn{
  border-radius: 5px;
  padding: 11px 32px;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #e7e7e7;
  color: #141417 !important;
}

.pricing-block-five .pricing-table .theme-btn:hover{
  color: #fff !important;
}

.pricing-block-five .pricing-table{
  background: #fff;
}

.pricing-block-five .pricing-table .table-header{
  border-bottom: 1px solid #e7e7e7;
}

.pricing-block-five .pricing-table .feature-list li i{
  margin-right: 8px;
  font-size: 16px;
}

.pricing-nine .tabs-content .shape .shape-1{
  width: 230px;
  height: 240px;
  background-repeat: no-repeat;
}

.pricing-nine .tabs-content .shape .shape-2{
  width: 230px;
  height: 240px;
  top: -60px;
  right: -60px;
  background-repeat: no-repeat;
}


/** faq-three **/

.faq-three .accordion-box .block .acc-btn{
  border: 1px solid #dedede;
}

.faq-three .accordion-box .block .acc-btn.active{
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.08);
}

.accordion-box .block .acc-btn.active .icon-outer{
  color: #1a1a1d;
}

.subscribe-five.service-page-1 .subscribe-form {
  padding-right: 225px;
}


/** service-21**/

.content_block_28 .content-box{
  padding-right: 400px;
  background: #fff;
}

.content_block_28 .content-box .image-box{
  width: 300px;
  height: 400px;
  overflow: hidden;
}

.content_block_28 .content-box .image-box:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.content_block_28 .content-box:hover .image-box:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.content_block_28 .content-box .image-box img{
  width: 100%;
  border-radius: 10px;
}

.content_block_28 .content-box .list li:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #141417;
  border-radius: 50%;
}

.content_block_28 .content-box .theme-btn{
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #e7e7e7;
  color: #141417 !important;
}

.service-21 .tab-btns li{
  position: relative;
  display: block;
  background: #fff;
  padding: 47px 25px 47px 80px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  z-index: 1;
  color: #141417;
  transition: all 500ms ease;
}

.service-21 .tab-btns li:last-child{
  margin-bottom: 0px;
}

.service-21 .tab-btns li.active-btn h6,
.service-21 .tab-btns li:hover h6,
.service-21 .tab-btns li.active-btn .icon-box,
.service-21 .tab-btns li:hover .icon-box{
  color: #fff;
}

.service-21 .tab-btns li:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  z-index: -1;
  transition: all 500ms ease;
}

.service-21 .tab-btns li:hover:before,
.service-21 .tab-btns li.active-btn:before{
  width: 100%;
}

.service-21 .tab-btns li .icon-box{
  top: 43px;
}

.service-21 .tab-btns li:after{
  position: absolute;
  content: '';
  background: #fff;
  width: 20px;
  height: 20px;
  right: -8px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  opacity: 0;
  transition: all 500ms ease;
}

.service-21 .tab-btns li:hover:after,
.service-21 .tab-btns li.active-btn:after{
  opacity: 1;
}

.service-21 .tabs-content .shape .shape-1{
  left: -30px;
  top: -30px;
  background-repeat: no-repeat;
}

.service-21 .tabs-content .shape .shape-2{
  right: -110px;
  width: 87px;
  height: 87px;
  background-repeat: no-repeat;
  z-index: 1;
}

.service-21 .tabs-content .shape .shape-3{
  bottom: -65px;
  right: -80px;
  background: #f0f0f0;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.service-21 .tab-btns li:hover,
.service-21 .tab-btns li.active-btn{
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
}


/** service-details **/

.service-details-content img{
  width: 100%;
  border-radius: 10px;
}

.service-details-content .image{
  overflow: hidden;
}

.service-details-content .image:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.service-details-content .image:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.service-details-content .content-two .list li{
  width: 33.333%;
}

.service-details-content .content-two .list li:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #141417;
  border-radius: 50%;
}

.service-details-content .accordion-box .block .acc-btn{
  background: #f5f5f5;
  border-radius: 8px !important;
}

.service-details-content .accordion-box .block .acc-btn.active{
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10);
}

.service-details-content .accordion-box .block .acc-btn.active h5{
  color: #fff;
}

.service-details-content .accordion-box .block .acc-btn.active .icon-outer{
  color: #fff;
}

.service-details-content .accordion-box .block .acc-content p{
  color: #808080;
}

.service-sidebar .category-widget .category-list li a{
  background: #f5f5f5;
  padding: 36px 25px 36px 80px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  color: #141417;
  transition: all 500ms ease;
}

.service-sidebar .category-widget .category-list li a i{
  position: absolute;
  left: 30px;
  top: 36px;
  font-size: 30px;
}

.service-sidebar .category-widget .category-list li a.current,
.service-sidebar .category-widget .category-list li a:hover{
  color: #fff;
}

.service-sidebar .category-widget .category-list li a:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  border-radius: 5px;
  transition: all 500ms ease;
}

.service-sidebar .category-widget .category-list li a.current:before,
.service-sidebar .category-widget .category-list li a:hover:before{
  width: 100%;
}

.service-sidebar .sidebar-widget{
  background: #f5f5f5;
}

.service-sidebar .consulting-widget input[type='text'],
.service-sidebar .consulting-widget input[type='email'],
.service-sidebar .consulting-widget textarea{
  border: 1px solid #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.service-sidebar .consulting-widget textarea{
  height: 120px;
  resize: none;
  display: block;
}

.service-sidebar .consulting-widget .form-group{
  margin-bottom: 15px;
}

.service-sidebar .consulting-widget .form-group:last-child{
  margin-bottom: 0px;
}

.service-sidebar .consulting-widget .form-group button{
  width: 100%;
}

.service-sidebar .contact-widget .info-list li i{
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 20px;
}

.service-details-2 .service-sidebar .category-widget .category-list li a{
  background: transparent;
  border: 1px solid #e5e5e5;
}

.service-details-2 .service-sidebar .sidebar-widget{
  background: transparent;
  border: 1px solid #e5e5e5;
}

.service-details-2 .service-sidebar .consulting-widget input[type='text'],
.service-details-2 .service-sidebar .consulting-widget input[type='email'],
.service-details-2 .service-sidebar .consulting-widget textarea{
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.service-sidebar .support-widget{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.service-sidebar .support-widget .shape{
  width: 334px;
  height: 232px;
  background-repeat: no-repeat;
}

.service-sidebar .support-widget:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  opacity: 0.95;
}

.service-sidebar .support-widget .icon-box{
  background: rgba(255, 255, 255, 0.20);
  color: #fff;
}

.service-sidebar .support-widget h3{
  color: rgba(255, 255, 255, 0.50);
}

.service-sidebar .support-widget h3 a{
  color: #fff;
}

.service-sidebar .support-widget h3 a:hover{
  text-decoration: underline;
}

.service-sidebar .testimonial-widget{
  background: #222226;
}

.service-sidebar .testimonial-widget .single-item .rating li{
  color: #ffc92e;
}

.service-sidebar .testimonial-widget .single-item .text p{
  color: #fff;
}

.service-sidebar .testimonial-widget .single-item .author .thumb-box imb{
  width: 100%;
  border-radius: 50%;
}

.service-sidebar .testimonial-widget .single-item .author h5{
  color: #fff;
  position: relative;
  z-index: 1;
}

.service-sidebar .testimonial-widget .single-item .author .designation{
  color: #a9a9aa;
}

.service-sidebar .testimonial-widget .single-item .author .quote{
  color: #39393c;
}

.service-details-content .tabs-box .tab-btns{
  border-bottom: 1px solid #e5e5e5;
}

.service-details-content .tabs-box .tab-btns li{
  position: relative;
  display: inline-block;
  float: left;
  font-size: 20px;
  line-height: 30px;
  color: #141417;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  padding: 0px 20px 9px 20px;
  cursor: pointer;
  transition: all 500ms ease;
}

.service-details-content .tabs-box .tab-btns li:before{
  position: absolute;
  content: '';
  width: 0%;
  left: 0px;
  bottom: -1px;
  height: 3px;
  transition: all 500ms ease;
}

.service-details-content .tabs-box .tab-btns li.active-btn:before,
.service-details-content .tabs-box .tab-btns li:hover:before{
  width: 100%;
}

.service-details-content .two-column .list li{
  width: 100%;
}

.service-details-content .two-column .list li:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #141417;
  border-radius: 50%;
}

.service-details-3 .service-sidebar .category-widget .category-list li a{
  background: transparent;
  padding: 25px 25px 25px 30px;
  border: 1px solid #e5e5e5;
}

.service-details-3 .service-sidebar .category-widget .category-list li a:after{
  position: absolute;
  content: "\e903";
  font-family: 'icomoon';
  top: 25px;
  right: 28px;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.service-details-3 .service-sidebar .sidebar-widget{
  background: transparent;
}

.service-sidebar .contact-widget .social-links li a{
  color: #bebebe;
}

.service-sidebar .download-widget .download-list li a{
  background: #f2f2f2;
}

.service-sidebar .download-widget .download-list li a i{
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 33px;
  transition: all 500ms ease;
}

.service-sidebar .download-widget .download-list li a span{
  color: #808080;
}

.service-sidebar .download-widget .download-list li a:hover i,
.service-sidebar .download-widget .download-list li a:hover h6,
.service-sidebar .download-widget .download-list li a:hover span{
  color: #fff;
}

.service-details-content .image_block_one .image-box .skills-box{
  width: 100%;
  box-shadow: none;
}

.service-details-content .image_block_one .image-box{
  padding: 0px;
}

.image_block_one .image-box .skills-box .piechart_2 span{
  position: absolute;
  display: flex;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  line-height: 38px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #141417;
  text-align: center;
}

.image_block_one .image-box .skills-box .piechart_2 span:after{
  color: #141417;
  content: "%";
  font-size: 22px;
}

.service-details-content .content-three .list li:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #141417;
  border-radius: 50%;
}

.service-details-content .quote-inner blockquote h6,
.service-details-content .quote-inner blockquote h5{
  color: #fff;
}

.service-details-content .quote-inner blockquote .icon{
  color: rgba(255, 255, 255, 0.30);
}

.service-details-content .quote-inner blockquote h5:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 15px;
  height: 2px;
  left: 0px;
  top: 13px;
}


/** career-page **/

.career-section .content-box .theme-btn{
  border-radius: 5px;
  padding-top: 17px;
  padding-bottom: 17px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.career-section .image-box .image img{
  width: 100%;
}

.career-section .image-box .image-2{
  right: -30px;
}

.career-section .image-box .shape .shape-1{
  left: -20px;
  top: -20px;
  width: 182px;
  height: 107px;
  background-repeat: no-repeat;
}

.career-section .image-box .shape .shape-2{
  right: -70px;
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.career-section .pattern-layer .pattern-1{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.career-section .pattern-layer .pattern-2{
  width: 193px;
  height: 202px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}


/** job-category **/

.job-category .single-item .icon-box{
  background: #f4f0fd;
}

.job-category .single-item:hover .icon-box{
  color: #fff;
  background: rgba(255, 255, 255, 0.10);
}

.job-category .single-item:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  border-radius: 5px;
  transition: all 500ms ease;
}

.job-category .single-item:hover:before{
  height: 100%;
  top: 0px;
}

.job-category .single-item:hover h4,
.job-category .single-item:hover span{
  color: #fff;
}


/** positions-section **/

.positions-block-one .inner-box .list li:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.positions-block-one .inner-box .theme-btn{
  border: 1px solid #e5e5e5;
  color: #141417 !important;
  padding-top: 16px;
  padding-bottom: 16px;
}

.positions-block-one .inner-box{
  background: #fff;
}

.positions-block-one .inner-box .theme-btn:hover{
  color: #fff !important;
}

.positions-section .pattern-layer .pattern-1{
  width: 593px;
  height: 563px;
  background-repeat: no-repeat;
}

.positions-section .pattern-layer .pattern-2{
  right: 280px;
  width: 193px;
  height: 202px;
  background-repeat: no-repeat;
}


/** process-page **/

.process-six.process-page{
  background: #fff;
}

.process-six.process-page .processing-block-five .inner-box .count-box{
  box-shadow: 0px 0px 0px 20px rgba(97, 42, 222, 0.10);
}

.process-six.process-page .processing-block-five .inner-box .count-box:before{
  background: rgba(97, 42, 222, 0.10);
}


/** process-seven **/

.processing-block-six .inner-box .count-box{
  background: #f7f7f7;
}

.processing-block-six .inner-box .count-box:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  z-index: -1;
  transition: all 500ms ease;
}

.processing-block-six .inner-box:hover .count-box:before{
  transform: scale(1,1);
}

.processing-block-six .inner-box .count-box .overlay-icon{
  opacity: 0;
  color: #fff;
}

.processing-block-six .inner-box:hover .count-box .overlay-icon{
  opacity: 1;
}

.processing-block-six .inner-box:hover .count-box span{
  opacity: 0;
}

.processing-block-six .inner-box .count-box:after{
  position: absolute;
  content: '';
  border: 2px dashed #d9d9d9;
  border-radius: 50%;
  width: 112px;
  height: 112px;
  left: -21px;
  top: -21px;
  transition: all 500ms ease;
}

.processing-block-six .inner-box:before{
  position: absolute;
  content: "\f105";
  font-family: 'Font Awesome 5 Pro';
  right: -15px;
  top: 23px;
  font-size: 30px;
  color: #dcdcdc;
}

.processing-block:last-child .processing-block-six .inner-box:before{
  display: none;
}

.process-three.process-page .processing-block-three .inner-box .icon-box .count-text{
  color: #fff;
  transition: all 500ms ease;
}

.process-three.process-page .processing-block-three .inner-box:hover .icon-box{
  color: #fff;
}

.process-three.process-page .processing-block-three .inner-box .icon-box:before{
  transition: all 500ms ease;
}

.process-three.process-page .processing-block-three .inner-box:hover .icon-box .count-text{
  background: #fff;
}

.process-three.process-page .sec-title h2{
  color: #fff;
}

.process-five.process-page .processing-block-four .inner-box .count-box{
  box-shadow: 0px 0px 0px 25px rgba(106, 54, 255, 0.10);
}

.process-five.process-page .processing-block-four .inner-box:hover .count-box{
  box-shadow: 0px 0px 0px 25px rgba(106, 54, 255, 1);
}


/** faq-page-section **/

.faq-page-section .accordion-box .block .acc-btn{
  border: 1px solid #dedede;
  border-radius: 5px;
}

.faq-page-section .accordion-box .block .acc-btn.active{
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.08);
}

.faq-sidebar .support-widget{
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.faq-sidebar .support-widget .shape{
  width: 334px;
  height: 232px;
  background-repeat: no-repeat;
}

.faq-sidebar .support-widget:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  opacity: 0.95;
}

.faq-sidebar .support-widget .icon-box{
  background: rgba(255, 255, 255, 0.20);
  color: #fff;
}

.faq-sidebar .support-widget h3{
  color: rgba(255, 255, 255, 0.50);
}

.faq-sidebar .support-widget h3 a{
  color: #fff;
}

.faq-sidebar .support-widget h3 a:hover{
  text-decoration: underline;
}

.faq-sidebar .consulting-widget{
  background: #f5f5f5;
}

.faq-sidebar .consulting-widget input[type='text'],
.faq-sidebar .consulting-widget input[type='email'],
.faq-sidebar .consulting-widget textarea{
  border: 1px solid #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.faq-sidebar .consulting-widget textarea{
  height: 120px;
  resize: none;
  display: block;
}

.faq-sidebar .consulting-widget .form-group{
  margin-bottom: 15px;
}

.faq-sidebar .consulting-widget .form-group:last-child{
  margin-bottom: 0px;
}

.faq-sidebar .consulting-widget .form-group button{
  width: 100%;
}

.faq-sidebar .category-widget .category-list li a:hover{
  color: #141417;
}

.faq-sidebar .category-widget .category-list li a i{
  position: relative;
  top: 2px;
  margin-left: 0px;
  opacity: 0;
  color: #141417;
  transition: all 500ms ease;
}

.faq-sidebar .category-widget .category-list li a:hover i{
  opacity: 1;
  margin-left: 10px;
}

.faq-page-section.faq-page-2 .accordion-box .block .acc-btn.active h4{
  color: #fff;
}

.faq-page-section.faq-page-2 .accordion-box .block .acc-btn.active .icon-outer{
  color: #fff;
}

.faq-page-section.faq-page-2 .accordion-box .block .acc-content p{
  color: #808080;
}

.faq-page-section.faq-page-3 .accordion-box .block .acc-btn{
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.08);
}

.faq-page-section.faq-page-3 .accordion-box .block .acc-btn.active h4,
.faq-page-section.faq-page-3 .accordion-box .block .acc-btn.active .icon-outer{
  color: #fff;
}
.faq-page-section.faq-page-3 .accordion-box .block .acc-content p{
  color: #808080;
}

.contact-one.faq-page-3 .default-form input,
.contact-one.faq-page-3 .default-form textarea{
  border-radius: 5px !important;
  font-size: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  color: #808080 !important;
}

.contact-one.faq-page-3 .default-form input:focus,
.contact-one.faq-page-3 .default-form textarea:focus{
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05) !important;
}

.contact-one.faq-page-3 .form-group{
  padding: 0px 15px;
}

.contact-one.faq-page-3 .form-group .theme-btn{
  width: auto;
  border-radius: 5px;
  padding-top: 17px;
  padding-bottom: 17px;
  font-size: 16px;
}

.contact-one .shape{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


/** team-page **/

.team-four.team-page-2 .team-block-one .inner-box .lower-content:before{
  display: none;
}

.team-four.team-page-2 .team-block-one .inner-box .lower-content h4 a {
  color: #141417 !important;
}

.team-four.team-page-2 .team-block-one .inner-box .lower-content .designation{
  color: #808080 !important;
}

.team-four.team-page-2 .team-block-one .inner-box .lower-content h4:before{
  background: #141417 !important;
}

.team-three.team-page-3 .team-block-one .inner-box{
  overflow: visible;
}

.team-four.team-page-4 .team-block-one .inner-box .lower-content{
  text-align: left;
}

.team-four.team-page-4 .team-block-one .inner-box .lower-content h4:before{
  display: none;
}

.team-ten.team-page-7{
  background: transparent;
}


/** team-details **/

.team-details-one .image-box{
  overflow: hidden;
}

.team-details-one .image-box img{
  width: 100%;
}

.team-details-one .image-box:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.team-details-one .image-box:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.team-details-one .team-details-content .content-one .info li strong{
  width: 90px;
}

.team-details-one .team-details-content .content-one .info:before{
  position: absolute;
  content: '';
  width: 3px;
  height: calc(100% - 20px);
  left: 0px;
  top: 8px;
}

.team-details-one .team-details-content .content-one .info li a{
  color: #808080;
}

.team-details-one .team-details-content .content-one .social-links li a{
  border: 1px solid #e6e6e6;
  color: #808080;
}

.team-details-one .team-details-content .content-one .social-links li a:hover{
  color: #fff;
  z-index: 1;
}

.team-details-one .team-details-content .content-one .social-links li a:before{
  position: absolute;
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0,0);
  transition: all 500ms ease;
}

.team-details-one .team-details-content .content-one .social-links li a:hover:before{
  transform: scale(1,1);
}

.team-details-one .progress-box .bar{
  border-radius: 0px;
  background: #f2f2f2;
}

.team-details-one .progress-box .bar-inner{
  border-radius: 0px;
}

.team-details-one .progress-box .count-text{
  font-family: 'Poppins', sans-serif;
}

.team-details-one .content-three .form-inner .form-group{
  margin-bottom: 20px;
  padding: 0px 10px;
}

.team-details-one .content-three .form-inner .form-group:last-child{
  margin-bottom: 0px;
}

.team-details-one .content-three .form-inner{
  margin: 0px 5px;
}

.team-details-one .content-three .form-inner .form-group input[type='text'],
.team-details-one .content-three .form-inner .form-group input[type='email'],
.team-details-one .content-three .form-inner .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  color: #141417;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
  transition: all 500ms ease;
}

.team-details-one .content-three .form-inner .form-group textarea{
  height: 150px;
  resize: none;
}

.team-details-two .team-details-content{
  background: #fff;
}

.team-details-two .team-details-content .image-box{
  overflow: hidden;
}

.team-details-two .team-details-content .image-box img{
  width: 100%;
  border-radius: 10px;
}

.team-details-two .team-details-content .image-box:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.team-details-two .team-details-content .image-box:hover:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.team-details-two .team-details-content .content-box .info li strong{
  width: 160px;
}

.team-details-two .team-details-content .content-box .info li a{
  color: #808080;
}

.team-details-two .team-details-content .content-box .social-links li a{
  border: 1px solid #e6e6e6;
  color: #808080;
}

.team-details-two .team-details-content .content-box .social-links li a:hover{
  color: #fff;
}


/** experience-section **/

.experience-section .progress-box .bar{
  border-radius: 0px;
  background: #ebebeb;
}

.experience-section .progress-box .bar-inner{
  border-radius: 0px;
}

.experience-section .form-inner .form-group{
  margin-bottom: 20px;
}

.experience-section .form-inner .form-group:last-child{
  margin-bottom: 0px;
}

.experience-section .form-inner .form-group input[type='text'],
.experience-section .form-inner .form-group input[type='email'],
.experience-section .form-inner .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  color: #808080;
  font-family: 'Poppins', sans-serif;
  transition: all 500ms ease;
}

.experience-section .form-inner .form-group textarea{
  height: 80px;
  resize: none;
  margin-bottom: 10px;
}

.team-details-three .image-box .image img{
  width: 100%;
  border-radius: 50%;
}

.team-details-three .image-box .social-links li{
  margin: 0px 6px;
}

.team-details-three .image-box .social-links li a{
  border: 1px solid #e6e6e6;
  color: #808080;
}

.team-details-three .image-box .social-links li a:hover{
  color: #fff;
}

.team-details-three .team-details-content .content-one .info li strong{
  width: 160px;
}

.team-details-three .team-details-content .content-one .info li a{
  color: #808080;
}

.team-details-three .image_block_one .image-box .skills-box{
  width: 100%;
  box-shadow: none;
}

.team-details-three .image_block_one .image-box{
  padding: 0px;
}


/** registration-section **/

.registration-section .content-box{
  max-width: 470px;
  margin: 0 auto;
  background: #fff;
}

.registration-section .default-form input[type='text'],
.registration-section .default-form input[type='email'],
.registration-section .default-form input[type='tel'],
.registration-section .default-form input[type='password'],
.registration-section .default-form textarea{
  border: 1px solid #e5e5e5;
}

.registration-section .default-form .form-group{
  margin-bottom: 17px;
}

.registration-section .default-form .form-group:last-child{
  margin-bottom: 0px;
}

.registration-section .default-form .form-group .lost-password a:hover{
  text-decoration: underline;
}

.registration-section .default-form .form-group .theme-btn{
  font-size: 16px;
  padding: 12px 32px;
}

.registration-section .text p a:hover{
  text-decoration: underline;
}

/** check-box **/

.check-box input {
  display: none;
}

.check-box{
  margin-bottom: 9px;
}

.check-box:last-child{
  margin-bottom: 0px;
}

.check-box label {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #141417;
  font-family: 'Poppins', sans-serif;
  padding-left: 27px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0px;
  transition: all 500ms ease;
}

.check-box input:checked + label{
  color: #1e2c36;
}

.check-box label:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 4px;
  width: 17px;
  height: 17px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e5e5;
  border-radius: 2px;
  background: #f7f7f7;
}

.check-box label:after {
  position: absolute;
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-size: 9px;
  left: 0px;
  top: 5px;
  width: 17px;
  height: 17px;
  line-height: 17px;
  color: #fff;
  opacity: 0;
  font-weight: 400;
  text-align: center;
}

.check-box input:checked + label:after {
  opacity: 1;
}

.registration-section .content-box .shape .shape-1{
  top: -55px;
  left: -110px;
  z-index: -1;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.registration-section .content-box .shape .shape-2{
  right: -200px;
  z-index: -1;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.registration-section .content-box .shape .shape-3{
  width: 87px;
  height: 87px;
  left: -123px;
  background-repeat: no-repeat;
  z-index: -1;
}

.registration-section .content-box .shape .shape-4{
  right: -57px;
  background-repeat: no-repeat;
  z-index: -1;
}

.check-box label a:hover{
  text-decoration: underline;
}


/** error-section **/

.error-section .inner-box .theme-btn{
  font-size: 16px;
}

.error-section .inner-box .theme-btn i{
  position: relative;
  font-size: 24px;
  margin-left: 0px;
  margin-right: 10px;
  top: 2px;
}


/** comingsoon-section **/

.comingsoon-section{
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 230px 0px 240px 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.comingsoon-section .content-box h2,
.comingsoon-section .content-box h3{
  color: #fff;
}

.comingsoon-section .cs-countdown .count-col{
  position: relative;
  display: inline-block;
  margin: 0px 13px;
}

.comingsoon-section .cs-countdown .count-col span{
  position: relative;
  display: block;
  font-size: 40px;
  text-align: center;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.07);
  font-weight: 800;
  color: #fff;
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin-bottom: 14px;
}

.comingsoon-section .cs-countdown .count-col h6{
  position: relative;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
}

.comingsoon-section .subscribe-inner{
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
}

.comingsoon-section .subscribe-inner .form-group input[type='email']{
  position: relative;
  display: block;
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 150px 15px 30px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  color: #808080;
}

.comingsoon-section .subscribe-inner .form-group button{
  position: absolute;
  top: 21px;
  right: 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #141417;
  cursor: pointer;
  transition: all 500ms ease;
}

.comingsoon-section .subscribe-inner .form-group button i{
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.comingsoon-section .subscribe-inner .form-group input:focus + button,
.comingsoon-section .subscribe-inner .form-group button:hover{

}


/** pricing-page **/

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .table-header h6{
  background: transparent;
  border: 1px solid #e5e5e5;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table{
  border: none;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .theme-btn{
  border: 1px solid #e7e7e7;
  color: #141417 !important;
  padding-top: 16px;
  padding-bottom: 16px;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .theme-btn:hover{
  color: #fff !important;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .theme-btn span:after{
  color: #f1592a;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .theme-btn:before{
  background: #f1592a;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .theme-btn:hover:after{
  border-right: 2px solid #f1592a !important;
  border-top: 2px solid #f1592a !important;
}

.pricing-one.pricing-page-1 .pricing-block-one.active-block .pricing-table .table-header h6{
  border-color: #4c4c50;
}

.pricing-one.pricing-page-1 .pricing-block-one.active-block .pricing-table .theme-btn span:after{
  color: #fff;
}

.pricing-one.pricing-page-1 .pricing-block-one.active-block .pricing-table .theme-btn:before{
  background: #fff;
}

.pricing-one.pricing-page-1 .pricing-block-one.active-block .pricing-table .theme-btn:hover:after{
  border-right: 2px solid #fff !important;
  border-top: 2px solid #fff !important;
}

.pricing-one.pricing-page-1 .pricing-block-one.active-block .pricing-table .theme-btn{
  color: #fff !important;
}

.header-shadow {
  box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 5%);
}

.faq-three.pricing-page-2 .shape .shape-1{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 5s infinite linear;
  animation: zoom-fade 5s infinite linear;
}

.faq-three.pricing-page-2 .shape .shape-2{
  width: 176px;
  height: 183px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}


/** contact-page **/

/** contactinfo-one **/

.info-block-one .inner-box .icon-box{
  background: #fdf3ee;
}

.info-block-one .inner-box:hover .icon-box{
  color: #fff;
}

.info-block-one .inner-box .icon-box:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0,0);
  transition: all 500ms ease;
}

.info-block-one .inner-box:hover .icon-box:before{
  transform: scale(1,1);
}

.info-block-one .inner-box p a{
  color: #808080;
}

.info-block-one .inner-box{
  background: #fff;
}

.contactinfo-one .bg-color{
  width: 100%;
  height: calc(100% - 130px);
}


/** contact-three **/

.contact-three #contact-google-map{
  width: 100%;
  height: 570px;
}

.contact-three #contact-form .form-group{
  position: relative;
  display: block;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.contact-three #contact-form .form-group input[type='text'],
.contact-three #contact-form .form-group input[type='email'],
.contact-three #contact-form .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  color: #808080;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

.contact-three #contact-form .form-group textarea{
  height: 120px;
  resize: none;
}

.contact-three #contact-form .form-group input:focus,
.contact-three #contact-form .form-group textarea:focus{

}

.contact-three .shape .shape-1{
  top: -50px;
  width: 563px;
  height: 533px;
  background-repeat: no-repeat;
  z-index: -1;
}

.contact-three .shape .shape-2{
  width: 593px;
  height: 563px;
  background-repeat: no-repeat;
}

/** google-map-section **/

.google-map-section #contact-google-map{
  width: 100%;
  height: 500px;
}

/** contact-four **/

.contact-four .info-list li{
  border-bottom: 1px solid #e5e5e5;
  min-height: 108px;
}

.contact-four .info-list li:last-child{
  border-bottom: none;
}

.contact-four .info-list li .icon-box{
  color: #fff;
}

.contact-four .info-list li .icon-box:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0,0);
  border-radius: 50%;
  z-index: -1;
  transition: all 500ms ease;
}

.contact-four .info-list li:hover .icon-box:before{
  transform: scale(1,1);
}

.contact-four .info-list li p a{
  color: #808080;
}

.contact-four #contact-form{
  margin: 0px 5px;
}

.contact-four #contact-form .form-group{
  padding: 0px 10px;
  margin-bottom: 20px;
}

.contact-four #contact-form .form-group input[type='text'],
.contact-four #contact-form .form-group input[type='email'],
.contact-four #contact-form .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  font-size: 16px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  color: #808080;
  border-radius: 5px;
  padding: 10px 20px;
}

.contact-four #contact-form .form-group textarea{
  height: 120px;
  resize: none;
}

.contact-four #contact-form .form-group input:focus,
.contact-four #contact-form .form-group textarea:focus{

}

.contact-four .shape .shape-1{
  width: 353px;
  height: 303px;
  background-repeat: no-repeat;
}

.contact-four .shape .shape-2{
  width: 593px;
  height: 563px;
  background-repeat: no-repeat;
}

.contact-four .form-inner{
  background: #fff;
}


/** contact-five **/

.contact-five #contact-form{
  margin: 0px 5px;
}

.contact-five #contact-form .form-group{
  padding: 0px 10px;
  margin-bottom: 20px;
}

.contact-five #contact-form .form-group input[type='text'],
.contact-five #contact-form .form-group input[type='email'],
.contact-five #contact-form .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  color: #808080;
  border-radius: 5px;
  padding: 10px 20px;
}

.contact-five #contact-form .form-group textarea{
  height: 120px;
  resize: none;
}

.contact-five #contact-form .form-group input:focus,
.contact-five #contact-form .form-group textarea:focus{

}

.contact-five #contact-google-map{
  width: 100%;
  height: 520px;
}

.contact-three .theme-btn,
.contact-four .theme-btn,
.contact-five .theme-btn,
.contact-six .theme-btn,
.contact-seven .theme-btn{
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  padding-top: 12px;
  padding-bottom: 12px;
}

.contact-five .shape .shape-1{
  width: 563px;
  height: 533px;
  background-repeat: no-repeat;
  z-index: -1;
}

.contact-five .shape .shape-2{
  width: 593px;
  height: 563px;
  background-repeat: no-repeat;
}

.contact-five .form-inner{
  background: #fff;
}


/** contactinfo-two **/

.info-block-two .inner-box{
  background: #fff;
}

.info-block-two .inner-box .icon-box{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #f3f3f3;
}

.info-block-two .inner-box:hover .icon-box{
  transform: translate(-50%,-50%) scale(1.1);
}

.info-block-two .inner-box .info li{
  color: #808080;
}

.info-block-two .inner-box .info li a{
  color: #808080;
}

.contactinfo-one.contact-page-4 .info-block-one .inner-box .icon-box{
  background: transparent;
}

.contactinfo-one.contact-page-4 .info-block-one .inner-box .icon-box:before{
  display: none;
}

.contactinfo-one.contact-page-4 .info-block-one .inner-box .icon-box .icon-shape{
  width: 108px;
  height: 84px;
  left: -14px;
  background-repeat: no-repeat;
}


/** contact-six **/

.contact-six .map-inner{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 100%;
}

.contact-six .map-inner #contact-google-map{
  width: 100%;
  height: 100%;
}

.contact-six .form-inner{
  background: #fff;
}

.contact-six #contact-form .form-group{
  margin-bottom: 20px;
}

.contact-six #contact-form .form-group:last-child{
  margin-bottom: 0px;
}

.contact-six #contact-form .form-group input[type='text'],
.contact-six #contact-form .form-group input[type='email'],
.contact-six #contact-form .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  color: #808080;
  padding: 10px 0px;
}

.contact-six #contact-form .form-group textarea{
  height: 120px;
  resize: none;
}

.contact-six #contact-form .form-group input:focus,
.contact-six #contact-form .form-group textarea:focus{

}

.contact-six .pattern-layer{
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


/** contact-seven **/

.contact-seven .info-list li p a{
  color: #141417;
}

.contact-seven .form-inner form{
  margin: 0px 5px;
}

.contact-seven #contact-form .form-group{
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-seven #contact-form .form-group:last-child{
  margin-bottom: 0px;
}

.contact-seven #contact-form .form-group input[type='text'],
.contact-seven #contact-form .form-group input[type='email'],
.contact-seven #contact-form .form-group textarea{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  color: #808080;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
}

.contact-seven #contact-form .form-group textarea{
  height: 120px;
  resize: none;
}

.contact-seven #contact-form .form-group input:focus,
.contact-seven #contact-form .form-group textarea:focus{

}


/************* project-page **************/

.project-two.project-page-1 .sortable-masonry .filter-tabs li{
  font-family: 'Poppins', sans-serif;
}


/** project-eight **/

.project-block-seven .inner-box .image-box{
  overflow: hidden;
}

.project-block-seven .inner-box .image-box .image{
  overflow: hidden;
  border-radius: 5px;
}

.project-block-seven .inner-box .image-box .image img{
  width: 100%;
  border-radius: 5px;
}

.project-block-seven .inner-box .image-box:before{
  position: absolute;
  content: '';
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  left: 30px;
  top: 30px;
  border-radius: 5px;
  transform: scale(0,0);
  opacity: 0.95;
  z-index: 1;
  transition: all 500ms ease;
}

.project-block-seven .inner-box:hover .image-box:before{
  transform: scale(1,1);
}

.project-block-seven .inner-box .image-box .links{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0,0);
}

.project-block-seven .inner-box:hover .image-box .links{
  transform: translate(-50%,-50%) scale(1,1);
}

.project-block-seven .inner-box .image-box .links li{
  margin: 0px 3.5px;
}

.project-block-seven .inner-box .image-box .links li a{
  border: 2px solid #fff;
  color: #fff;
}

.project-block-seven .inner-box .image-box .links li a:hover{
  background: #fff;
}

.project-block-seven .inner-box .content-box h4 a:hover{

}

.project-eight .masonry-item{
  padding: 0px 35px;
}

.project-eight .sortable-masonry{
  margin: 0px -20px;
}

.project-eight .sortable-masonry .filter-tabs li{
  border: 1px solid #e5e5e5;
  border-radius: 25px;
  padding: 8px 29px 6px 29px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin: 0px 3.5px;
}

.project-eight .sortable-masonry .filter-tabs li:before{
  display: none;
}

.project-eight .sortable-masonry .filter-tabs li.active,
.project-eight .sortable-masonry .filter-tabs li:hover{
  color: #fff;
}

.project-eight .sortable-masonry .filter-tabs li:after{
  position: absolute;
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 25px;
  z-index: -1;
  transform: scale(0,0);
  transition: all 500ms ease;
}

.project-eight .sortable-masonry .filter-tabs li.active:after,
.project-eight .sortable-masonry .filter-tabs li:hover:after{
  transform: scale(1,1);
}


/** project-nine **/

.project-nine .masonry-item{
  padding: 0px 25px;
}

.project-nine .sortable-masonry .filter-tabs li{
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 8px 29px 6px 29px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  margin: 0px 3.5px;
}

.project-nine .sortable-masonry .filter-tabs li:before{
  display: none;
}

.project-nine .sortable-masonry .filter-tabs li.active,
.project-nine .sortable-masonry .filter-tabs li:hover{
  color: #fff;
}

.project-nine .sortable-masonry .filter-tabs li:after{
  position: absolute;
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 5px;
  z-index: -1;
  transform: scale(0,0);
  transition: all 500ms ease;
}

.project-nine .sortable-masonry .filter-tabs li.active:after,
.project-nine .sortable-masonry .filter-tabs li:hover:after{
  transform: scale(1,1);
}

.project-two.project-page-4 .sortable-masonry .filter-tabs li{
  padding-left: 19px;
  padding-right: 19px;
  font-family: 'Poppins', sans-serif;
  margin: 0px 0px;
}

.project-two.project-page-5 .masonry-item{
  padding: 0px 35px;
}

.project-two.project-page-5 .sortable-masonry{
  margin: 0px -20px;
}

.project-block-seven .inner-box .image-box .image{
  background: #000;
}

.project-block-seven .inner-box:hover .image-box .image img{
  opacity: 0.7;
  transform: scale(1.05);
}

.project-two.project-page-5 .project-block-seven .inner-box .image-box:before{
  opacity: 0.8;
}

.project-two.project-page-5 .sortable-masonry .filter-tabs li{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.project-two.project-page-5 .sortable-masonry .filter-tabs li:before{
  display: none;
}


/** project-details **/

.project-details .project-info .info-list li span{
  width: 120px;
}

.project-details .project-info .info-list li a{
  color: #808080;
}

.project-details .project-info .info-list li.social-links a{
  margin-right: 23px;
  font-size: 15px;
  color: #bebebe;
}

.project-details .project-info .info-list li.social-links a:last-child{
  margin-right: 0px;
}

.project-details .project-info .info{
  border-left: 1px solid #dde2e1;
}

.project-details .owl-carousel {
  max-width: 970px;
  margin: 0 auto;
  width: 100%;
  display: block;
}

.project-details .owl-stage-outer{
  overflow:visible;
}

.project-details .carousel-outer .image-box{
  background: #000;
  overflow: hidden;
}

.project-details .carousel-outer .image-box img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.project-details .carousel-outer .image-box:hover img{
  opacity: 0.5;
  transform: scale(1.1);
}

.project-details .carousel-outer .owl-nav{
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 100%;
}

.project-details .carousel-outer .owl-nav button{
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: #141417;
  transition: all 500ms ease;
}

.project-details .carousel-outer .owl-nav button:hover{
  color: #fff;
}

.project-details .carousel-outer .owl-nav button.owl-prev{
  left: -115px;
}

.project-details .carousel-outer .owl-nav button.owl-next{
  right: -115px;
}

.project-details .nav-btn .single-btn a i{
  font-size: 20px;
  position: relative;
  top: 2px;
}

.project-details .nav-btn .single-btn.prev-btn a i{
  margin-right: 10px;
}

.project-details .nav-btn .single-btn.next-btn a i{
  margin-left: 10px;
}

.project-details .image-box .image{
  overflow: hidden;
  background: #000;
}

.project-details .image-box .image img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.project-details .image-box .image:hover img{
  opacity: 0.5;
  transform: scale(1.1);
}

.project-details-3 .project-info .info{
  border-left: none;
}

.project-details-4 .porject-info{
  max-width: 760px;
  margin: 0 auto;
}

.project-details-4 .porject-info .info-list .single-item p a{
  color: #808080;
}

.project-details-4 .porject-info .info-list .single-item:before{
  position: absolute;
  content: '';
  background: #e5e5e5;
  width: 1px;
  height: 50px;
  top: 0px;
  right: -40px;
}

.project-details-4 .porject-info .info-list .single-column:last-child .single-item:before{
  display: none;
}

.project-details .client-thumb-outer{
  position:absolute;
  max-width:1200px;
  margin:0 auto;
  overflow:hidden;
  left: 0px;
  bottom: 20px;
  right: 0px;
  width: 100%;
}

.project-details .client-thumb-outer .client-thumbs-carousel{
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.project-details .client-thumb-outer .client-thumbs-carousel{
  overflow:visible;
  max-width: 218px;
  margin: 0 auto;
}

.project-details.project-details-5 .client-project-carousel{
  max-width: 1780px;
  overflow: hidden;
}

.project-details .client-thumb-outer .client-thumbs-carousel .owl-stage-outer{
  overflow: visible;
}

.project-details .client-thumb-outer .owl-stage-outer{
  overflow: hidden;
}

.project-details.project-details-5 .image-box .image img{
  border-radius: 0px;
  opacity: 0.7;
}

.project-details-5 .owl-item .thumb-item .thumb-box:before{
  position: absolute;
  content: '';
  border: 4px solid #fff;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  transition: all 500ms ease;
}

.project-details-5 .owl-item.active .thumb-item .thumb-box:before{
  opacity: 1;
}

.owl-carousel .feature-block-ten .inner-box .image-box img{
  display: inline-block;
}

.nice-select .list{
  z-index: 99;
}






/***************** new-fixing ********************/


/** banner-five **/

.banner-five{
  padding: 66px 0px 290px 0px;
  background: -webkit-linear-gradient(0deg, #237ecd, #051b88 100%);
}

.banner-five .content-box h2,
.banner-five .content-box p{
  color: #fff;
}

.banner-five .shape .shape-1{
  top: -60px;
  left: 200px;
  width: 350px;
  height: 350px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: zoom-fade-two 10s infinite linear;
  animation: zoom-fade-two 10s infinite linear;
}

.banner-five .shape .shape-2{
  top: -135px;
  right: 0px;
  width: 520px;
  height: 520px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: zoom-fade 10s infinite linear;
  animation: zoom-fade 10s infinite linear;
}

.banner-five .shape .shape-3{
  top: -400px;
  right: 240px;
  width: 520px;
  height: 520px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-animation: zoom-fade-two 8s infinite linear;
  animation: zoom-fade-two 8s infinite linear;
}

.banner-five .pattern-layer{
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background-size: 100% 100%;
  background-position: top center;
  z-index: 2;
}

.banner-five .image-box{
  margin-right: -75px;
}

.banner-five .image-box img{
  width: 100%;
}

.main-header.header-style-14{
  position: absolute;
}

.main-header.header-style-14 .header-lower .outer-container{
  padding: 0px;
}

.banner-four .image-box{
  top: 18px;
}

.main-header.about-page-5{
  position: relative;
}

.main-header.about-page-5.header-style-two.header-style-14 .nav-right .search-box-outer,
.main-header.about-page-5.header-style-two.header-style-14 .nav-right .nav-btn{
  color: #141417 !important;
}

.main-header.about-page-5.header-style-two.header-style-14 .nav-right .search-box-outer:hover,
.main-header.about-page-5.header-style-two.header-style-14 .nav-right .nav-btn:hover{
  color: #a44ac5 !important;
}

.main-header.header-style-two.header-style-14 .sticky-header .nav-right .search-box-outer,
.main-header.header-style-two.header-style-14 .sticky-header .nav-right .nav-btn{
  color: #141417 !important;
}

.main-header.team-details-1.header-style-two.header-style-14 .nav-right .search-box-outer,
.main-header.team-details-1.header-style-two.header-style-14 .nav-right .nav-btn,
.main-header.project-page-2.header-style-two.header-style-14 .nav-right .search-box-outer,
.main-header.project-page-2.header-style-two.header-style-14 .nav-right .nav-btn{
  color: #141417 !important;
}

.main-header.team-details-1.header-style-two.header-style-14,
.main-header.project-page-2{
  position: relative;
}

.main-header.team-details-1.header-style-two.header-style-14 .nav-right .search-box-outer .search-box-btn:hover,
.main-header.team-details-1.header-style-two.header-style-14 .nav-right .nav-btn:hover,
.main-header.project-page-2.header-style-two.header-style-14 .nav-right .search-box-outer .search-box-btn:hover,
.main-header.project-page-2.header-style-two.header-style-14 .nav-right .nav-btn:hover{
  color: #a44ac5 !important;
}

.content_block_29 .content-box {
  padding-left: 400px;
  background: #fff;
}

.content_block_29 .content-box .image-box{
  width: 300px;
  height: 400px;
  overflow: hidden;
}

.content_block_29 .content-box .image-box:before{
  position: absolute;
  top: 0;
  left: -80%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.content_block_29 .content-box:hover .image-box:before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.content_block_29 .content-box .image-box img{
  width: 100%;
  border-radius: 10px;
}

.content_block_29 .content-box .list li:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 8px;
  width: 12px;
  height: 12px;
  border: 2px solid #141417;
  border-radius: 50%;
}

.content_block_29 .content-box .theme-btn{
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #e7e7e7;
  color: #141417 !important;
}

.slider-four.home-6 .banner-carousel .owl-nav{
  top: 35%;
}

.subscribe-one .form-inner .form-group button{
  padding: 12px 40px;
}



/***

====================================================================
    Search Popup
====================================================================

***/

.search-popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.80);
    padding-bottom: 200px;
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}

.search-popup .popup-inner{
  width: 100%;
  background: #fff;
}

.search-popup .upper-box{
  position: relative;
  padding: 70px 70px;
}

.search-popup .overlay-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}

.search-popup .close-search {
    position: relative;
    font-size: 22px;
    color: #141417;
    cursor: pointer;
    z-index: 5;
    top: 11px;
    transition: all 500ms ease;
}

.search-popup .close-search:hover {
    color: red;
}

.search-popup .search-form {
    position: relative;
    width: 100%;
    padding: 100px 0px 250px 0px;
}

.search-popup .search-form .form-group{
  position: relative;
  margin: 0px;
}

.search-popup .search-form fieldset input[type="search"] {
    position: relative;
    height: 90px;
    padding: 20px 0px;
    background: #ffffff;
    line-height: 30px;
    font-size: 24px;
    color: #808080;
    font-family: 'Inter', sans-serif;
    border: none;
    border-radius: 0px;
    padding-right: 50px;
    border-bottom: 1px solid #e5e5e5;
}

.search-popup .search-form fieldset button[type="submit"] {
    position: absolute;
    top: 30px;
    right: 0px;
    font-size: 22px;
    color: #141417;
    cursor: pointer;
    transition: all 500ms ease;
}

.search-popup .search-form fieldset input[type="search"]:focus{
  border-color: #141417;
}

.search-popup .form-control:focus{
  box-shadow: none !important;
}

.main-header .search-box-outer{
  cursor: pointer;
}

.parallax_none{
  transform: translate(0px);
}

.video-one{
  overflow: hidden;
}

.cta-one{
  overflow: hidden;
}

.cta-two,
.cta-three,
.cta-eight,
.page-title{
  overflow: hidden;
}

.cta-two:before,
.cta-three:before,
.page-title:before{
  z-index: 1;
}

.page-title .content-box{
  position: relative;
  z-index: 1;
  width: 41%;
}
.page-title .content-box .yellow{
  display: inline-block;
  background: #f79623;
  min-height: 40px;
  padding: 0 45px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}
.page-title .content-box .green{
  display: inline-block;
  background: #4ec49b;
  min-height: 40px;
  padding: 0 45px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.cta-two .pattern-layer .pattern-1,
.cta-two .pattern-layer .pattern-2{
  z-index: 1;
}

.parallax-bg{
  top: -30%;
  width: 100%;
  height: 150%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: fixed;
}
.parallax-bg iframe{
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  inset: 0;
}

.page-title.style-two{
  overflow: visible;
}

.cta-eight .pattern-layer .pattern-1{
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.cta-eight .pattern-layer .pattern-2{
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/** text-overlay-animation **/

.overlay-anim-black-bg,
.overlay-anim-red-bg,
.overlay-anim-white-bg {
  display: inline-block;
  overflow: hidden;
}

.overlay-anim-black-bg:after,
.overlay-anim-red-bg:after,
.overlay-anim-white-bg:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: 1s cubic-bezier(.858, .01, .068, .99);
  -o-transition: 1s cubic-bezier(.858, .01, .068, .99);
  transition: 1s cubic-bezier(.858, .01, .068, .99);
  z-index: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.overlay-anim-black-bg:after{
  background: #222;
}

.overlay-anim-white-bg:after{
  background: #fff;
}

.overlay-anim-black-bg.overlay-animation:after,
.overlay-anim-red-bg.overlay-animation:after,
.overlay-anim-white-bg.overlay-animation:after {
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
}


/* slider text animation */

.slider-text-anim {
  display: inline-block;
  overflow: hidden;
  position: relative;
  padding-bottom: 5px;
}

.slider-text-anim:before {
  content: '';
  width: 101%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  background: #fff;
  -webkit-transition: 1s cubic-bezier(.858, .01, .068, .99);
  -o-transition: 1s cubic-bezier(.858, .01, .068, .99);
  transition: 1s cubic-bezier(.858, .01, .068, .99);
  z-index: 3;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.slider-text-anim.black-bg:before{
  background: #222;
}

.active .slider-text-anim:before {
  -webkit-transform: translateX(1%);
  -ms-transform: translateX(1%);
  transform: translateX(1%);
}



/** custome-mouse-pointer **/


.mouse-pointer {
  position: fixed;
  top: 50%;
  left: -100px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9999;
  -webkit-transition-property: width, height, background;
  -o-transition-property: width, height, background;
  transition-property: width, height, background;
  -webkit-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-timing-function: cubic-bezier(.19, .94, .336, 1);
  -o-transition-timing-function: cubic-bezier(.19, .94, .336, 1);
  transition-timing-function: cubic-bezier(.19, .94, .336, 1);
  border-radius: 50%;
  background: #f4244f;
  overflow: hidden;
}

.mouse-pointer.display_none{
  display: none;
}

body.out .mouse-pointer {
  width: 0;
  height: 0;
}

.mouse-pointer.large {
  width: 65px;
  height: 65px;
  background: rgba(224,36,79,.0) !important;
  -webkit-box-shadow: 0 0 30px rgba(224,36,79, 0.4);
  box-shadow: 0 0 30px rgba(224,36,79, 0.4);
}

.mouse-pointer.style-two{
  background: #41a0ea;
}

.mouse-pointer.style-two.large {
  -webkit-box-shadow: 0 0 30px rgba(65,160,234, 0.4);
  box-shadow: 0 0 30px rgba(65,160,234, 0.4);
}

.mouse-pointer.style-three{
  background: #6a36ff;
}

.mouse-pointer.style-three.large {
  -webkit-box-shadow: 0 0 30px rgba(106,54,255, 0.4);
  box-shadow: 0 0 30px rgba(106,54,255, 0.4);
}

.mouse-pointer.style-four{
  background: #bc8664;
}

.mouse-pointer.style-four.large {
  -webkit-box-shadow: 0 0 30px rgba(188,134,100, 0.4);
  box-shadow: 0 0 30px rgba(188,134,100, 0.4);
}

.mouse-pointer.style-five{
  background: #f1592a;
}

.mouse-pointer.style-five.large {
  -webkit-box-shadow: 0 0 30px rgba(241,89,42, 0.4);
  box-shadow: 0 0 30px rgba(241,89,42, 0.4);
}

.mouse-pointer.style-six{
  background: #e93421;
}

.mouse-pointer.style-six.large {
  -webkit-box-shadow: 0 0 30px rgba(233,52,33, 0.4);
  box-shadow: 0 0 30px rgba(233,52,33, 0.4);
}

.mouse-pointer.style-seven{
  background: #f38836;
}

.mouse-pointer.style-seven.large {
  -webkit-box-shadow: 0 0 30px rgba(243,136,54, 0.4);
  box-shadow: 0 0 30px rgba(243,136,54, 0.4);
}

.mouse-pointer.style-eight{
  background: #ff7f4c;
}

.mouse-pointer.style-eight.large {
  -webkit-box-shadow: 0 0 30px rgba(255,127,76, 0.4);
  box-shadow: 0 0 30px rgba(255,127,76, 0.4);
}

.mouse-pointer.style-nine{
  background: #ae8c64;
}

.mouse-pointer.style-nine.large {
  -webkit-box-shadow: 0 0 30px rgba(174,140,100, 0.4);
  box-shadow: 0 0 30px rgba(174,140,100, 0.4);
}

.mouse-pointer.style-ten{
  background: #ff5e14;
}

.mouse-pointer.style-ten.large {
  -webkit-box-shadow: 0 0 30px rgba(255,94,20, 0.4);
  box-shadow: 0 0 30px rgba(255,94,20, 0.4);
}

.mouse-pointer.style-11{
  background: #fb4754;
}

.mouse-pointer.style-11.large {
  -webkit-box-shadow: 0 0 30px rgba(251,71,84, 0.4);
  box-shadow: 0 0 30px rgba(251,71,84, 0.4);
}

.mouse-pointer.style-12{
  background: #00d0f5;
}

.mouse-pointer.style-12.large {
  -webkit-box-shadow: 0 0 30px rgba(0,208,245, 0.4);
  box-shadow: 0 0 30px rgba(0,208,245, 0.4);
}

.mouse-pointer.style-13{
  background: #a44ac5;
}

.mouse-pointer.style-13.large {
  -webkit-box-shadow: 0 0 30px rgba(164,74,197, 0.4);
  box-shadow: 0 0 30px rgba(164,74,197, 0.4);
}

.mouse-pointer.style-14{
  background: #17be74;
}

.mouse-pointer.style-14.large {
  -webkit-box-shadow: 0 0 30px rgba(23,190,116, 0.4);
  box-shadow: 0 0 30px rgba(23,190,116, 0.4);
}

.mouse-pointer.small {
  width: 25px;
  height: 25px;
  background: rgba(227,87,18,0);
  -webkit-box-shadow: 0 0 30px #e35712;
  box-shadow: 0 0 30px #e35712;
}

.mouse-pointer.right {
  width: 50px;
  height: 50px;
  background: #000 !important;
}

.mouse-pointer.right.large .icon{
  opacity: 0;
}

.mouse-pointer.right.large{
  background: transparent !important;
}

.mouse-pointer.zoom,
.mouse-pointer.open {
  width: 80px;
  height: 80px;
  background: rgba(227,87,18,0);
  border: 2px solid #e35712;
}

.mouse-pointer .icon{
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-100%, -50%);
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  opacity: 0;
  -webkit-transition: .4s cubic-bezier(.225, 1, .316, .99);
  -o-transition: .4s cubic-bezier(.225, 1, .316, .99);
  transition: .4s cubic-bezier(.225, 1, .316, .99);
}

.mouse-pointer .icon i{
  margin: 0px 3px;
}

.mouse-pointer.right .icon {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.mouse-pointer.black {
  background: #000;
}

.mouse-pointer.black.large {
  background: rgba(0,0,0,.4);
  -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.2);
  box-shadow: 0 0 30px rgba(0,0,0,0.2);
}

.mouse-pointer.black.small {
  background: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.2);
  box-shadow: 0 0 30px rgba(0,0,0,0.2);
}

.mouse-pointer.black.right {
  background: rgba(0,0,0,0);
  border: 2px solid #000;
}

.mouse-pointer.black.zoom,
.mouse-pointer.black.open {
  background: rgba(0,0,0,0);
  border: 2px solid #000;
}

.touch .mouse-pointer {
  display: none;
}


/** hero-shape **/

.hero-shape{
  will-change: border-radius, transform, opacity;
  animation: heroShape 3s linear infinite;
  display: block;
}

.hero-shape-two{
  position: absolute;
  left: 0px;
  bottom: 0px;
  will-change: border-radius, transform, opacity;
  animation: heroShapeTwo 5s linear infinite;
  display: block;
}

.hero-shape-three{
  background: #fff;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 55% 50% 48% 30% / 40% 50% 70% 6%;
  will-change: border-radius, transform, opacity;
  animation: heroShapeThree 3s linear infinite;
  display: block;
  opacity: 0.10;
}

.hero-shape-four{
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 55% 35% 48% 30% / 40% 40% 70% 6%;
  will-change: border-radius, transform, opacity;
  animation: heroShapeFour 3s linear infinite;
  display: block;
}


@keyframes heroShape {
    0%,
    100% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    25% {
        transform: translate3d(-20px, 5px, 0) rotateZ(0.01deg);
    }
    35% {
        transform: translate3d(-20px, 5px, 0) rotateZ(0.01deg);
    }
    45% {
        transform: translate3d(-20px, 10px, 0) rotateZ(0.01deg);
    }
    55% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    75% {
        transform: translate3d(0, -5px, 0) rotateZ(0.01deg);
    }
}

@keyframes heroShapeTwo {
   0%,
    100% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    25% {
        transform: translate3d(-15px, 5px, 0) rotateZ(0.01deg);
    }
    35% {
        transform: translate3d(-30px, 5px, 0) rotateZ(0.01deg);
    }
    45% {
        transform: translate3d(-15px, 5px, 0) rotateZ(0.01deg);
    }
    55% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    75% {
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}

@keyframes heroShapeThree {
    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    25% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(-20px, 5px, 0) rotateZ(0.01deg);
    }
    35% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(-20px, 5px, 0) rotateZ(0.01deg);
    }
    45% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(-20px, 10px, 0) rotateZ(0.01deg);
    }
    55% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    75% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -5px, 0) rotateZ(0.01deg);
    }
}

@keyframes heroShapeFour {
    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    35% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 10px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    65% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -10px, 0) rotateZ(0.01deg);
    }
}

.pricing-block-one .theme-btn-two{
  border: 1px solid #e7e7e7;
  padding-top: 11px;
  padding-bottom: 11px;
}

.cta-two .theme-btn-two span:after{
  color: #41a0ea;
}

.news-two .theme-btn-two span:after{
  color: #41a0ea;
}

/** wave-animation **/

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}

.pricing-two .pricing-block-one.active-block .theme-btn.theme-btn-five:hover{
  color: #1e70b3 !important;
}

.pricing-two .pricing-block-one.active-block .theme-btn.theme-btn-five:before{
  background: #fff;
}

.header-style-five .theme-btn{
  border: 1px solid #e7e7e7;
  color: #141417 !important;
  padding: 11px 32px;
}

.header-style-five .theme-btn:hover{
  border-color: #bc8664;
  color: #bc8664 !important;
}

.banner-two .theme-btn-two span:after{
  color: #bc8664;
}

.banner-two .theme-btn-two{
  font-family: 'Oxygen', sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding-top: 17px;
  padding-bottom: 17px;
}

.pricing-three .theme-btn-six:before,
.pricing-three .pricing-block-one.active-block .theme-btn-six{
  background: #bc8664;
}

.pricing-three .theme-btn-six:hover,
.pricing-three .pricing-block-one.active-block .theme-btn-six{
  border-color: #bc8664;
}

.pricing-three .pricing-block-one.active-block .theme-btn-six{
  color: #fff !important;
}

.pricing-three .pricing-block-one.active-block .theme-btn-six:before{
  background: #fff;
}

.pricing-three .pricing-block-one.active-block .theme-btn-six:hover{
  color: #bc8664 !important;
}

.contact-two.home-5 .theme-btn-five{
  padding: 16px 75px;
}

.news-five .theme-btn-six:before{
  background: #bc8664;
}

.news-five .theme-btn-six:hover{
  border-color: #bc8664;
}

.news-five .theme-btn-six{
  padding: 16px 32px;
}

.service-six .theme-btn-seven,
.support-area .theme-btn-seven{
  padding: 17px 47px;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
}

.cta-four .theme-btn:before{
  background: #fff;
}

.cta-four .theme-btn:hover{
  color: #f1592a !important;
}

.news-six .theme-btn{
  background: transparent;
  color: #141417 !important;
  padding: 11px 42px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #e7e7e7;
}

.news-six .theme-btn:hover{
  color: #fff !important;
  border-color: #f1592a;
}

.news-six .theme-btn:before{
  background: #f1592a;
}

.header-style-seven .theme-btn{
  border-radius: 0px;
  background: #e93421;
  padding: 15px 40px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
}

.header-style-seven .theme-btn:hover{
  color: #e93421 !important;
}

.header-style-seven .theme-btn:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 300ms ease;
}

.header-style-seven .theme-btn:hover:before{
  width: 100%;
}

.slider-five .theme-btn-two{
  border-radius: 0px;
  background: #e93421;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
}

.slider-five .theme-btn-two span:before,
.slider-five .theme-btn-two span:after{
  color: #fff;
}

.slider-five .theme-btn-two:before{
  background: #fff;
}

.slider-five .theme-btn-two:after{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

.service-seven .theme-btn-two{
  border-radius: 0px;
  background: #e93421;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
}

.service-seven .theme-btn-two span:before,
.service-seven .theme-btn-two span:after{
  color: #fff;
}

.service-seven .theme-btn-two:before{
  background: #fff;
}

.service-seven .theme-btn-two:after{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

.process-three .pattern-1{
  width: 180px;
  height: 85px;
  background: #27272b;
  opacity: 1;
  z-index: 1;
}

.process-three .pattern-2{
  width: 250px;
  height: 120px;
  background: #27272b;
  opacity: 1;
  z-index: 1;
}

.clients-one.home-7{
  overflow: hidden;
}

.clients-one .pattern-3{
  width: 250px;
  height: 120px;
  left: 340px;
  background: rgba(255, 255, 255, 0.10);
  opacity: 1;
  z-index: 1;
}

.clients-one .pattern-4{
  width: 250px;
  height: 120px;
  right: 340px;
  background: rgba(255, 255, 255, 0.10);
  opacity: 1;
  z-index: 1;
}

.appointment-one.home-7 .theme-btn{
  border-radius: 0px;
  background: #e93421;
  padding: 15px 40px;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
}

.appointment-one.home-7 .theme-btn:hover{
  color: #e93421 !important;
}

.appointment-one.home-7 .theme-btn:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.appointment-one.home-7 .theme-btn:hover:before{
  width: 100%;
}

.footer-seven .theme-btn{
  border-radius: 0px;
  background: #e93421;
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
}

.footer-seven .theme-btn:hover{
  color: #e93421 !important;
}

.footer-seven .theme-btn:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 300ms ease;
}

.footer-seven .theme-btn:hover:before{
  width: 100%;
}

.slider-six .waves{
  z-index: 1;
}

.header-style-eight .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  background: #f38836;
}

.header-style-eight .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #232327;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.header-style-eight .theme-btn:hover:before{
  bottom: -40%;
}

.slider-six .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  background: #fff;
  color: #141417 !important;
  padding: 22px 53px;
}

.slider-six .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #232327;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.slider-six .theme-btn:hover:before{
  bottom: -40%;
}

.slider-six .theme-btn:hover{
  color: #fff !important;
}

.feature-six .feature-block-six .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 33px;
  font-family: 'Poppins', sans-serif;
  border: 2px solid #fbdbc2;
  color: #f38836 !important;
}

.feature-six .feature-block-six .theme-btn:hover {
  border-color: #f38836;
  color: #f38836 !important;
}

.feature-six .feature-block-six .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #232327;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.feature-six .feature-block-six .theme-btn:hover:before{
  bottom: -40%;
}

.service-eight .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 12px 43px;
  background: #f38836;
}

.service-eight .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #232327;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.service-eight .theme-btn:hover:before{
  bottom: -40%;
}

.project-three.home-8 .project-block-three .inner-box .view-btn a:hover{
  color: #f38836;
}


.news-eight .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  padding: 11px 32px;
  font-family: 'Poppins', sans-serif;
  border: 2px solid #fbdbc2;
  color: #f38836 !important;
}

.news-eight .theme-btn:hover {
  border-color: #f38836;
  color: #f38836 !important;
}

.news-eight .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #232327;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.news-eight .theme-btn:hover:before{
  bottom: -40%;
}

.appointment-two.home-8 .theme-btn{
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  background: #f38836;
}

.appointment-two.home-8 .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #232327;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.appointment-two.home-8 .theme-btn:hover:before{
  bottom: -40%;
}

.slider-seven .banner-carousel .owl-nav{
  top: 52%;
}

.slider-seven .theme-btn-eight{
  padding: 18px 39px 16px 39px;
}

.service-nine .theme-btn {
  padding: 18px 40px 16px 40px;
}

.pricing-five .theme-btn-two{
  padding: 11px 26px;
}

.pricing-five .theme-btn-two:after,
.pricing-five .theme-btn-two:before{
  display: none;
}

.pricing-five .theme-btn-two:hover{
  border-color: #ff7f4c;
  color: #ff7f4c !important;
}

.news-nine .theme-btn-eight{
  background: transparent;
  border: 1px solid #e7e7e7;
  color: #141417 !important;
  padding: 11px 32px;
}

.news-nine .theme-btn-eight:hover{
  border-color: #ff7f4c;
  color: #ff7f4c !important;
}

.slider-eight .theme-btn-two{
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
  font-size: 15px;
  border-radius: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  background: #ae8c64;
}

.slider-eight .theme-btn-two span:before,
.slider-eight .theme-btn-two span:after{
  color: #fff;
}

.slider-eight .theme-btn-two:before{
  background: #fff;
}

.slider-eight .theme-btn-two:after{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

.feture-black-eight .inner-box .link a:hover{
  letter-spacing: 0.5px;
}

.news-ten .news-block-one .inner-box:hover{
  transform: translateY(-10px);
}

.news-ten .news-block-one .theme-btn{
  border: 1px solid #e7e7e7;
  color: #141417 !important;
  padding: 11px 32px;
}

.news-ten .news-block-one .theme-btn:before{
  position: absolute;
  content: '';
  background: #ae8c64;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.news-ten .news-block-one .theme-btn:hover:before{
  width: 100%;
}

.news-ten .news-block-one .theme-btn:hover{
  border-color: #ae8c64;
  color: #fff !important;
}

.service-ten .more-btn .theme-btn-two{
  font-weight: 700;
  font-family: 'Oxygen', sans-serif;
  font-size: 15px;
  border-radius: 0px;
  padding-top: 17px;
  padding-bottom: 17px;
  background: #ae8c64;
}

.service-ten .more-btn .theme-btn-two span:before,
.service-ten .more-btn .theme-btn-two span:after{
  color: #fff;
}

.service-ten .more-btn .theme-btn-two:before{
  background: #fff;
}

.service-ten .more-btn .theme-btn-two:after{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

.header-style-11 .theme-btn{
  background: #ff5e14;
  padding: 12px 35px;
  font-weight: 600;
  font-family: 'Jost', sans-serif;
  font-size: 17px;
}

.slider-nine .theme-btn-eight{
  font-weight: 600;
  font-family: 'Jost', sans-serif;
  font-size: 17px;
  border-radius: 0px;
  margin-right: 17px;
  padding: 17px 40px;
  background: #ff5e14;
}

.feature-block-nine .inner-box .icon-box{
  transition: all 500ms ease;
}

.feature-block-nine .inner-box:hover .icon-box{
  transform: rotateY(180deg);
}

.project-three.home-11 .project-block-three .inner-box .view-btn a:hover{
  color: #ff5e14;
}

.news-11 .theme-btn{
  padding: 16px 39px;
  border: 1px solid #e7e7e7;
}

.news-11 .theme-btn:hover{
  border-color: #ff5e14;
}

.banner-three .theme-btn{
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  padding: 17px 40px;
  background: #fb4754;
}

.about-12 .content_block_nine .content-box .theme-btn{
  padding-top: 17px;
  padding-bottom: 17px;
  font-weight: 600;
  font-family: 'Jost', sans-serif;
  font-size: 17px;
  background: #fb4754;
}

.about-12 .content_block_nine .content-box .theme-btn span:before,
.about-12 .content_block_nine .content-box .theme-btn span:after{
  color: #fff;
}

.about-12 .content_block_nine .content-box .theme-btn:before{
  background: #fff;
}

.about-12 .content_block_nine .content-box .theme-btn:after{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

.subscribe-five .theme-btn{
  background: #fb4754;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  padding: 17px 32px;
}

.banner-five .theme-btn,
.about-13 .theme-btn{
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  background: #00d0f5;
  padding-top: 17px;
  padding-bottom: 17px;
}

.banner-five .theme-btn span:before,
.banner-five .theme-btn span:after,
.about-13 .theme-btn span:before,
.about-13 .theme-btn span:after{
  color: #fff;
}

.banner-five .theme-btn:before,
.about-13 .theme-btn:before{
  background: #fff;
}

.banner-five .theme-btn:after,
.about-13 .theme-btn:after{
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
}

.pricing-one.home-13 .theme-btn-six{
  border-radius: 5px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding: 16px 30px;
}

.pricing-one.home-13 .theme-btn-six:hover{
  color: #fff !important;
}

.pricing-one.home-13 .pricing-block-two.active-block .pricing-table .theme-btn:before{
  background: #141417;
}

.pricing-one.home-13 .pricing-block-two.active-block .pricing-table .theme-btn:hover{
  border-color: #141417;
}

.cta-seven .theme-btn{
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  padding: 17px 40px;
  background: #00d0f5;
}

.news-14 .news-block-one .inner-box:hover{
  box-shadow: 0 0 60px rgb(0 0 0 / 20%);
}

.subscribe-one.home-14 .form-inner .form-group button:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  background: -webkit-linear-gradient(0deg, #ad4bc6 0%, #6d42c3 100%);
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.subscribe-one.home-14 .form-inner .form-group button:hover:before{
  width: 100%;
}

.header-style-15 .theme-btn{
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 11px 32px;
}

.header-style-15 .theme-btn:hover{
  border-color: #17be74;
}

.header-style-15 .theme-btn:before{
  background: #17be74;
}

.slider-11 .theme-btn,
.about-15 .theme-btn,
.cta-eight .theme-btn{
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  padding: 17px 32px;
  background: #17be74;
}

.slider-11 .theme-btn:hover,
.cta-eight .theme-btn:hover{
  background: #141417;
}

.service-block-12 .inner-box .lower-content .link a:hover{
  letter-spacing: 0.5px;
}

.service-15 .more-btn .theme-btn{
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.08);
  background: #fff;
  color: #141417 !important;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  border-radius: 30px;
  padding: 17px 40px;
}

.service-15 .more-btn .theme-btn:hover{
  color: #fff !important;
}

.team-seven .team-block-one .inner-box,
.news-15 .news-block-one .inner-box{
  transition: all 500ms ease;
}

.team-seven .team-block-one .inner-box:hover,
.news-15 .news-block-one .inner-box:hover{
  box-shadow: 0 0 50px rgb(0 0 0 / 25%);
}

.pricing-one.home-15 .theme-btn{
  padding: 16px 28px;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.pricing-one.home-15 .theme-btn:hover{
  border-color: #17be74;
  color: #fff !important;
}

.pricing-one.home-15 .theme-btn:before{
  background: #17be74;
}

.pricing-one.home-15 .pricing-block-two.active-block .pricing-table .theme-btn:hover{
  background: #fff;
  color: #17be74 !important;
}

.pricing-one.home-15 .pricing-block-two.active-block .pricing-table .theme-btn:before{
  display: none;
}

.subscribe-one.home-15 .form-inner .form-group .theme-btn{
  font-size: 15px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  border-radius: 30px;
  width: 224px;
  padding: 17px 30px;
  background: #17be74;
}

.subscribe-one.home-15 .form-inner .form-group .theme-btn:before{
  background: #fff;
}

.cta-five .theme-btn {
  border-radius: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 12px 32px;
  background: #f38836;
}

.cta-five .theme-btn:before{
  webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #fff;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.cta-five .theme-btn:hover:before{
  bottom: -40%;
}

.cta-five .theme-btn:hover{
  color: #f38836 !important;
}

.header-style-16 .theme-btn{
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding: 12px 31px;
  border-radius: 5px;
  background: #e35712;
}

.request-form .theme-btn{
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding: 12px 20px;
  width: 166px;
  border-radius: 5px;
  background: #e35712;
}

.about-16 .theme-btn{
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding: 17px 40px;
  border-radius: 5px;
  background: #e35712;
}

.service-block-four .inner-box,
.service-block-11 .inner-box{
  transition: all 500ms ease;
}

.service-block-four .inner-box:hover,
.service-block-11 .inner-box:hover{
  box-shadow: 0 10px 50px rgb(0 0 0 / 20%);
}

.testimonial-three.about-page-4 .nav-style-two .owl-nav button.owl-next:hover:before,
.testimonial-three.about-page-4 .nav-style-two .owl-nav button.owl-prev:hover:before{
  background: #f1592a;
}

.testimonial-three.about-page-4 .nav-style-two .owl-nav button.owl-next:hover:after {
  border-right: 2px solid #f1592a;
  border-top: 2px solid #f1592a;
}

.testimonial-three.about-page-4 .nav-style-two .owl-nav button.owl-prev:hover:after {
  border-left: 2px solid #f1592a;
  border-bottom: 2px solid #f1592a;
}

.about-20.about-page-5 .feature-block-nine .inner-box:hover .icon-box {
  transform: rotateY(0deg);
}

.about-20.about-page-5 .feature-block-nine .inner-box .icon-box:before{
  display: none;
}

.news-three.about-page-5 .news-block-one .inner-box .lower-content .theme-btn:before {
  background: -webkit-linear-gradient(0deg, #6e42c3 0%, #ad4bc6 100%);
}

.subscribe-five.service-page-1 .theme-btn{
  background: -webkit-linear-gradient(0deg, #bd1ec7, #f92542 100%);
}

.subscribe-five.service-page-2 .theme-btn{
  background: #6a36ff;
}

.subscribe-five.service-page-2 .subscribe-form .form-group input:focus {
  border-color: #6a36ff !important;
}

.service-20 .content_block_27 .theme-btn{
  font-weight: 600;
  font-family: 'Jost', sans-serif;
  font-size: 17px;
  padding: 17px 35px;
  background: #e45712;
}

.subscribe-five.service-page-1.service-page-4 .theme-btn:before{
  background: #141417;
}

.content_block_28 .content-box .theme-btn:hover,
.content_block_29 .content-box .theme-btn:hover{
  color: #fff !important;
}

.subscribe-five.service-page-5 .subscribe-form .message-btn .theme-btn{
  border-radius: 5px;
}

.service-details .theme-btn{
  border-radius: 5px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding: 17px 40px;
  border-radius: 5px;
  background: #e35712;
}

.team-details-one .content-three .form-inner .form-group button {
  background: -webkit-linear-gradient(0deg, #6d42c3 0%, #ad4bc6 100%);
  padding: 12px 32px;
  color: #fff !important;
}

.experience-section.team-details-2 .theme-btn{
  background: #f1592a;
  padding: 12px 32px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.pricing-one.pricing-page-1 .pricing-block-one .pricing-table .theme-btn {
  border: 1px solid #e7e7e7;
  color: #141417 !important;
  padding: 16px 32px;
}

.pricing-one.pricing-page-1 .pricing-block-one.active-block .pricing-table .theme-btn:hover{
  color: #f1592a !important;
  border-color: #fff;
}

.pricing-one.pricing-page-2 .theme-btn-six {
  border-radius: 5px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  padding: 16px 30px;
}

.pricing-one.pricing-page-2 .theme-btn-six:hover{
  color: #fff !important;
}

.pricing-one.pricing-page-2 .pricing-block-two.active-block .pricing-table .theme-btn:hover {
  border-color: #141417;
}

.pricing-one.pricing-page-2 .pricing-block-two.active-block .pricing-table .theme-btn:before{
  background: #141417;
}

.pricing-one.pricing-page-3 .theme-btn:before {
  background: #17be74;
}

.pricing-one.pricing-page-3 .theme-btn:hover{
  color: #fff !important;
}

.pricing-one.pricing-page-3 .pricing-block-two.active-block .pricing-table .theme-btn:hover {
  background: #fff;
  color: #17be74 !important;
}

.pricing-one.pricing-page-3 .pricing-block-two.active-block .pricing-table .theme-btn:before{
  display: none;
}

.contact-three .theme-btn {
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding: 12px 25px;
  border-radius: 5px;
  background: #e35712;
}

.contactinfo-one.contact-page-4 .info-block-one:hover .inner-box .icon-box{
  transform: scale(1.1);
}
@media screen and (max-width: 576px){
	.page-title .content-box{
		width:100%;
	}
}



.donusumbaslat:before{ background:#6C41F2 !important}
.donusumbaslat:hover img {
  filter: brightness(0) invert(1);
  transition: none;
}
.donusumhizlandir:before { background:#E90048 !important }
.donusumhizlandir:hover img {
  filter: brightness(0) invert(1);
  transition: none;
}
.donusumsurdur:before { background:#18ADA6 !important }
.donusumsurdur:hover img {
  filter: brightness(0) invert(1);
  transition: none;
}

.page-header{
  background:#f79622;
  height:144px;
  margin-top:70px;
  align-items:center;
  text-align:right;
  justify-content: center;
}
.page-header .p-title{
  display:flex;
  flex-direction: column;
  justify-content: center;
  height:100%;

}
.page-header .p-title h1,
.page-header .p-title p {
  color:white;
}

.sponsors {
  margin-bottom:30px;
}

.sponsors strong{
  color:#f79622;
  padding:20px 0;
  font-size:1.3em;
  display:block;
}

.sponsors .min-sponsors img{
width:60%;
}
.sponsors .max-sponsors img{
  width:60%;
}

.index-news-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height:320px
}

.news-image{
  width:50%; margin-right:20px; margin-bottom:10px; float:left;
}

.owl-nav.disabled{
  display:none!important
}

.gallery-img{
  height: 180px;
  object-fit: cover;
  width: 100%;
}

.slider-1 .owl-nav.disabled{
  display:block!important
}


.n-image-1{
  width:300px; background:#fff; padding:10px; border-radius:10px;
}
.n-image-2{
  width:260px; background:#fff; padding:10px; border-radius:10px;
}

.inline-border {
  width:100px; background:#eee !important; margin-bottom:3px; margin-left:10px; height:2px; display:inline-block
}


.slider-items {
  border:1px solid #eee; background:#fff; height:440px; text-align:center; display:flex; flex-direction:column; align-items:center; justify-content:center; padding:10px; box-shadow:0 0px 11px rgba(0, 0, 0, 0.1); margin:10px
}

.service-block-slide-lefting {
  border:1px solid #eee;
  min-height:500px !important;
  justify-content:center;
  display:flex;
  flex-direction:column;
  background:#fff;
  text-align:center;
  padding:10px;
  box-shadow:0 0px 11px rgba(0, 0, 0, 0.1);
  margin:10px;
}
.img-show{
  opacity: 0;
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color: rgba(0, 0, 0, 0.5);
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
.img-hover:hover .img-show{
  opacity: 1;
}
