/* Responsive Css */



@media only screen and (max-width: 1449px){

 .chooseus-two .content-inner{
  padding-right: 30px;
  padding-left: 30px;
 }

 .chooseus-three .content-inner,
 .chooseus-six .content-inner{
  padding-left: 30px;
  padding-right: 30px;
 }

}



@media only screen and (max-width: 1200px){


  .image_block_two .image-box{
    margin-left: 0px;
  }

  .banner-three .pattern-layer-2{
    display: none;
  }

  .content_block_two .content-box{
    margin-right: 0px;
  }

  .main-header .outer-box{
    padding: 0px 30px;
  }

  .header-style-two .outer-container{
    padding: 0px 0px;
  }

  .about-two .sec-title-two{
    margin-right: 0px;
  }

  .chooseus-two .content-inner{
    width: 100%;
  }

  .chooseus-two .bg-layer{
    display: none;
  }

  .graph-one .sec-title-two{
    margin-right: 0px;
  }

  .banner-carousel .owl-nav{
    display: none;
  }

  .graph-two .image-box{
    margin: 0px;
  }

  .chooseus-three .content-inner,
  .chooseus-three .progress-inner{
    float: none;
    width: 100%;
  }

  .chooseus-three .bg-layer{
    display: none;
  }

  .chooseus-three .content-inner{
    padding-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .chooseus-three .progress-inner{
    padding: 0px 0px 150px 0px;
  }

  .service-block-three .inner-box .overlay-content{
    padding: 15px;
  }

  .service-block-three .inner-box .overlay-content h4{
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  .service-block-three .inner-box .overlay-content h4 a br{
    display: none;
  }

  .service-block-three .inner-box p{
    margin-bottom: 10px;
  }

  .testimonial-three .inner-content{
    margin-left: 0px;
  }

  .testimonial-three .owl-nav{
    display: none;
  }

  .contact-one .sec-title-three p{
    max-width: 100%;
  }

  .content_block_six .content-box{
    margin: 0px;
  }

  .header-style-five .main-menu .navigation > li{
    margin: 0px 12px;
  }

  .banner-two .content-box{
    margin: 0px;
  }

  .main-header .shape{
    display: none;
  }

  .testimonial-five .inner-content{
    margin-left: 0px;
  }

  .testimonial-five .owl-nav,
  .testimonial-six .owl-nav{
    display: none;
  }

  .pricing-block-one .pricing-table{
    padding-left: 30px;
    padding-right: 30px;
  }

  .cta-four .text .image{
    left: 0px;
  }

  .slider-five .image-box .image,
  .about-seven .bg-layer{
    display: none;
  }

  .content_block_11 .content-box{
    margin-left: 0px;
  }

  .content_block_12 .content-box{
    margin-right: 0px;
  }

  .process-three .processing-block{
    padding-left: 0px;
  }

  .processing-block-three .inner-box .shape{
    display: none;
  }

  .processing-block-three .inner-box .icon-box{
    left: 15px;
  }

  .processing-block-three .inner-box{
    padding-left: 150px;
  }

  .appointment-one .shape,
  .appointment-one .image-layer{
    display: none;
  }

  .appointment-one .content-box{
    margin-left: 0px;
  }

  .header-style-eight .header-lower .outer-container{
    padding: 0px 0px;
  }

  .header-style-eight .main-menu .navigation > li{
    margin: 0px 10px;
  }

  .header-style-eight .theme-btn{
    padding: 12px 25px;
  }

  .content_block_nine .content-box{
    margin-right: 0px;
  }

  .testimonial-seven .owl-nav{
    display: none;
  }

  .appointment-two .content-box{
    margin-left: 0px;
  }

  .appointment-two .map-inner{
    margin-right: 0px;
  }

  .header-style-eight .sticky-header .outer-container{
    padding: 0px 30px;
  }

  .header-style-nine .main-menu .navigation > li{
    margin: 0px 5px;
  }

  .chooseus-six .content-inner{
    width: 100%;
  }

  .chooseus-six .bg-layer{
    display: none;
  }

  .feature-block-seven .inner-box:before{
    display: none;
  }

  .cta-six .text{
    padding-left: 0px;
  }

  .cta-six .text .image{
    display: none;
  }

  .slider-eight{
    padding: 0px;
  }

  .header-style-ten{
    padding: 0px 30px;
  }

  .content_block_nine .content-box{
    margin-left: 0px;
  }

  .service-block-nine .inner-box .overlay-content{
    padding-left: 10px;
    padding-right: 10px;
  }

  .service-block-nine .inner-box h3{
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }

  .service-block-nine .inner-box .icon-box{
    margin-bottom: 15px;
  }

  .service-ten{
    padding: 0px;
  }

  .chooseus-seven .image-box{
    padding-left: 0px;
  }

  .content_block_14 .content-box{
    margin-right: 0px;
  }

  .project-five{
    padding: 0px 15px;
  }

  .project-block-four .inner-box .content-box .inner h3{
    font-size: 20px;
    line-height: 28px;
  }

  .project-block-four .inner-box .content-box{
    margin-left: 0px;
    margin-right: 0px;
  }

  .project-block-four .inner-box .content-box .inner{
    margin-top: 0px;
  }

  .testimonial-eight .owl-nav{
    display: none;
  }

  .testimonial-eight .inner-content{
    padding-left: 0px;
  }

  .skills-three,
  .footer-ten{
    padding: 0px;
  }

  .header-style-ten .sticky-header,
  .header-top-two .outer-container{
    padding: 0px 30px;
  }

  .subscribe-three .inner-container{
    padding-left: 30px;
    padding-right: 30px;
  }

  .header-style-11 .header-lower .outer-container{
    padding: 0px;
    padding-right: 30px;
  }

  .header-style-11 .btn-box,
  .about-11 .image-layer{
    display: none;
  }

  .feature-block-nine .inner-box{
    padding-right: 0px;
  }

  .image_block_12 .image-box{
    margin-left: 0px;
  }

  .team-six .image-layer,
  .banner-three .bg-layer,
  .banner-three .pattern-layer,
  .testimonial-nine .owl-nav,
  .banner-four .shape,
  .screenshot-section .screen-mobile,
  .service-15 .shape .shape-1{
    display: none;
  }

  .testimonial-nine .inner-content{
    padding-left: 0px;
  }

  .header-style-two.header-style-14 .header-lower{
    padding: 0px;
  }

  .screenshot-section .inner-box{
    margin-right: 0px;
  }

  .screenshot-section .sec-title-ten{
    padding-bottom: 0px;
  }

  .image_block_18 .image-box .image:before{
    display: none;
  }

  .header-style-15 .nav-right .btn-box{
    display: none;
  }

  .content_block_18 .content-box{
    margin-left: 0px;
  }

  .chooseus-11.chooseus-three{
    padding-bottom: 110px;
  }

  .testimonial-eight .sec-title{
    margin-right: 0px;
  }

  .project-seven .sortable-masonry .filter-tabs li{
    margin: 0px 10px;
  }

  .testimonial-12 .owl-nav{
    display: none;
  }

  .testimonial-12 .sec-title-11{
    margin-right: 0px;
  }

  .cta-nine .image-box{
    margin: 0px;
  }

  .ex_shop_header .header-phone-number{
    display: none;
  }

  .ex_shop_header_two .header-phone-number{
    display: block;
  }

  .main-header.about-page-2 .outer-box{
    padding: 0px 30px;
  }

  .main-header.about-page-2 .outer-container{
    padding: 0px 0px;
  }

  .header-style-15 .outer-box{
    padding: 0px 30px;
  }

  .content_block_three .content-box{
    margin-left: 0px;
  }

  .content_block_five .content-box{
    margin-right: 0px;
  }

  .service-20 .tab-btns li{
    margin-bottom: 30px;
  }

  .pricing-nine .tabs-content{
    padding-left: 0px;
  }

  .team-block-four .inner-box .image{
    width: 300px;
    height: 300px;
  }

  .team-details-content{
    margin-left: 0px;
  }

  .team-details-two .content-box{
    margin-left: 0px;
  }

  .project-block-one .inner-box .content-box{
    padding-left: 20px;
  }

  .blog-sidebar{
    margin: 0px;
  }

  .header-top-one .top-left,
  .header-top-one .top-right{
    float: none;
    display: block;
    text-align: center;
  }

  .header-top-one .social-links li,
  .header-top-one .top-right li{
    float: none;
  }

  .header-top-one .search-box-outer .dropdown-menu{
    right: inherit;
    left: 0px !important;
  }

  .header-top-one .info li:before{
    display: none;
  }

  .header-top-one .top-right li{
    margin-right: 30px;
  }

  .header-top-one .top-right li:last-child{
    margin-right: 0px;
  }

  .main-header.blog-details-2{
    padding: 0px;
  }

  .main-header.blog-details-2 .outer-container{
    padding: 0px 15px;
  }


}



@media only screen and (min-width: 768px){
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul,
  .main-menu .navigation > li > .megamenu{
    display:block !important;
    visibility:hidden;
    opacity:0;
  }
}



@media only screen and (max-width: 991px){

  .main-menu,
  .sticky-header,
  .main-header.style-one .outer-container:before{
    display: none !important;
  }

  .menu-area .mobile-nav-toggler {
    display: block;
    padding: 10px;
  }

  .megamenu ul li:first-child{
    display: none;
  }

  .feature-block-one .inner-box{
    margin-bottom: 30px;
  }

  .feature-one{
    padding-bottom: 20px;
  }

  .image_block_one .image-box{
    margin-bottom: 30px;
  }

  .service-one .pattern-layer{
    display: none;
  }

  .service-block-one .inner-box{
    margin-bottom: 30px;
  }

  .service-one{
    padding-bottom: 120px;
  }

  .video-one .video-btn{
    position: relative;
    top: 0px;
    margin-bottom: 50px;
    left: 30px;
  }

  .content_block_two .content-box{
    margin-bottom: 30px;
  }

  .processing-block-one .inner-box{
    margin-bottom: 30px;
  }

  .processing-block-one .inner-box .shape{
    display: none;
  }

  .process-one{
    padding-bottom: 110px;
  }

  .image_block_three .image-box{
    margin-bottom: 30px;
  }

  .counter-block-one .inner-box{
    margin-bottom: 30px;
  }

  .funfact-one{
    padding-bottom: 50px;
  }

  .news-block-one .inner-box{
    margin-bottom: 30px;
  }

  .news-one .inner-container{
    padding-bottom: 120px;
  }

  .footer-widget{
    margin: 0px 0px 30px 0px !important;
  }

  .footer-widget-section{
    padding-bottom: 40px;
  }

  .feature-block-two .inner-box{
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .feature-two .inner-container{
    padding-bottom: 20px;
  }

  .about-two .shape{
    display: none;
  }

  .service-block-two .inner-box,
  .team-block-one .inner-box{
    margin-bottom: 30px;
  }

  .service-two{
    padding-bottom: 120px;
  }

  .team-one{
    padding-bottom: 120px;
  }

  .graph-one .image-box{
    padding-left: 0px;
  }

  .project-one .upper-box .text{
    margin-right: 0px;
    margin-top: 15px;
  }

  .project-one .inner-content{
    margin-right: 0px;
  }

  .project-one .owl-nav{
    display: none;
  }

  .news-two{
    padding-bottom: 120px;
  }

  .subscribe-one .inner-container{
    padding-left: 30px;
    padding-right: 30px;
  }

  .subscribe-one .pattern-layer{
    display: none;
  }

  .footer-top-two .footer-menu li{
    margin-right: 25px;
  }

  .news-two .upper-box .text{
    padding-left: 0px;
    margin-top: 15px;
  }

  .banner-carousel .content-box h2 br{
    display: none;
  }

  .feature-block-three .inner-box{
    margin-bottom: 30px;
  }

  .about-three{
    padding-bottom: 120px;
  }

  .service-block-three .inner-box{
    margin-bottom: 30px;
  }

  .skills-two .shape{
    display: none;
  }

  .content_block_three .content-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .skills-two .image_block_three .image-box{
    margin: 0px;
  }

  .contact-one .shape{
    display: none;
  }

  .contact-one .form-inner{
    margin: 30px 0px 0px 0px;
  }

  .main-header.header-style-three .menu-area .mobile-nav-toggler .icon-bar{
    background: #000;
  }

  .banner-one .shape,
  .banner-one .pattern-layer .pattern-2{
    display: none;
  }

  .header-style-four .menu-area .mobile-nav-toggler .icon-bar{
    background: #000;
  }

  .banner-one .content-box{
    margin-bottom: 30px;
  }

  .about-four .image-box{
    margin-bottom: 30px;
  }

  .about-four .image-box .image{
    margin: 0px;
  }

  .content_block_five .content-box,
  .content_block_six .content-box{
    margin-bottom: 30px;
  }

  .header-style-five .header-top-one .social-links-two li{
    float: none;
  }

  .banner-two .content-box{
    margin-bottom: 30px;
  }

  .service-block-five .inner-box{
    margin-bottom: 30px;
  }

  .service-five{
    padding-bottom: 120px;
  }

  .image_block_five .image-box{
    margin-bottom: 30px;
  }

  .pricing-three .pricing-block-one .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-three{
    padding-bottom: 120px;
  }

  .footer-five .footer-widget-section{
    padding-bottom: 60px;
  }

  .footer-five .pattern-layer{
    display: none;
  }

  .footer-five{
    background: #222;
  }

  .slider-four .image-box{
    display: none;
  }

  .service-block-six .inner-box{
    margin-bottom: 30px;
  }

  .content_block_ten .content-box{
    margin-bottom: 30px;
  }

  .processing-block-two .inner-box{
    margin-bottom: 30px;
  }

  .process-two{
    padding-bottom: 110px;
  }

  .processing-block-two .inner-box .shape{
    display: none;
  }

  .cta-four .text{
    padding-left: 0px;
  }

  .cta-four .text .image{
    position: relative;
    top: 0px;
    margin-bottom: 30px;
  }

  .cta-four .btn-box{
    text-align: left !important;
  }

  .subscribe-two .inner-container{
    padding-left: 30px;
    padding-right: 30px;
  }

  .header-top-two .top-left,
  .header-top-two .top-right{
    float: none;
    display: block;
    text-align: center;
  }

  .header-top-two .top-left ul li,
  .header-top-two .top-right ul li{
    float: none;
  }

  .header-top-two .search-box-outer .dropdown-menu{
    right: inherit;
    left: 0px !important;
  }

  .header-style-seven .menu-area .mobile-nav-toggler .icon-bar{
    background: #fff;
  }

  .slider-five .banner-carousel .slide-item{
    padding-top: 90px;
  }

  .image_block_seven .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .chooseus-five .bg-layer,
  .chooseus-five .pattern-layer{
    display: none;
  }

  .service-seven{
    padding-bottom: 150px;
  }

  .testimonial-one.home-7{
    padding-top: 140px;
  }

  .processing-block-three .inner-box{
    margin-bottom: 60px;
  }

  .team-four .team-block-one{
    margin-bottom: 30px;
  }

  .team-four{
    padding-bottom: 120px;
  }

  .feature-block-six .inner-box{
    margin-bottom: 30px;
  }

  .image_block_eight .image-box{
    margin-left: 0px;
    margin-top: 40px;
  }

  .service-eight .image-box-one{
    margin: 30px 0px;
  }

  .service-eight .content-box{
    padding: 0px;
    margin: 0px;
  }

  .service-eight .image{
    margin-bottom: 30px;
  }

  .cta-five .sec-title-eight h2 br{
    display: none;
  }

  .appointment-two{
    padding-top: 100px;
  }

  .appointment-two .map-inner{
    margin-bottom: 30px;
  }

  .header-style-nine .header-top-two .social-links-two li a,
  .header-style-nine .search-box-outer .search-box-btn,
  .header-style-nine .header-top-two .info li,
  .header-style-nine .header-top-two .social-links-two li:first-child{
    border: none;
  }

  .header-style-nine .menu-area .mobile-nav-toggler .icon-bar{
    background: #fff;
  }

  .header-style-nine .header-top-two .info li{
    padding-right: 0px;
  }

  .feature-block-seven .inner-box{
    padding: 0px !important;
    margin-bottom: 30px;
  }

  .feature-seven{
    padding-bottom: 35px;
  }

  .service-block-eight .inner-box{
    margin-bottom: 30px;
  }

  .sec-title h2 br{
    display: none;
  }

  .pricing-five .tabs-content{
    margin-left: 0px;
  }

  .cta-six .text{
    padding-bottom: 0px;
    margin-bottom: 20px;
  }

  .cta-six{
    padding-bottom: 150px;
  }

  .cta-six .btn-box{
    text-align: left !important;
  }

  .feture-black-eight .inner-box{
    margin-bottom: 30px;
  }

  .feature-eight{
    padding-bottom: 120px;
  }

  .image_block_ten .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .content_block_14 .content-box{
    margin-bottom: 30px;
  }

  .project-block-four .inner-box{
    margin-bottom: 30px;
  }

  .project-block-four .inner-box:hover .content-box .inner {
    transform: translateY(0px);
  }

  .team-five{
    padding-bottom: 110px;
  }

  .skills-three .sec-title{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .skills-three .outer-container{
    padding-bottom: 120px;
  }

  .news-ten{
    padding-bottom: 120px;
  }

  .subscribe-three h2{
    margin-bottom: 15px;
  }

  .header-style-11 .header-top-two .info li,
  .header-style-eight .search-box-outer .search-box-btn{
    border: none;
  }

  .header-style-11 .header-top-two .info li,
  .header-style-eight .search-box-outer .search-box-btn{
    padding-right: 0px;
  }

  .feature-block-nine .inner-box{
    margin-bottom: 30px;
  }

  .feature-nine{
    padding-bottom: 45px;
  }

  .image_block_11 .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .news-11{
    padding-bottom: 120px;
  }

  .subscribe-four .text{
    margin-right: 0px;
    margin-bottom: 60px;
  }

  .footer-11 .image-layer{
    display: none;
  }

  .footer-11 .footer-widget-section{
    padding-bottom: 60px;
  }

  .image_block_13 .image-box{
    margin-bottom: 30px;
  }

  .testimonial-nine .sec-title-nine{
    margin-bottom: 30px;
  }

  .news-12{
    padding-bottom: 120px;
  }

  .footer-widget-section{
    padding: 90px 0px 60px 0px !important;
  }

  .slider-ten .image-box{
    margin: 30px 0px 0px 0px;
  }

  .feature-block-ten .inner-box{
    margin-bottom: 30px;
  }

  .about-13 .image-box{
    margin: 0px 0px 30px 0px;
  }

  .pricing-block-two .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-one.pricing-six{
    padding-bottom: 120px;
  }

  .chooseus-ten .image-box{
    margin: 30px 0px 0px 0px;
  }

  .image_block_15 .image-box{
    margin: 0px 0px 30px 0px;
  }

  .cta-seven .text{
    padding-left: 0px;
    margin-bottom: 20px;
  }

  .cta-seven .text .image{
    position: relative;
    top: 0px;
    margin-bottom: 30px;
  }

  .cta-seven .btn-box{
    text-align: left !important;
  }

  .sec-title-ten h2 br{
    display: none;
  }

  .image_block_18 .image-box{
    margin-bottom: 30px;
  }

  .subscribe-one.home-14 .text{
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .header-style-15 .nav-right .search-box-outer{
    margin-bottom: 0px;
  }

  .feature-12 .upper-box:before{
    display: none;
  }

  .feature-12 .upper-box .text{
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .feature-block-12 .inner-box{
    margin-bottom: 30px;
  }

  .service-block-12 .inner-box{
    margin-bottom: 30px;
  }

  .news-15{
    padding-bottom: 120px;
  }

  .subscribe-one.home-15 .text{
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .request-form .form-group{
    margin-bottom: 20px;
  }

  .request-form .inner-container{
    padding-bottom: 10px;
  }

  .image_block_20 .image-box .text{
    left: 0px;
  }

  .service-block-13 .inner-box{
    margin-bottom: 30px;
  }

  .service-16{
    padding-bottom: 120px;
  }

  .service-17 .tab-btns li{
    font-size: 18px;
  }

  .content_block_20 .content-box{
    margin: 30px 0px;
  }

  .content_block_20 .content-box .text{
    margin-bottom: 20px;
  }

  .service-17 .image-box{
    margin: 0px;
  }

  .project-seven .filter-tabs{
    text-align: left !important;
  }

  .pricing-block-three .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-eight{
    padding-bottom: 120px;
  }

  .ex_shop_header .outer-box{
    padding: 17px 0px;
  }

  .ex_shop_header .menu-area .mobile-nav-toggler .icon-bar{
    background: #fff;
  }

  .ex_shop_header .header-lower .menu-area{
    width: 100%;
  }

  .ex_shop_header .header-lower .navbar-right-info{
    position: absolute;
  }

  .content_block_22 .content-box{
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .content_block_22 .content-box .image-box{
    margin-bottom: 30px;
  }

  .skills-two.about-page-1{
    padding-bottom: 120px;
  }

  .footer-three .pattern-layer{
    display: none;
  }

  .page-title.style-two .pattern-layer .shape-1,
  .page-title.style-two .pattern-layer .shape-2{
    display: none;
  }

  .content_block_25 .content-box{
    margin-bottom: 30px;
  }

  .project-three.about-page-2 .project-block-three{
    max-width: 100%;
  }

  .processing-block-four .inner-box{
    margin-top: 80px;
  }

  .processing-block-four .inner-box .line-shape,
  .process-five .shape{
    display: none;
  }

  .process-five .sec-title-12{
    margin-bottom: 30px;
  }

  .team-block-three .inner-box{
    margin-bottom: 30px;
  }

  .team-nine{
    padding-bottom: 115px;
  }

  .feature-block-eight .inner-box{
    margin-bottom: 30px;
  }

  .feature-13{
    padding-bottom: 120px;
  }

  .team-ten .shape{
    display: none;
  }

  .team-block-four{
    margin-bottom: 30px;
  }

  .team-ten{
    padding-bottom: 115px;
  }

  .paroller,
  .paroller-2{
    transform: translate(0px) !important;
  }

  .content_block_26 .content-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .mobile-menu .megamenu h6{
    display: none;
  }

  .image_block_20 .image-box{
    margin-left: 0px;
    margin-top: 30px;
  }

  .processing-block-five .inner-box{
    margin-top: 80px;
  }

  .process-six .sec-title-six{
    margin-bottom: 30px;
  }

  .processing-block-five .inner-box .line-shape{
    display: none;
  }

  .team-three.about-page-4 .shape{
    display: none;
  }

  .team-three.about-page-4{
    padding-bottom: 120px;
  }

  .about-20 .sec-title-six{
    margin: 0px 0px 30px 0px;
  }

  .about-20 .title-box:before{
    display: none;
  }

  .about-20 .title-box .text{
    margin: 0px;
  }

  .team-11 .shape{
    display: none;
  }

  .team-block-five .inner-box{
    margin-bottom: 30px;
  }

  .team-11{
    padding-bottom: 120px;
  }

  .image_block_23 .image-box{
    padding: 0px;
    margin: 0px 0px 30px 0px;
  }

  .pricing-one.service-page-1 .pricing-block-one .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-one.service-page-1{
    padding-bottom: 120px;
  }

  .service-19 .tab-btns li{
    width: 50%;
  }

  .image_block_24 .image-box{
    margin: 30px 0px;
  }

  .content_block_27 .content-box{
    margin-left: 0px;
    margin-right: 0px;
  }

  .service-19,
  .service-20{
    padding-bottom: 120px;
  }

  .pricing-block-four .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-one.service-page-3,
  .service-15.service-page-4,
  .faq-three{
    padding-bottom: 120px;
  }

  .service-21 .tab-btn-box{
    margin-bottom: 30px;
  }

  .service-sidebar{
    margin-left: 0px;
    margin-top: 30px;
  }

  .service-details-2 .service-sidebar,
  .service-details-3 .service-sidebar{
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .service-details-content .image_block_one .image-box .skills-box .single-progress-box{
    margin-bottom: 30px;
  }

  .career-section .pattern-layer .pattern-2{
    display: none;
  }

  .career-section .content-box{
    margin-bottom: 30px;
  }

  .processing-block-six .inner-box{
    margin-top: 50px;
  }

  .processing-block-six .inner-box:before{
    display: none;
  }

  .faq-sidebar{
    margin-left: 0px;
  }

  .faq-page-section.faq-page-2 .faq-sidebar{
    margin-bottom: 30px;
  }

  .team-details-two .team-details-content{
    padding-right: 50px;
    padding-left: 50px;
  }

  .team-details-three .image_block_one .image-box .skills-box .single-progress-box{
    margin-bottom: 30px;
  }

  .team-details-three{
    padding-bottom: 100px;
  }

  .pricing-one.pricing-page-1 .pricing-block-one .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-one.pricing-page-1,
  .pricing-one.pricing-page-3{
    padding-bottom: 120px;
  }

  .faq-two.pricing-page-1 .image_block_one .image-box{
    margin-right: 0px;
  }

  .faq-three.pricing-page-2 .shape{
    display: none;
  }

  .pricing-block-five .pricing-table{
    margin-bottom: 30px;
  }

  .pricing-nine{
    padding-bottom: 120px;
  }

  .info-block-one .inner-box{
    margin-bottom: 30px;
  }

  .contact-four .form-inner{
    margin-left: 0px;
    margin-top: 30px;
  }

  .info-block-two .inner-box{
    margin-bottom: 30px;
  }

  .contactinfo-two{
    padding-bottom: 120px;
  }

  .contactinfo-one.contact-page-4{
    padding-bottom: 120px;
  }

  .contact-seven .form-inner{
    margin: 30px 0px 0px 0px;
  }

  .project-nine .sortable-masonry .filter-tabs li{
    margin-bottom: 10px;
  }

  .project-details .project-info .info{
    padding: 0px;
    border-left: none;
  }

  .project-details .lower-box .text{
    margin: 0px 0px 30px 0px;
  }

  .project-details-content{
    margin-left: 0px;
  }

  .project-details-4 .porject-info .info-list .single-item{
    margin-bottom: 15px;
  }

  .project-details-5 .text-box .text{
    margin: 0px 0px 30px 0px;
  }

  .shop-sidebar{
    margin-bottom: 30px;
  }

  .shop-details .product-details{
    margin: 30px 0px 0px 0px;
  }

  .blog-sidebar{
    margin-top: 30px;
  }

  .ex_shop_header .header-top .right-column{
    width: 100%;
    justify-content: center;
  }

  .ex_shop_header .header-top .language{
    position: relative;
  }

  .page-title{
    padding: 200px 0px 100px 0px;
  }

  .page-title .content-box h1{
    font-size: 40px;
    line-height: 50px;
  }

  .banner-five .image-box{
    margin-right: 0px;
    margin-top: 30px;
  }

  .ex_shop_header .header-lower .shop-category > ul{
  position: absolute;
  left: 0px;
  top: 70px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0px 30px 0px rgba(0,0,0,0.06);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ex_shop_header .header-lower .shop-category:hover > ul{
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}


}


@media only screen and (max-width: 767px){

  .main-header .outer-box{
    padding: 0px 15px;
  }

  .header-top-one .top-right li,
  .header-top-one .info li p,
  .header-top-one .info li p a,
  .header-top-one .social-links li{
    line-height: 26px;
  }

  .header-top-one .info li i{
    top: 5px;
  }

  .banner-five .pattern-layer{
    display: none;
  }

  .header-top-one{
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .banner-five{
    padding: 90px 0px 100px 0px;
  }

  .banner-carousel .slide-item{
    padding: 90px 0px 100px 0px;
  }

  .slider-one .shape-layer{
    display: none;
  }

  .banner-carousel .content-box h2{
    font-size: 50px;
    line-height: 60px;
  }

  .banner-carousel .content-box p br{
    display: none;
  }

  .feature-block-one .inner-box:before{
    display: none;
  }

  .about-one .inner-container{
    padding: 70px 0px;
  }

  .service-one{
    padding: 70px 0px 40px 0px;
  }

  .video-one .pattern-layer{
    display: none;
  }

  .video-one{
    padding: 100px 0px 70px 0px;
  }

  .sec-pad{
    padding: 70px 0px;
  }

  .image_block_two .image-box .shape,
  .process-one .pattern-layer{
    display: none;
  }

  .process-one{
    padding-bottom: 40px;
  }

  .testimonial-one .inner-container{
    padding: 70px 0px;
  }

  .testimonial-one .dots-style-one .owl-dots{
    display: none;
  }

  .cta-one .shape{
    display: none;
  }

  .cta-one{
    padding: 65px 0px 70px 0px;
  }

  .news-one .upper-box .text{
    padding: 0px;
  }

  .news-one .inner-container,
  .service-two{
    padding-bottom: 40px;
  }

  .footer-one .pattern-layer,
  .slider-two .shape-layer,
  .slider-two .pattern-layer,
  .service-two .pattern-layer,
  .chooseus-two .pattern-layer,
  .graph-one .image-box .shape,
  .testimonial-two .pattern-layer,
  .testimonial-two .owl-dots,
  .cta-two .pattern-layer,
  .about-three .shape,
  .chooseus-three .content-inner .pattern-layer,
  .contact-one .pattern-layer{
    display: none;
  }

  .about-two .upper-box{
    margin-bottom: 30px;
  }

  .clients-one{
    padding-bottom: 70px;
  }

  .chooseus-two .content-inner{
    padding-top: 70px;
    padding-bottom: 65px;
  }

  .funfact-two{
    padding-top: 70px;
  }

  .sec-title-two h2 br{
    display: none;
  }

  .cta-two{
    padding: 65px 0px 70px 0px;
  }

  .news-two{
    padding-bottom: 40px;
  }

  .footer-top-two .footer-menu,
  .footer-top-two .footer-logo{
    float: none;
    display: block;
    text-align: center;
  }

  .footer-top-two .footer-menu li{
    float: none;
  }

  .slider-three .banner-carousel .slide-item{
    padding-top: 200px;
  }

  .clients-one.home-3{
    padding-bottom: 50px;
  }

  .sec-title-three h2 br{
    display: none;
  }

  .graph-two{
    padding-bottom: 70px;
  }

  .chooseus-three .content-inner{
    padding-top: 65px;
  }

  .chooseus-three .progress-inner{
    padding-bottom: 70px;
  }

  .service-three .more-btn{
    margin-bottom: 0px;
  }

  .skills-two{
    padding-bottom: 70px;
  }

  .news-three{
    padding-bottom: 40px;
  }

  .banner-one h2{
    font-size: 50px;
    line-height: 60px;
  }

  .banner-one .pattern-layer .pattern-1{
    display: none;
  }

  .header-style-four .menu-area .mobile-nav-toggler .icon-bar{
    background: #222;
  }

  .header-style-four .header-lower .nav-right .search-box-outer .search-box-btn,
  .header-style-four .header-lower .nav-right .nav-btn{
    color: #222;
  }

  .about-four{
    padding-top: 70px;
  }

  .clients-one.home-4{
    padding: 70px 0px 0px 0px;
  }

  .service-block-four .inner-box{
    margin-bottom: 30px;
  }

  .service-four .more-btn{
    margin-top: 0px;
  }

  .chooseus-four{
    padding: 70px 0px;
  }

  .team-one{
    padding-bottom: 40px;
  }

  .quote-one{
    padding-bottom: 65px;
  }

  .funfact-three .pattern-layer,
  .testimonial-four .owl-dots,
  .news-four .shape,
  .footer-four .pattern-layer{
    display: none;
  }

  .funfact-three.home-4{
    padding: 60px 0px 35px 0px;
  }

  .testimonial-four{
    padding: 70px 0px;
  }

  .pricing-block-one .pricing-table{
    margin-bottom: 30px;
  }

  .news-four{
    padding-bottom: 40px;
  }

  .header-style-five .header-top-one .social-links-two li{
    line-height: 26px;
  }

  .banner-two .content-box h2{
    font-size: 50px;
    line-height: 60px;
  }

  .service-five{
    padding: 65px 0px 40px 0px;
  }

  .funfact-four{
    padding: 65px 0px 35px 0px;
  }

  .sec-title-five h2 br,
  .image_block_five .image-box .shape,
  .team-two .pattern-layer,
  .contact-two .pattern-layer,
  .image_block_six .shape{
    display: none;
  }

  .team-two.home-4{
    padding: 65px 0px 10px 0px;
  }

  .testimonial-five{
    padding-bottom: 70px;
  }

  .pricing-three,
  .news-five{
    padding-bottom: 40px;
  }

  .slider-four.home-6 .content-inner{
    padding-top: 0px;
  }

  .feature-four .inner-container{
    margin-top: 0px;
  }

  .about-six{
    padding: 70px 0px;
  }

  .about-six .content_block_nine .content-box{
    padding-bottom: 0px;
  }

  .service-six .more-btn{
    margin-top: 0px;
  }

  .support-area .map-box{
    margin-left: 0px;
  }

  .sec-title-six h2 br,
  .footer-six .pattern-layer,
  .slider-five .shape,
  .slider-five .pattern-layer{
    display: none;
  }

  .sec-title-six h2{
    font-size: 40px;
    line-height: 50px;
  }

  .process-two{
    padding: 65px 0px 35px 0px;
  }

  .team-three{
    padding-bottom: 40px;
  }

  .cta-four{
    padding: 70px 0px;
  }

  .cta-four .text h2{
    font-size: 40px;
    line-height: 50px;
  }

  .feature-five .inner-container{
    margin-top: 0px;
  }

  .slider-five .banner-carousel .slide-item{
    padding-bottom: 100px;
  }

  .feature-five .pattern-layer{
    display: none;
  }

  .feature-block-five .inner-box{
    padding-bottom: 30px;
    padding-top: 0px;
  }

  .feature-five .inner-container{
    padding: 70px 0px 35px 0px;
    background: #222;
  }

  .service-block-seven .inner-box{
    margin-bottom: 30px;
  }

  .service-seven{
    padding-bottom: 70px;
  }

  .service-seven .more-btn{
    margin-top: 0px;
  }

  .testimonial-one.home-7{
    padding: 65px 0px 70px 0px;
  }

  .processing-block-three .inner-box{
    padding-bottom: 50px;
  }

  .process-three .pattern-layer,
  .clients-one.home-7 .pattern-layer,
  .appointment-one .pattern-layer{
    display: none;
  }

  .process-three{
    background: #222;
  }

  .process-three{
    padding: 90px 0px 20px 0px;
  }

  .team-four{
    padding: 65px 0px 40px 0px;
  }

  .news-seven .upper-box .text{
    margin: 15px 0px 0px 0px;
  }

  .news-seven{
    padding: 65px 0px 40px 0px;
  }

  .appointment-one.home-7{
    background: #f7f7f7;
    padding: 65px 0px 70px 0px;
  }

  .slider-six .content-inner h2{
    font-size: 50px;
    line-height: 60px;
  }

  .slider-six .content-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .slider-six .pattern-layer,
  .image_block_eight .image-box .shape,
  .testimonial-seven .shape{
    display: none;
  }

  .feature-six{
    padding: 65px 0px 40px 0px;
  }

  .about-eight{
    padding-bottom: 70px;
  }

  .sec-title-eight h2 br,
  .image_block_nine .image-box .shape{
    display: none;
  }

  .service-eight .tab-btn-box{
    margin-bottom: 0px;
  }

  .pricing-four{
    padding-bottom: 40px;
  }

  .news-eight{
    padding: 65px 0px 40px 0px;
  }

  .news-eight .upper-box .text{
    padding-left: 0px;
    margin-top: 15px;
  }

  .appointment-two{
    padding: 70px 0px;
  }

  .slider-seven .banner-carousel .slide-item{
    padding-top: 300px;
  }

  .content_block_nine .content-box{
    padding-bottom: 35px;
  }

  .service-nine .more-btn{
    margin-top: 0px;
  }

  .chooseus-six .content-inner{
    padding-top: 65px;
    padding-bottom: 170px;
  }

  .funfact-five .inner-container{
    padding: 70px 0px 35px 0px;
  }

  .testimonial-three{
    padding-top: 70px;
  }

  .sec-title h2{
    font-size: 40px;
    line-height: 50px;
  }

  .testimonial-three .owl-dots{
    display: none;
  }

  .pricing-five,
  .project-four{
    padding-bottom: 40px;
  }

  .project-block-two .inner-box{
    margin-bottom: 30px;
  }

  .cta-six .shape{
    display: none;
  }

  .cta-six .text{
    padding-top: 70px;
  }

  .cta-six{
    padding-bottom: 70px;
  }

  .cta-six .text h2{
    font-size: 40px;
    line-height: 50px;
  }

  .news-nine{
    padding-bottom: 40px;
  }

  .header-style-ten{
    padding: 0px;
  }

  .slider-eight .banner-carousel .content-box h2{
    margin-bottom: 25px;
  }

  .feature-eight{
    padding-bottom: 40px;
  }

  .about-ten{
    padding-top: 0px;
    padding-bottom: 35px;
  }

  .image_block_ten .image-box .shape,
  .chooseus-seven .image-box .shape{
    display: none;
  }

  .service-ten .more-btn{
    margin: 0px;
  }

  .service-block-nine .inner-box{
    margin-bottom: 30px;
  }

  .chooseus-seven .image-box .image{
    margin-bottom: 30px;
  }

  .chooseus-seven{
    padding-bottom: 40px;
  }

  .testimonial-eight .inner-content .shape,
  .image_block_11 .image-box .shape,
  .image_block_12 .image-box .shape{
    display: none;
  }

  .team-five{
    padding-bottom: 35px;
  }

  .skills-three .outer-container{
    padding: 70px 0px 40px 0px;
  }

  .subscribe-three .inner-container{
    margin-bottom: 0px;
  }

  .news-ten{
    padding-bottom: 40px;
  }

  .header-style-11 .outer-box{
    padding: 0px;
  }

  .about-11{
    padding-bottom: 35px;
  }

  .service-11{
    padding: 65px 0px 200px 0px;
  }

  .service-11 .more-btn{
    margin-top: 0px;
  }

  .team-six{
    padding-bottom: 35px;
  }

  .banner-three{
    padding-top: 180px;
    padding-bottom: 30px;
  }

  .banner-three h2{
    font-size: 50px;
    line-height: 60px;
  }

  .banner-three .shape,
  .testimonial-nine .pattern-layer,
  .subscribe-five .pattern-layer,
  .slider-ten .pattern-layer,
  .about-13 .image-box .shape,
  .chooseus-ten .shape,
  .testimonial-ten .owl-dots,
  .cta-seven .shape,
  .footer-13 .shape .shape-1{
    display: none;
  }

  .clients-one.home-12{
    padding: 70px 0px;
  }

  .about-12{
    padding-bottom: 35px;
  }

  .chooseus-nine{
    padding: 70px 0px;
  }

  .testimonial-nine{
    padding: 70px 0px;
  }

  .subscribe-five .subscribe-form .form-group input{
    margin-bottom: 30px;
  }

  .subscribe-five .subscribe-form{
    padding-right: 0px;
  }

  .subscribe-five .subscribe-form .message-btn{
    position: relative;
  }

  .subscribe-five .subscribe-form .message-btn .theme-btn{
    width: 100%;
  }

  .news-12{
    padding-bottom: 40px;
  }

  .subscribe-five{
    padding-bottom: 70px;
  }

  .domain-field .inner-container{
    margin-top: 0px;
  }

  .slider-ten .banner-carousel .content-box{
    margin-bottom: 30px;
  }

  .domain-field .inner-container{
    padding-left: 30px;
    padding-right: 30px;
  }

  .feature-ten{
    padding-top: 65px;
  }

  .pricing-one.pricing-six{
    padding-bottom: 70px;
  }

  .chooseus-ten{
    padding: 70px 0px;
  }

  .content_block_15 .content-box{
    margin-bottom: 30px;
  }

  .service-block-11 .inner-box{
    margin-bottom: 30px;
  }

  .service-13{
    padding: 65px 0px 40px 0px;
  }

  .testimonial-ten{
    padding-bottom: 70px;
  }

  .cta-seven{
    padding: 70px 0px;
  }

  .news-13{
    padding: 65px 0px 40px 0px;
  }

  .footer-13{
    padding-top: 0px;
  }

  .banner-four .image-box{
    margin: 0px;
  }

  .banner-four{
    padding: 90px 0px 0px 0px;
  }

  .paroller-3,
  .paroller-4{
    transform: translate(0px) !important;
  }

  .banner-four .content-box h2{
    font-size: 50px;
    line-height: 60px;
  }

  .banner-four .content-box h2 span:before,
  .slider-11 .pattern-layer{
    display: none;
  }

  .feature-11,
  .about-14,
  .process-four{
    padding: 0px;
  }

  .service-14,
  .news-14{
    padding: 70px 0px 40px 0px;
  }

  .screenshot-section .screenshot{
    margin-bottom: 0px;
  }

  .screenshot-section{
    padding: 70px 0px;
  }

  .sec-title-ten h2{
    font-size: 40px;
    line-height: 50px;
  }

  .testimonial-11 .owl-dots,
  .about-15 .pattern-layer,
  .service-15 .shape{
    display: none;
  }

  .download-section{
    padding-bottom: 70px;
  }

  .header-style-15 .nav-right .search-box-outer{
    margin-right: 0px;
    margin-top: 0px;
  }

  .feature-12{
    padding-top: 65px;
  }

  .about-15{
    padding: 70px 0px;
  }

  .service-15{
    padding: 65px 0px 70px 0px;
  }

  .service-15 .more-btn{
    margin-top: 0px;
  }

  .chooseus-11.chooseus-three{
    padding-bottom: 30px;
  }

  .team-seven,
  .pricing-one,
  .news-15{
    padding: 60px 0px 40px 0px;
  }

  .testimonial-eight.home-15{
    padding-bottom: 70px;
  }

  .header-style-16 .outer-box{
    padding: 0px;
  }

  .slider-12 .banner-carousel .slide-item{
    padding: 100px 0px 150px 0px;
  }

  .request-form .default-form{
    padding-right: 0px;
  }

  .request-form .default-form .message-btn{
    position: relative;
  }

  .request-form .default-form .message-btn .theme-btn{
    width: 100%;
  }

  .request-form .inner-container{
    padding: 50px 0px;
  }

  .content_block_19 .content-box{
    margin-left: 0px;
  }

  .service-16{
    padding-bottom: 40px;
  }

  .service-17 .image-shape{
    display: none;
  }

  .service-17{
    padding: 65px 0px 150px 0px;
  }

  .service-17 .tab-btns li{
    width: 100% !important;
    margin-bottom: 30px;
    border: none;
    border-radius: 0px !important;
  }

  .service-17 .tab-btns li:last-child{
    margin-bottom: 0px;
  }

  .service-17 .tab-btns{
    border-radius: 0px;
  }

  .funfact-eight .counter-block-one .inner-box .icon-box{
    display: none;
  }

  .sec-title-11 p br{
    display: none;
  }

  .team-eight .owl-dots{
    display: none;
  }

  .project-seven{
    padding-top: 65px;
  }

  .testimonial-block-four .inner-box .image-layer,
  .testimonial-block-four .inner-box .shape-layer,
  .cta-nine .image-box .shape,
  .cta-nine .pattern-layer,
  .footer-16 .shape{
    display: none;
  }

  .testimonial-block-four .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .testimonial-12 .sec-title-11{
    margin-bottom: 50px;
  }

  .sec-title-11 h2 br,
  .ourmission-section .pattern-layer,
  .skills-two.about-page-1 .pattern-layer,
  .project-three.about-page-2 .shape-layer,
  .skills-two.about-page-2 .pattern-layer{
    display: none;
  }

  .cta-nine{
    padding: 70px 0px;
  }

  .news-16{
    padding: 65px 0px;
  }

  .ex_shop_header .outer-box{
    padding: 17px 0px;
  }

  .about-three.about-page-1{
    padding: 60px 0px 40px 0px;
  }

  .ourmission-section .sec-title-three{
    margin-bottom: 50px;
  }

  .ourmission-section.about-page-1{
    padding-bottom: 70px;
  }

  .skills-two.about-page-1{
    padding-bottom: 40px;
  }

  .content_block_24 .content-box .sec-title-three{
    margin-bottom: 50px;
  }

  .team-five.about-page-1{
    padding-top: 65px;
  }

  .cta-three.about-page-1{
    padding: 60px 0px 80px 0px;
  }

  .page-title.style-two{
    padding: 60px 0px 50px 0px;
  }

  .about-17.about-page-2{
    padding-bottom: 70px;
  }

  .team-block-three .inner-box{
    padding-right: 0px;
  }

  .team-nine,
  .feature-13{
    padding-bottom: 40px;
  }

  .header-style-two .nav-right .nav-btn{
    margin-left: 15px;
  }

  .sec-title-13 h2 br{
    display: none;
  }

  .team-ten.about-page-3{
    padding-bottom: 35px;
  }

  .funfact-nine .shape,
  .testimonial-five.about-page-3 .owl-dots,
  .about-19 .pattern-layer,
  .about-19 .image_block_20 .image-box .shape,
  .testimonial-three .shape,
  .about-20 .image-box .image-shape,
  .image_block_23 .image-box .image-shape{
    display: none;
  }

  .funfact-nine{
    padding: 70px 0px 40px 0px;
  }

  .testimonial-five.about-page-3{
    padding-top: 65px;
  }

  .page-title.about-page-3{
    padding: 60px 0px 50px 0px;
  }

  .feature-13.about-page-4{
    padding-top: 70px;
  }

  .funfact-ten.about-page-4{
    padding-bottom: 36px;
  }

  .testimonial-three.about-page-4 .sec-title-six{
    margin-bottom: 50px;
  }

  .team-three.about-page-4{
    padding-bottom: 40px;
  }

  .about-20 .feature-box{
    margin-bottom: 35px;
  }

  .about-20 .image-box .image{
    margin-bottom: 20px;
  }

  .about-20.about-page-5{
    padding-bottom: 40px;
  }

  .skills-four.about-page-5{
    padding: 70px 0px 40px 0px;
  }

  .news-three.about-page-5 .pattern-layer{
    display: none;
  }

  .news-three.about-page-5{
    padding-top: 60px;
  }

  .testimonial-one.testimonial-page-1,
  .testimonial-one.testimonial-page-3{
    padding: 70px 0px 0px 0px;
  }

  .testimonial-five.testimonial-page-2,
  .testimonial-11.testimonial-page-5{
    padding: 70px 0px 40px 0px;
  }

  .service-18{
    padding: 70px 0px 40px 0px;
  }

  .faq-two.service-page-1 .image_block_one .image-box{
    margin-right: 0px;
  }

  .faq-two.service-page-1{
    padding-bottom: 40px;
  }

  .pricing-one.service-page-1{
    padding-bottom: 70px;
  }

  .subscribe-five.service-page-1 .subscribe-form{
    padding-right: 0px;
  }

  .service-19 .tab-btns li{
    width: 100%;
  }

  .service-19 .tab-btn-box{
    margin-bottom: 40px;
  }

  .service-19 .inner-box,
  .service-20 .tab-btn-box{
    margin-bottom: 50px;
  }

  .service-19{
    padding: 70px 0px 20px 0px;
  }

  .service-20{
    padding-bottom: 40px;
  }

  .funfact-ten .pattern-layer{
    display: none;
  }

  .funfact-ten.service-page-3{
    padding: 70px 0px 30px 0px;
  }

  .pricing-one.service-page-3,
  .service-15.service-page-4{
    padding-bottom: 40px;
  }

  .sec-title p br{
    display: none;
  }

  .pricing-nine.service-page-4{
    padding-bottom: 40px;
  }

  .faq-three.service-page-4{
    padding: 65px 0px 40px 0px;
  }

  .service-21 .tab-btn-box{
    margin-right: 0px;
  }

  .content_block_28 .content-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .content_block_28 .content-box .image-box{
    position: relative;
    top: 0px;
    right: 0px;
    width: 100%;
    height: auto;
  }

  .faq-three.service-page-5{
    padding: 65px 0px 40px 0px;
  }

  .service-details-content .content-two .list li{
    width: 50%;
  }

  .service-details-content .two-column .image{
    margin-bottom: 30px;
  }

  .service-details-content .image-box .image{
    margin-bottom: 30px;
  }

  .career-section .content-box{
    padding-top: 0px;
  }

  .career-section .content-box h2{
    font-size: 40px;
    line-height: 50px;
  }

  .career-section .image-box .shape{
    display: none;
  }

  .job-category{
    padding-top: 0px;
    padding-bottom: 70px;
  }

  .positions-section{
    padding-bottom: 70px;
  }

  .process-six.process-page,
  .process-seven{
    padding: 60px 0px;
  }

  .process-seven .sec-title,
  .process-five.process-page .sec-title{
    margin-bottom: 30px;
  }

  .process-five.process-page{
    padding: 60px 0px;
  }

  .faq-page-section{
    padding: 65px 0px 70px 0px;
  }

  .faq-page-section.faq-page-2,
  .faq-page-section.faq-page-3{
    padding-bottom: 40px;
  }

  .contact-one.faq-page-3{
    padding: 65px 0px 70px 0px;
  }

  .team-one.team-page-1{
    padding-top: 65px;
  }

  .team-three.team-page-3{
    padding-top: 60px;
  }

  .team-11.team-page-5,
  .team-nine.team-page-6,
  .team-ten.team-page-7{
    padding: 60px 0px 40px 0px;
  }

  .team-details-one,
  .team-details-two,
  .experience-section{
    padding: 70px 0px;
  }

  .team-details-two .team-details-content .image-box{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .team-details-two .team-details-content{
    margin-bottom: 30px;
  }

  .experience-section .contact-inner{
    margin-left: 0px;
    margin-top: 30px;
  }

  .team-details-three{
    padding: 70px 0px 40px 0px;
  }

  .team-details-three .image-box{
    margin: 0px;
  }

  .team-details-three .team-details-content{
    margin-top: 30px;
  }

  .registration-section,
  .error-section,
  .contact-five{
    padding: 70px 0px;
  }

  .pricing-one.pricing-page-1,
  .faq-two.pricing-page-1,
  .pricing-one.pricing-page-2,
  .pricing-one.pricing-page-3,
  .contactinfo-one.contact-page-4,
  .project-two.project-page-1,
  .project-two.project-page-4{
    padding-bottom: 40px;
  }

  .faq-three.pricing-page-2{
    padding: 65px 0px 70px 0px;
  }

  .contactinfo-one{
    padding-top: 70px;
  }

  .contact-four .shape,
  .contact-five .shape{
    display: none;
  }

  .contactinfo-two{
    padding-bottom: 40px;
  }

  .contact-six .form-inner{
    margin-left: 0px;
  }

  .contact-seven{
    padding-bottom: 70px;
  }

  .header-style-six .nav-right .nav-btn{
    margin-left: 0px;
  }

  .project-eight .sortable-masonry .filter-tabs li{
    margin-bottom: 10px;
  }

  .project-eight.project-page-2,
  .project-two.project-page-5{
    padding-bottom: 0px;
  }

  .project-nine.project-page-3{
    padding-bottom: 20px;
  }

  .project-nine.project-page-6{
    padding-bottom: 30px;
  }

  .project-details .project-info{
    margin-bottom: 30px;
  }

  .project-details .carousel-outer{
    margin-bottom: 30px;
  }

  .project-details .lower-box{
    margin-bottom: 0px;
  }

  .project-details{
    padding-bottom: 65px;
  }

  .project-details .image-box .image{
    margin-bottom: 30px;
  }

  .project-details .image-box{
    margin-bottom: 0px;
  }

  .project-details.project-details-3 .project-details-content{
    margin-bottom: 30px;
  }

  .project-details.project-details-4 .text{
    margin-left: 0px;
    margin-right: 0px;
  }

  .project-details.project-details-4 .info-list{
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .project-details-4 .porject-info .info-list .single-item:before{
    display: none;
  }

  .project-details-4 .project-details-content{
    margin-bottom: 30px;
  }

  .project-details .client-thumb-outer{
    position: relative;
  }

  .project-details-5 .carousel-box{
    margin-bottom: 30px;
  }

  .project-details-5 .text-box{
    margin-bottom: 0px;
  }

  .project-details-5 .project-details-content{
    margin-bottom: 0px;
  }

  .shop-details{
    padding: 70px 0px 0px 0px;
  }

  .product-details-content{
    margin-bottom: 30px;
  }

  .shop-details .product-discription{
    margin-bottom: 30px;
  }

  .cart-section,
  .checkout-section{
    padding: 70px 0px;
  }

  .checkout-section .order-info{
    margin-top: 50px;
  }

  .blog-standard-content{
    margin-right: 0px;
  }

  .blog-standard-content .news-block-one .inner-box .lower-content{
    padding-left: 30px;
    padding-right: 30px;
  }

  .news-block-one.quote-block .inner-box .lower-content{
    padding-left: 0px;
    padding-right: 0px;
  }

  .blog-list-content .news-block-one .inner-box{
    padding-left: 0px;
  }

  .blog-list-content .news-block-one .inner-box .image-box{
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }

  .blog-details-content{
    margin-right: 0px;
  }

  .blog-details-content .image{
    margin-bottom: 30px;
  }

  .blog-details-content .content-two .image-box{
    margin-bottom: 0px;
  }

  .blog-details-content .post-share-option .tags-list{
    float: none;
    display: block;
    margin-bottom: 15px;
  }

  .blog-details-content .post-share-option .social-list{
    float: none;
    display: block;
  }

  .blog-details-content .nav-btn .single-btn.prev-btn{
    margin-bottom: 15px;
  }

  .page-title.blog-details{
    padding: 100px 0px 40px 0px;
  }

  .page-title.blog-details h2 br{
    display: none;
  }

  .sidebar-page-container.blog-details-2{
    padding-top: 70px;
  }

  .blog-details-3 .blog-details-content .content-two .image-box{
    margin-bottom: 30px;
  }

  .sidebar-page-container.blog-details-3{
    padding-bottom: 70px;
  }

  .image_block_one .image-box .single-progress-box{
    margin-bottom: 15px;
  }

  .funfact-three{
    padding: 70px 0px 40px 0px;
  }

  .testimonial-three{
    padding-bottom: 70px;
  }

  .cta-three{
    padding: 60px 0px 80px 0px;
  }

  .service-block-10 .inner-box{
    margin-bottom: 30px;
  }

  .image_block_21 .image-box{
    margin-bottom: 30px;
  }

  .contact-three .content-box{
    padding-left: 0px;
    margin-top: 30px;
  }

  .blog-details-2{
    padding-bottom: 70px;
  }

  .contact-two h2{
    font-size: 36px;
    line-height: 46px;
  }

  .sec-title-seven h2{
    font-size: 40px;
    line-height: 50px;
  }

  .funfact-five .inner-container{
    padding-left: 0px;
    padding-right: 0px;
  }

  .content_block_29 .content-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .content_block_29 .content-box .image-box{
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    margin-top: 30px;
  }


}

@media only screen and (max-width: 599px){

  .image_block_one .image-box{
    margin-right: 0px;
    padding: 0px;
  }

  .image_block_one .image-box .video-inner{
    position: relative;
    right: 0px;
    margin: 30px 0px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image_block_one .image-box .skills-box{
    position: relative;
    width: 100%;
  }

  .section-line{
    display: none;
  }

  .content_block_one .content-box{
    margin-left: 0px;
  }

  .funfact-one .shape{
    display: none;
  }

  .pricing-one .inner-container{
    padding: 0px;
  }

  br{
    display: none;
  }

  .footer-bottom .copyright,
  .footer-bottom .footer-nav{
    float: none;
    display: block;
    text-align: center;
  }

  .footer-bottom .footer-nav li{
    float: none;
  }

  .graph-two .shape{
    display: none;
  }

  .project-two{
    padding-bottom: 0px;
  }

  .slider-five .banner-carousel .slide-item{
    padding-top: 110px;
  }

  .service-eight .tab-btns li{
    width: 100%;
    margin-right: 0px;
  }

  .service-eight .tab-btns li:after{
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
  }

  .service-eight .tab-btns li:hover:after, .service-eight .tab-btns li.active-btn:after{
    bottom: -15px;
  }

  .testimonial-block-two .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .project-block-three .inner-box .lower-content h3{
    font-size: 20px;
    line-height: 26px;
  }

  .project-block-three .inner-box .lower-content{
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner-three{
    padding-bottom: 70px;
  }

  .image_block_13 .image-box .shape,
  .image_block_14 .image-box .shape{
    display: none;
  }

  .domain-field .search-form .form-group input[type='search']{
    padding-left: 30px;
    padding-right: 30px;
  }

  .domain-field .search-form .form-group button{
    position: relative;
    top: 0px;
    right: 0px;
    margin-top: 15px;
  }

  .about-13 .content_block_nine .content-box{
    padding-bottom: 0px;
  }

  .subscribe-one.home-15 .inner-container .shape{
    display: none;
  }

  .header-style-16 .btn-box,
  .image_block_20 .image-box .shape{
    display: none;
  }

  .header-style-16 .nav-right .search-box-outer{
    margin-right: 0px;
    line-height: 26px;
  }

  .content_block_20 .content-box .list li.pull-left{
    width: 100%;
  }

  .main-header.ex_shop_header .header-upper .right-info{
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }

  .ex_shop_header .header-top ul.contact-info{
    margin-right: 0px;
    border-right: none;
  }

  .faq-two .image_block_one .image-box{
    padding-right: 0px;
  }

  .image_block_21 .image-box{
    padding: 0px;
    margin-right: 0px;
  }

  .image_block_21 .image-box .image-2{
    position: relative;
    margin-top: 30px;
  }

  .list-style-one li{
    width: 100%;
  }

  .pricing-block-four .pricing-table .table-inner{
    padding-left: 0px;
  }

  .pricing-block-four .pricing-table .table-header{
    width: 100%;
    position: relative;
  }

  .service-details-content .content-two .list li{
    width: 100%;
  }

  .comingsoon-section .cs-countdown .count-col{
    margin-bottom: 15px;
  }

  .comingsoon-section .cs-countdown .count-col:last-child{
    margin-bottom: 0px;
  }

  .info-block-one .inner-box p br{
    display: block;
  }

  .cart-section .othre-content .coupon-box{
    float: none;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .cart-section .othre-content .update-btn{
    float: none;
  }

  .cart-section .othre-content .update-btn button{
    width: 100%;
  }

  .blog-details-content blockquote{
    padding-right: 30px;
  }

  .project-one .sec-title-two{
    margin-right: 0px;
  }

  .about-three .sec-title-three{
    margin-right: 0px;
  }

  .about-three .upper-box .text{
    margin-left: 0px;
  }

  .contact-four .info-list li p br{
    display: block;
  }

}


@media only screen and (max-width: 499px){

  .mobile-menu{
    width: 100%;
  }

  .main-header .btn-box{
    display: none;
  }

  .image_block_two .image-box{
    padding: 0px;
  }

  .image_block_two .image-box .image-2{
    position: relative;
    margin-top: 30px;
  }

  .image_block_three .image-box{
    padding: 0px;
    margin-right: 0px;
  }

  .image_block_three .image-box .image{
    position: relative;
    margin-bottom: 30px;
  }

  .image_block_three .image-box .text{
    position: relative;
    right: 0px;
    bottom: 0px;
    width: 100%;
  }

  .image_block_three .image-box .text{
    padding-right: 30px;
  }

  .footer-top .footer-logo{
    float: none;
    display: block;
    text-align: center;
    margin-bottom: 15px;
  }

  .footer-top .social-links{
    float: none;
    display: block;
    text-align: center;
  }

  .footer-top .social-links li{
    float: none;
  }

  .header-style-two .nav-right .search-box-outer{
    margin-right: 0px;
  }

  .feature-two .inner-container{
    padding-left: 20px;
    padding-right: 20px;
  }

  .chooseus-two .content-inner .content-box{
    padding-left: 0px;
    padding-right: 0px;
  }

  .subscribe-one .form-inner .form-group{
    padding-right: 0px;
  }

  .subscribe-one .form-inner .form-group button{
    position: relative;
    width: 100%;
    margin-top: 15px;
  }

  .graph-two .image-box{
    padding-top: 50px;
  }

  .chooseus-three .single-progress-box{
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .chooseus-three .progress-inner{
    max-width: 100%;
  }

  .service-three .shape,
  .about-four .image-box .shape{
    display: none;
  }

  .content_block_four .content-box .list li{
    width: 100%;
  }

  .content_block_six .content-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .content_block_eight .form-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .image_block_five .image-box{
    padding: 0px;
  }

  .image_block_five .image-box .text{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin: 30px 0px;
  }

  .image_block_five .image-box .icon-box{
    position: relative;
    top: 0px;
    right: 0px;
    width: 100%;
  }

  .about-five .content_block_nine .content-box{
    padding-bottom: 0px;
  }

  .image_block_six .image-box{
    padding: 0px;
    margin-bottom: 30px;
  }

  .image_block_six .image-box .image-2{
    position: relative;
  }

  .content_block_ten .content-box .list-style-two li{
    width: 100%;
  }

  .team-block-one .inner-box .lower-content{
    margin-left: 15px;
    margin-right: 15px;
  }

  .subscribe-two .form-inner .form-group{
    padding-right: 0px;
  }

  .subscribe-two .form-inner .form-group button{
    position: relative;
    width: 100%;
    margin-top: 15px;
  }

  .subscribe-two .text{
    margin-right: 0px;
  }

  .slider-five .content-box{
    margin-right: 0px;
  }

  .main-header .search-box-outer .dropdown-menu{
    width: 300px;
  }

  .image_block_seven .image-box{
    padding: 0px;
  }

  .image_block_seven .image-box .image-2{
    position: relative;
    margin-top: 30px;
  }

  .content_block_11 .content-box .list li{
    width: 100%;
  }

  .service-block-seven .inner-box{
    padding-right: 30px;
    padding-left: 30px;
  }

  .service-block-seven .inner-box .icon-box{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 15px;
  }

  .header-style-eight .outer-container{
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-style-eight .social-links-two li{
    margin-right: 15px;
  }

  .image_block_eight .image-box{
    padding: 0px;
  }

  .image_block_eight .image-box .text{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-top: 30px;
  }

  .content_block_13 .content-box .list li{
    width: 100%;
  }

  .service-eight .image-box-one .shape{
    display: none;
  }

  .header-style-nine .header-top-two .info li{
    margin-right: 10px;
  }

  .image_block_nine .image-box{
    min-height: auto;
    margin-right: 0px;
    margin-top: 30px;
  }

  .image_block_nine .image-box .image{
    position: relative;
    bottom: 0px;
  }

  .content_block_nine .content-box{
    padding-top: 70px;
  }

  .header-style-ten .nav-right .nav-btn{
    display: none;
  }

  .header-style-ten .nav-right .search-box-outer{
    margin-right: 0px;
  }

  .image_block_ten .image-box{
    padding: 0px;
  }

  .image_block_ten .image-box .text{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-top: 30px;
  }

  .about-ten .content_block_nine .content-box{
    padding-top: 0px;
  }

  .news-block-one .inner-box .lower-content{
    padding-left: 30px;
  }

  .subscribe-three .form-inner .form-group{
    padding-right: 0px;
  }

  .subscribe-three .form-inner .form-group button{
    position: relative;
    width: 100%;
    margin-top: 15px;
  }

  .header-style-11 .logo-box{
    padding: 30px 30px 30px 30px !important;
  }

  .header-style-11 .header-lower .outer-container{
    padding-right: 15px;
  }

  .slider-nine .content-box .theme-btn{
    margin-bottom: 10px;
  }

  .image_block_11 .image-box{
    padding: 0px;
  }

  .image_block_11 .image-box .text{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-top: 30px;
  }

  .image_block_12 .image-box{
    padding: 0px;
  }

  .image_block_12 .image-box .image-2{
    position: relative;
    margin-top: 30px;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.07);
  }

  .subscribe-four .form-inner .form-group{
    padding-right: 0px;
  }

  .subscribe-four .form-inner .form-group button{
    position: relative;
    width: 100%;
    margin-top: 15px;
  }

  .image_block_13 .image-box{
    min-height: auto;
  }

  .image_block_13 .image-box .image{
    position: relative;
    left: 0px !important;
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
  }

  .about-12{
    padding-top: 0px;
  }

  .image_block_14 .image-box{
    padding: 0px;
  }

  .image_block_14 .image-box .image-2{
    position: relative;
  }

  .service-block-11 .inner-box{
    padding-left: 30px;
  }

  .service-block-11 .inner-box .icon-box{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 15px;
  }

  .image_block_15 .image-box .shape{
    display: none;
  }

  .banner-four .content-box .btn-box a{
    margin-bottom: 10px;
  }

  .image_block_16 .image-box,
  .image_block_17 .image-box,
  .image_block_18 .image-box{
    margin: 0px;
  }

  .image_block_16 .image-box .shape,
  .image_block_16 .image-box .image:before,
  .content_block_16 .content-box .single-item .icon-box .shape{
    display: none;
  }

  .content_block_16 .content-box{
    margin-right: 0px;
  }

  .content_block_17 .content-box .btn-box a{
    margin-bottom: 10px;
  }

  .subscribe-one.home-14 .form-inner .form-group{
    padding-right: 0px;
  }

  .subscribe-one.home-14 .form-inner .form-group button{
    width: 100%;
  }

  .subscribe-one h2{
    line-height: 40px;
  }

  .feature-12 .sec-title{
    margin-right: 0px;
  }

  .image_block_19 .image-box{
    padding: 0px;
  }

  .image_block_19 .image-box .image-2{
    position: relative;
    margin-top: 30px;
  }

  .image_block_19 .image-box .video-btn{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-bottom: 30px;
  }

  .content_block_18 .content-box .list-style-two li{
    width: 100%;
  }

  .subscribe-one.home-15 .form-inner .form-group{
    padding-right: 0px;
  }

  .slider-12 .banner-carousel .content-box{
    padding-left: 30px;
  }

  .image_block_20 .image-box .text{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-bottom: 30px;
  }

  .image_block_20 .image-box{
    margin: 0px;
  }

  .service-16 .tab-btn-box .tab-btns li,
  .service-16 .tab-btn-box .tab-btns{
    width: 100%;
  }

  .service-17 .image-box{
    padding: 0px;
  }

  .service-17 .image-box .image-3{
    position: relative;
    margin-top: 30px;
  }

  .project-seven .sortable-masonry{
    margin: 0px;
  }

  .pricing-block-three .pricing-table{
    padding-left: 30px;
    padding-right: 30px;
  }

  .cta-nine .sec-title-11{
    margin-right: 0px;
  }

  .news-16 .news-block-one .inner-box .lower-content{
    margin-right: 30px;
  }

  .subscribe-one.home-16 .form-inner .form-group{
    padding-right: 0px;
  }

  .subscribe-one.home-16 .form-inner .form-group button{
    width: 100%;
  }

  .ex_shop_header .header-lower .shop-category{
    width: 200px;
  }

  .testimonial-three.about-page-1 .sec-title-three{
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .main-header.about-page-2 .outer-box{
    padding: 0px 15px;
  }

  .main-header.about-page-2 .nav-right .nav-btn{
    display: none;
  }

  .main-header.about-page-2 .nav-right .search-box-outer{
    margin-right: 0px;
  }

  .about-17.about-page-2 .image-box{
    padding: 0px;
    margin-left: 0px;
  }

  .about-17 .image-box .image-2{
    position: relative;
    margin-top: 30px;
  }

  .image_block_22 .image-box{
    margin-left: 0px;
    padding: 0px;
  }

  .image_block_22 .image-box .image-2{
    position: relative;
    margin-top: 30px;
  }

  .header-style-six .nav-right .nav-btn{
    display: none;
  }

  .image_block_20 .image-box{
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .about-19 .image_block_20 .image-box .image{
    margin: 30px 0px;
  }

  .service-sidebar .testimonial-widget{
    padding-left: 30px;
    padding-right: 30px;
  }

  .service-details-content .quote-inner blockquote{
    padding-right: 30px;
  }

  .career-section .image-box{
    padding: 0px;
  }

  .career-section .image-box .image-2{
    position: relative;
    right: 0px;
  }

  .positions-block-one .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .process-six .sec-title{
    margin-bottom: 30px;
  }

  .team-details-two .team-details-content{
    padding: 50px 30px;
  }

  .header-style-two .nav-right .nav-btn{
    display: none;
  }

  .registration-section .content-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .contact-five .form-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .contact-six .map-inner{
    position: relative;
    width: 100%;
  }

  .contact-six .form-inner{
    padding-left: 30px;
    padding-right: 30px;
  }

  .project-nine .masonry-item{
    padding: 0px 0px;
  }

  .project-details .text{
    margin-right: 0px;
  }

  .pagination li{
    margin-bottom: 10px;
  }

  .product-details-content .product-details .addto-cart-box li{
    margin-bottom: 10px;
  }

  .checkout-section .payment-info,
  .checkout-section .order-info{
    padding-left: 30px;
    padding-right: 30px;
  }

  .news-block-one .inner-box blockquote{
    padding-right: 30px;
  }

  .news-block-one.quote-block .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .blog-details-content .author-box{
    padding-left: 30px;
  }

  .blog-details-content .author-box .author-thumb{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 15px;
  }

  .newsletter-form input[type="email"]{
    width: 100%;
    margin-bottom: 15px;
  }



}


@media only screen and (max-width: 399px){

  .header-top-one .top-right li.search-box-outer{
    display: none;
  }

  .service-block-four .inner-box{
    padding-left: 30px;
  }

  .service-block-four .inner-box .icon-box{
    position: relative;
    left: 0px;
    top: 0px;
    margin-bottom: 15px;
  }

  .pricing-three .tab-btn-box .tab-btns li,
  .pricing-one .tab-btn-box .tab-btns li{
    min-width: 130px;
  }

  .banner-carousel .content-box h2{
    font-size: 40px;
    line-height: 50px;
  }

}
.desktop{
  display:block;
}
.mobile{
  display:none
}


@media only screen and (max-width: 600px){
  .desktop{
    display:none;
  }
  .mobile{
    display:block;
  }
  .footer-logo{
    max-width: 120px;
  }
  .footer-mobile{
    background:#1e70b3;
    padding:20px 10px;
  }
  .ref-img{
    width:50% !important
  }
  .logo-wrap{
    background-color: #fff;
    box-shadow: 0 0 5px #eee;
  }
  .logo-wrap a{
    width:100%;
  }
  .logo-wrap:before{
    border:0;
    content: none;
  }
  .logo-top{
    z-index:2;
    width:100%;
  }
  .menu-area.clearfix{

  }
  .main-header{
    position: fixed;
    right: 10px;
    z-index:99;
    background: #fff;
    box-shadow:0 0 5px #eee;
  }
  .header-lower .outer-box .logo-box{
    padding:10px 0;
  }
  .theme-btn.theme-btn-five.baslat-btn.mr_25{
    margin:0
  }
}
