#tm-footer-a{
    background: #1e70b3;
    color: #fff;
    padding: 50px 0 20px 0;
}
#tm-footer-a .uk-container {
    box-sizing: border-box;
    max-width: 980px;
    padding: 0 25px;
    margin-left: auto;
    margin-right: auto;
}
#tm-footer-a .uk-container .uk-grid{
    display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-ms-flex-wrap: wrap;
-webkit-flex-wrap: wrap;
flex-wrap: wrap;
margin: 0;
padding: 0;
list-style: none;
margin-left: -25px;
}
.uk-grid>* {
    padding-left: 25px;
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
    margin: 0;
    float: left;
}
.uk-grid>*>:last-child {
    margin-bottom: 0;
}
#tm-footer-a .uk-container .uk-grid .uk-width-1-1{
    width: 100%;
}
.uk-panel, .uk-panel:hover {
    text-decoration: none;
}
.uk-panel {
    display: block;
    position: relative;
}
.uk-margin-large-top {
    margin-top: 50px !important;
}
@media (min-width: 900px){
    .uk-width-medium-3-5, .uk-width-medium-6-10 {
        width: 60%;
    }
    .uk-width-medium-2-5, .uk-width-medium-4-10 {
        width: 40%;
    }
}
.uk-width-1-4 {
    width: 25%;
}
.uk-width-3-4 {
    width: 75%;
}
.uk-text-white {
    color: #fff !important;
    font-size: 18px;
    line-height: 24px;
}
.uk-text-white + p {
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
}
footer h3, #tm-footer-a h3 {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}
.uk-text-center {
    text-align: center !important;
}
footer a, #tm-footer-a a {
    color: #fff;
}
.uk-icon-button.uk-icon-facebook, .uk-icon-button.uk-icon-twitter, .uk-icon-button.uk-icon-home, .uk-icon-button.uk-icon-linkedin, .uk-icon-button.uk-icon-youtube, .uk-icon-button.uk-icon-instagram {
    color: #fff;
    border: 1px solid #fff;
    margin-right: 20px;
}
.uk-icon-button.uk-icon-facebook {
    background: #3b5999;
}
.uk-icon-button {
    box-sizing: border-box;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background: #eee;
    line-height: 30px;
    color: #444;
    font-size: 18px;
    text-align: center;
}
.uk-icon-button.uk-icon-youtube {
    background: #cd201f;
}
.uk-icon-button.uk-icon-twitter {
    background: #000;
}
.uk-icon-button.uk-icon-instagram {
    background: #e4405f;
}
.uk-icon-button.uk-icon-linkedin {
    background: #0077B5;
}
.uk-icon-button.uk-icon-home {
    background: #f79622;
}
.uk-width-1-2, .uk-width-2-4, .uk-width-3-6, .uk-width-5-10 {
    width: 50%;
}
.uk-width-1-2 a{
    display: block;
    margin-top: -10px !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.uk-button-primary {
    background-color: #f79622;
    color: #fff;
}

.uk-button {
    -webkit-appearance: none;
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: #444;
    text-transform: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 12px;
    vertical-align: middle;
    line-height: 30px;
    min-height: 30px;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 40%);
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.uk-button-large {
    min-height: 40px;
    padding: 0 15px;
    line-height: 40px;
    font-size: 16px;
}
.uk-button:not(:disabled) {
    cursor: pointer;
}
.uk-grid-margin{
    margin-top: 25px;
}
.uk-margin-large-top {
    margin-top: 50px !important;
}
.uk-subnav>* {
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
    padding-left: 10px;
    margin-top: 10px;
    position: relative;
}
.uk-subnav li{
    line-height: 20px;
    font-size: 14px;
}
